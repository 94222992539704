/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onSendChatMessage = /* GraphQL */ `
  subscription OnSendChatMessage($owner: String!) {
    onSendChatMessage(owner: $owner) {
      id
      inboxId
      senderSub
      recipientSub
      owner
      createdAt
      s
      t
    }
  }
`;
export const listenToSocialPosts = /* GraphQL */ `
  subscription ListenToSocialPosts {
    listenToSocialPosts {
      _id
      author {
        publicName
        _id
      }
      dateCreated
      text
      price
      isAdult
      currency
      assetPath
      license
      likeCount
      deleted
    }
  }
`;
export const listenToSocialPostPurchase = /* GraphQL */ `
  subscription ListenToSocialPostPurchase($postId: String) {
    listenToSocialPostPurchase(postId: $postId)
  }
`;
