import React from "react";

import EnvironmentService from "../../services/environment";

class ContentRow extends React.Component {
    retrieveContentName() {
        return this.props.contentName.split('/').pop();
    }

    renderDownloadUrl(s3Key) {
        return `${EnvironmentService.staticURL()}/${s3Key}`;
    }

    renderDownloadButton() {
        return <a href={this.renderDownloadUrl(this.props.downloadKey)} download><button id="SignupButton" className="btn--blueGr">Download</button></a>
    }

    render() {
        return <tr>
            <td className="w20">&nbsp;</td>
            <td>
                {this.props.position}
            </td>
            <td className='content-row-file-name'>
                <div className='content-row-file-name-size-limit'>{this.retrieveContentName()}</div>
            </td>
            <td>
                {this.renderDownloadButton()}
            </td>
            <td className="w20">&nbsp;</td>
        </tr>
    }
}

export default ContentRow;
