import React from "react";

import PreferenceService from "../../services/preference";
import PackageService from "../../services/package";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import LoadingIndicator from "../../components/loading-indicator";
import SelectionModal from "../../components/modal/selection";
import ResponsiveNavigation from "../../components/responsive-navigation";
import HelmetComponent from "../../components/helmet";
import PackageList from "../../components/package-list"

class FeaturedPackages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newPackagesPaging: '',
            featuredPackages: [],
            isLoadingMore: false,
            isLoading: true,
            showAdultWarningModal: false,
        };

        this.handleNewPackageSuccess = this.handleNewPackageSuccess.bind(this);
        this.fetchPackages = this.fetchPackages.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleAdultWarningModalYesClick = this.handleAdultWarningModalYesClick.bind(this);
        this.handleAdultWarningModalNoClick = this.handleAdultWarningModalNoClick.bind(this);
        this.handleAdultWarningModalClose = this.handleAdultWarningModalClose.bind(this);
        this.attemptLoadMore = this.attemptLoadMore.bind(this);
        this.canLoadMore = this.canLoadMore.bind(this);
    }

    componentDidMount() {
        PreferenceService.subscribe('explicitFilterStateChange', this.handleFilterChange);

        this.displayAdultWarningIfNeeded();

        this.fetchPackages();
    }

    componentWillUnmount() {
        PreferenceService.unsubscribe('explicitFilterStateChange', this.handleFilterChange);
    }

    fetchPackages(includeNextKey = false) {
        this.fetchNewPackages(includeNextKey ? this.state.newPackagesPaging.nextKey : null, 25);
    }

    fetchNewPackages(nextKey, limit) {
        PackageService.fetchNewPackages(nextKey, limit).then(response => this.handleNewPackageSuccess(response.data.packages, response.data.paging)).catch(error => this.handleNewPackagesFailure(error));
    }

    loadMore() {
        this.fetchPackages(true);
    }

    isLoading() {
        return this.state.isLoading;
    }

    displayAdultWarningIfNeeded() {
        if (!PreferenceService.isExplicitContentFilterSet()) {
            this.setState({showAdultWarningModal: true});
        }
    }

    handleFilterChange() {
        this.setState({isLoading: true, featuredPackages: [], newPackagesPaging: ''}, () => {
           this.fetchPackages();
        });
    }

    handleNewPackageSuccess(packages, paging) {
        this.setState((prevState) => ({featuredPackages: prevState.featuredPackages.concat(packages), isLoadingMore: false, isLoading: false, newPackagesPaging: paging}));
    }

    handleNewPackagesFailure(error) {
        if (error?.response?.status === 404) {
            this.setState({isLoading: false});
        } else {
            setTimeout(this.fetchPackages, 1000);
        }
    }

    handleAdultWarningModalClose() {
        this.setState({showAdultWarningModal: false})
    }

    handleAdultWarningModalNoClick() {
        PreferenceService.setExplicitContentFilter('hide');

        this.handleAdultWarningModalClose();
    }

    handleAdultWarningModalYesClick() {
        PreferenceService.setExplicitContentFilter('show');
        this.handleAdultWarningModalClose();
    }

    hasPackages() {
        return this.state.featuredPackages.length > 0;
    }

    canLoadMore() {
        return this.state.newPackagesPaging?.nextKey;
    }

    isLoggedIn() {
        return !!this.props.currentUser;
    }

    attemptLoadMore() {
        if (this.canLoadMore()) {
            this.loadMore();
        }
    }

    renderNavigation() {
        return <Navigation isAuthenticated={this.isLoggedIn()} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={this.isLoggedIn()} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer />
    }

    renderContent() {
        return <section className="featured">
            <h3>Featured Packages</h3>
            <div className="container" id="container">
                <div className="row">
                    <PackageList packages={this.state.featuredPackages} navigation={this.props.navigation} loadMore={this.attemptLoadMore} />
                </div>
            </div>
        </section>;
    }

    renderLoading() {
        return <LoadingIndicator/>;
    }

    renderModal() {
        return <SelectionModal isActive={this.state.showAdultWarningModal} handleClose={this.handleAdultWarningModalClose} handleNoClick={this.handleAdultWarningModalNoClick} handleYesClick={this.handleAdultWarningModalYesClick} title="Warning! 18+ Material" message="This site contains Adult material. Would you like to see it or filter it out?" />
    }

    renderFeaturedPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            {this.isLoading() ? this.renderLoading() : this.renderContent()}
            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            {this.renderModal()}
            <HelmetComponent title='Featured Packages' description='OpenSwap.io currently featured packages.' />
        </div>
    }

    render() {
        return this.renderFeaturedPage();
    }
}

export default FeaturedPackages;
