import React from "react";

import { Helmet } from "react-helmet"

class Index extends React.Component {
    renderKeywords() {
        if (this.props.keywords && Array.isArray(this.props.keywords)) {
            return <meta name='keywords' content={this.props.keywords.join()} />;
        }

        return null;
    }

    renderIsAdult() {
        if (this.props.isAdult) {
            return [<meta name='rating' key='rating-adult' content='adult' />, <meta name='rating' content='RTA-5042-1996-1400-1577-RTA' />];
        }
    }

    renderOpenGraphHeaders() {
        let headers = [<meta key='og-title' property="og:title" content={this.props.title} />,
                <meta key='og-description' property="og:description" content={this.props.description} />];

        if (this.props.image) {
            headers.push(<meta key='og-image' property="og:image" content={this.props.image} />);
        }

        if (this.props.url) {
             headers.push(<meta key='og-url' property="og:url" content={this.props.url} />);
        }

        return headers;
    }

    render() {
        return (
            <Helmet>
                <title>OpenSwap - {this.props.title}</title>
                <meta name='description' content={this.props.description} />
                {this.renderKeywords()}
                {this.renderIsAdult()}
                {this.renderOpenGraphHeaders()}
            </Helmet>
        )
    }
}
export default Index