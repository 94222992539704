import React from "react";

import countryList from 'react-select-country-list'

import { Storage } from 'aws-amplify';

import AvatarEditor from 'react-avatar-edit'

import SessionService from "../../services/session";
import UserService from "../../services/user";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveNavigation from "../../components/responsive-navigation";
import Modal from "../../components/modal/display";
import AvatarImage from "../../components/avatar-image";
import LoadingIndicator from "../../components/loading-indicator";
import CoverImage from "../../components/cover-image";
import UPLOAD_ERROR_STATE from "../../constants/package-upload-error-states";
import HelmetComponent from "../../components/helmet";

const avatarS3Key = "avatar.jpg";
const coverS3Key = "cover.jpg";

const MAX_FILE_SIZE = 5000000;

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.countries = countryList().getData();
        this.modifiedFields = {};

        this.avatarDataUrl = '';
        this.croppedAvatarDataUrl = '';

        this.state = {
            isUploadingAvatar: false,
            isUploadingCover: false,
            countries: this.countries,
            country: '',
            blocked_regions: [],
            email: '',
            user_id: '',
            publicName: '',
            bio: '',
            name: '',
            personal_btc_address: '',
            nft_address: '',
            sex: '',
            isSaving: false,
            isModalOpen: false,
            isShowingAvatarEditor: false,
            errorState: null,
        };

        this.handleUploadCoverImage = this.handleUploadCoverImage.bind(this);
        this.handleUploadAvatar = this.handleUploadAvatar.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.canUpdateUser = this.canUpdateUser.bind(this);
        this.handleAvatarCrop = this.handleAvatarCrop.bind(this);
        this.handleAvatarEditorClose = this.handleAvatarEditorClose.bind(this);
    }

    componentDidMount() {
        SessionService.getCurrentUser().then(currentUser => this.setState({email: currentUser ? currentUser.attributes.email : '', user_id: currentUser.userId}));
        this.fetchCurrentUserData();
    }

    // Fetch from API
    async fetchCurrentUserData() {
        const currentUserMetaData = await UserService.fetchCurrent();

        this.setState({publicName: currentUserMetaData.data.publicName,
            bio: currentUserMetaData.data.bio,
            name: currentUserMetaData.data.name,
            sex: currentUserMetaData.data.sex,
            country: currentUserMetaData.data.country,
            blocked_regions: currentUserMetaData.data.blocked_regions,
            personal_btc_address: currentUserMetaData.data.personal_btc_address,
            nft_address: currentUserMetaData.data.nft_address});
    }

    handleUploadAvatar(event) {
        if (event.target.files.length === 0) return;

        if (event.target.files.item(0) && event.target.files.item(0).size > MAX_FILE_SIZE) {
            this.openErrorModal(UPLOAD_ERROR_STATE.FILE_TOO_BIG);
            return;
        }

        if (event.target.files.item(0) && event.target.files.item(0).type !== 'image/jpeg') {
            this.openErrorModal(UPLOAD_ERROR_STATE.WRONG_IMAGE_TYPE);
            return;
        }

        this.processAvatarImage(event.target.files[0]);
    }

    handleUploadCoverImage(event) {
        if (event.target.files.length === 0) return;

        if (event.target.files.item(0) && event.target.files.item(0).size > MAX_FILE_SIZE) {
            this.openErrorModal(UPLOAD_ERROR_STATE.FILE_TOO_BIG);
            return;
        }

        if (event.target.files.item(0) && event.target.files.item(0).type !== 'image/jpeg') {
            this.openErrorModal(UPLOAD_ERROR_STATE.WRONG_IMAGE_TYPE);
            return;
        }

        this.setState({isUploadingCover: true});

        this.uploadImage(event.target.files[0], coverS3Key, () => {
            this.setState({isUploadingCover: false})
        }, (error) => {
            this.setState({isUploadingCover: false})
        });
    }

    processAvatarImage(imageFile) {
        const reader = new FileReader();

        reader.onload = function() {
           this.avatarDataUrl = reader.result;
           this.setState({isShowingAvatarEditor: true});
        }.bind(this);

        reader.onerror = function() {

        };

        reader.readAsDataURL(imageFile);
    }

    uploadImage(imageFile, s3Key, completion, error) {
        const reader = new FileReader();

        reader.onload = function () {
            Storage.put(s3Key, reader.result, {
                level: 'protected',
                contentType: imageFile.type,
            }).then(result => completion()).catch(err => error(err));
        };

        reader.onerror = function () {
            error();
        };

        reader.readAsArrayBuffer(imageFile);
    }

    handleUserDataChange(event) {
        const name = event.target.name;

        this.trackFieldChange(event);

        if (name === "blocked_regions") {
            if (this.maximumBlockedRegionsExceeded(event)) {
                return;
            }

            this.setState({[name]: this.collectSelectedBlockedRegions(event)});
        } else {
            this.setState({[name]: event.target.value});
        }
    }

    maximumBlockedRegionsExceeded(event) {
        let count = 0;

        for (let i = 0; i < event.target.length; i++) {
            if (event.target[i].selected) {
                count++;
            }
        }

        return count > 5;
    }

    trackFieldChange(event) {
        this.modifiedFields[event.target.name] = true;
    }

    collectSelectedBlockedRegions(event) {
        let result = [];
        let options = event && event.target;

        for (let i = 0; i < options.length; i++) {
            let option = options[i];

            if (option.selected) {
                result.push( option.value || option.text);
            }
        }
        return result;
    }

    buildUpdateUserBody() {
        let userUpdateBody = {};

        Object.keys(this.modifiedFields).forEach(property => userUpdateBody[property] = this.state[property]);

        delete userUpdateBody["publicName"];

        return userUpdateBody;
    }

    hasBeenModified(field) {
        return this.modifiedFields[field] === true;
    }

    updateUser(event) {
        event.preventDefault();

        this.buildUpdateUserBody();

        this.setState({isSaving: true});

        UserService.update(this.buildUpdateUserBody()).then(response => this.handleSuccessfulUserUpdate()).catch(error => this.handleFailedUserUpdate());

        if (this.hasBeenModified("publicName")) {
            UserService.update({publicName: this.state.publicName}).then(response => this.setState({isSaving: false, isModalOpen: true})).catch(error => this.setState({isSaving: false}));
        }
    }

    handleSuccessfulUserUpdate() {
        this.setState({isSaving: false, isModalOpen: true});
        this.modifiedFields = {};
    }

    handleFailedUserUpdate() {
        this.setState({isSaving: false})
    }

    handleModalClose() {
        this.setState({isModalOpen: false, errorState: null});
    }

    canUpdateUser() {
        return Object.keys(this.modifiedFields).length > 0;
    }

    handleAvatarCrop(event) {
        this.croppedAvatarDataUrl = event;
    }

    async handleAvatarEditorClose() {
        this.setState({isShowingAvatarEditor: false, isUploadingAvatar: true});
        this.uploadImage(await (await fetch(this.croppedAvatarDataUrl)).blob(), avatarS3Key, () => {
            this.setState({isUploadingAvatar: false})
        }, (error) => {
            this.setState({isUploadingAvatar: false})
        }, false);
    }

    openErrorModal(error) {
        this.setState({isModalOpen: true, errorState: error});
    }

    renderAvatarImage() {
        if (this.state.isShowingAvatarEditor) {
            return  <AvatarEditor
                width={150}
                height={150}
                onCrop={this.handleAvatarCrop}
                onClose={this.handleAvatarEditorClose}
                onBeforeFileLoad={this.onBeforeFileLoad}
                src={this.avatarDataUrl}
            />
        }

        return <AvatarImage loadFromStorage={true} key={this.state.user_id} size='large' identityId={this.state.user_id} extraClassNames="user-img" />;
    }

    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderSuccessModal() {
        return <Modal isActive={this.state.isModalOpen} handleClose={this.handleModalClose} title="Success!" message="Your Settings have been successfully saved." />
    }

    renderErrorModal() {
        return <Modal isActive={this.state.isModalOpen} handleClose={this.handleModalClose} title="Error!" message={this.renderErrorModalMessage()} />
    }

    renderErrorModalMessage() {
        switch (this.state.errorState) {
            case UPLOAD_ERROR_STATE.WRONG_IMAGE_TYPE:
                return 'Please upload a JPG image.';
            case UPLOAD_ERROR_STATE.FILE_TOO_BIG:
                return 'Please upload a smaller image. The limit is 5 MB';
            default:
                return 'Unknown error has occurred';
        }
    }

    renderModal() {
        return this.state.errorState ? this.renderErrorModal() : this.renderSuccessModal();
    }

    renderSettingsContent() {
        return <section className="featured my settings">

            <form className="form-signin" action="#">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="category">
                                <div className="category-header">Account Details</div>
                                {this.state.isUploadingCover ? <div className="header container"><LoadingIndicator positionClassName="top-margin-settings-cover" /></div> :
                                <CoverImage loadFromStorage={true} key={Math.random()} identityId={this.state.user_id}>
                                    <input type="file" onChange={this.handleUploadCoverImage} id="hero-img" name="hero-img" className="d-none" />
                                    <div className="add-hero h-100">
                                        <label htmlFor="hero-img"  className="d-flex align-items-center justify-content-center h-100">
                                            Change Cover Image
                                        </label>
                                    </div>
                                </CoverImage>}
                                <div className="user-avatar">
                                    <div className="category-title">Avatar</div>
                                    {(!this.state.isShowingAvatarEditor && !this.state.isUploadingAvatar) && <input type="file" onChange={this.handleUploadAvatar} id="user-img" name="user-img" className="d-none" />}
                                    <div className="add-user-img">
                                        <label htmlFor="user-img" className="d-flex align-items-center justify-content-center h-100">
                                            {this.state.isUploadingAvatar ? <LoadingIndicator positionClassName="top-margin-settings-avatar" /> : this.renderAvatarImage()}
                                        </label>
                                    </div>
                                    {this.state.isShowingAvatarEditor ? <h5 className='setting'>Tap the X to save</h5> : ''}
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Your Email</div>
                                    <input id="emailInput" type="email" className="form-control" defaultValue={this.state.email} readOnly={true} placeholder="Email" required />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Your Username</div>
                                    <input id="usernameInput" type="text" className="form-control" onChange={this.handleUserDataChange} name="publicName" value={this.state.publicName} placeholder="Username" required />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container setting">
                    <div className="row">
                        <div className="col">
                            <div className="category">
                                <div className="category-header">Personal Details</div>
                                <div className="user-avatar">
                                    <div className="category-title">Your Bio</div>
                                    <textarea onChange={this.handleUserDataChange} id="user-bio" name="bio" className="form-control" value={this.state.bio}
                                              placeholder="Write something about you" rows="6" />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Your Name</div>
                                    <input id="nameInput" onChange={this.handleUserDataChange} type="text" name="name" className="form-control" value={this.state.name} placeholder="Name" />
                                    <br />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Your Gender</div>
                                    <div className="checkbox">
                                        <input onChange={this.handleUserDataChange} type="radio" value={'M'} id="male" name="sex" checked={this.state.sex === "M"} />
                                        <label htmlFor="male">
                                            <svg id="checkmarkIcon" width="100" height="100"
                                                 viewBox="0 0 12.23 9.18">
                                                <path d="M12.17.87,5.11,7.93,1.36,4.18"
                                                      transform="translate(-0.65 -0.16)" fill="none"
                                                      strokeMiterlimit="10" strokeWidth="2"></path>
                                            </svg>
                                        </label>
                                    </div>
                                    <label htmlFor="male" className="nav-checkbox-label">Male</label>

                                    <div className="checkbox">
                                        <input onChange={this.handleUserDataChange} type="radio" value={"F"} id="female" name="sex" checked={this.state.sex === "F"} />
                                        <label htmlFor="female">
                                            <svg id="checkmarkIcon" width="100" height="100"
                                                 viewBox="0 0 12.23 9.18">
                                                <path d="M12.17.87,5.11,7.93,1.36,4.18"
                                                      transform="translate(-0.65 -0.16)" fill="none"
                                                      strokeMiterlimit="10" strokeWidth="2"></path>
                                            </svg>
                                        </label>
                                    </div>
                                    <label htmlFor="female" className="nav-checkbox-label">Female</label>
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Your Country</div>
                                    <select onChange={this.handleUserDataChange} name="country" value={this.state.country} className="custom-select">
                                        {
                                            this.state.countries.map(country => <option key={country.value} value={country.value}>{country.label}</option> )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container setting">
                    <div className="row">
                        <div className="col">
                            <div className="category">
                                <div className="category-header">Financial Details</div>
                                <div className="user-avatar">
                                    <div className="category-title">BTC Address</div>
                                    <input id="btc" onChange={this.handleUserDataChange} type="text" className="form-control" value={this.state.personal_btc_address} name="personal_btc_address" placeholder="Your Personal BTC Address" required />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">NFT Address</div>
                                    <input id="btc" onChange={this.handleUserDataChange} type="text" className="form-control" value={this.state.nft_address} name="nft_address" placeholder="Your NFT Address" required />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container setting">
                    <div className="row">
                        <div className="col">
                            <div className="category">
                                <div className="category-header">Security Details</div>
                                <div className="user-avatar">
                                    <div className="category-title">Geo Block</div>
                                    <select name="blocked_regions" onChange={this.handleUserDataChange} className="custom-select" value={this.state.blocked_regions} multiple>
                                        {
                                            this.state.countries.map(country => <option key={country.value} value={country.value}>{country.label}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container inputs">

                    <div id="SignupMessage" style={{"display":"none"}}></div>

                    <div id="SignupButtonHolder">
                        <button id="SignupButton" onClick={this.updateUser} disabled={!this.canUpdateUser()} className={this.state.isSaving ? "btn--gray button-loading-border" : "btn--blueGr"}>Save</button>
                    </div>
                </div>
            </form>

        </section>;
    }

    renderSettingsPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            {this.renderSettingsContent()}
            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            {this.renderModal()}
            <HelmetComponent title='Settings' description='OpenSwap.io settings page.' />
        </div>
    }

    render() {
        return this.renderSettingsPage();
    }
}

export default Settings;
