import React from 'react';

import Pica from 'pica';
import countryList from 'react-select-country-list'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import * as Sentry from "@sentry/react";
import 'react-circular-progressbar/dist/styles.css';
import {Storage} from "aws-amplify";

import UPLOAD_ERROR_STATE from "../../constants/package-upload-error-states";

import PackageService from "../../services/package";
import RateService from "../../services/rate";
import UserService from "../../services/user";
import MetaDataService from "../../services/meta-data";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveNavigation from "../../components/responsive-navigation";
import LoadingIndicator from "../../components/loading-indicator";
import Modal from "../../components/modal/display";
import ImageSettingsModal from "../../components/modal/image-settings";
import HelmetComponent from "../../components/helmet";

import PACKAGE_LICENSE from "../../constants/package-licenses";
import CURRENCY from "../../constants/currency";

const fileThumbnail = require('../../images/file.png');
const videoThumbnail = require('../../images/video.png');
const imageSettingsIcon = require('../../images/image-settings.svg');

const MAX_PACKAGE_SIZE = 4294967296;
const BYTES_IN_GB = 1073741824;
const MIN_PRICE = 0.0002;
const MAX_PACKAGE_QTY = 250;
const MAX_PACKAGE_PREV = 10;
const MAX_UPLOAD_RETRIES = 5;

class UploadPage extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.currentuploadRetries = 0;

        this.temporaryPackageId = '';

        this.currentSettingsItem = ''; // This holds currently open item in the settings modal. It holds the items name specifically

        this.currentCoverPreview = ''; // This holds the name of the current item image set as cover
        /*
         This variable is needed for Editing.
         This variable is used to store a reference package payload.
          This reference payload will be compared with the package payload coming from the state to determine which package properties have been edited
         */
        this.packagePayload = {};
        this.user = {};

        this.isEditing = this.extractIsEditingFromQueryString();

        this.countries = countryList().getData();
        this.pica = Pica({ features: [ 'js', 'wasm', 'ww', 'cib' ] });
        this.urlCreator = window.URL || window.webkitURL;

        this.dropTargetReference = React.createRef();
        this.newFiles = []; // Stores new (before they have been processed / uploaded) File objects returned by the browser upon drop
        this.files = []; // Stores File objects returned by the browser upon drop
        this.newFileBlobs = []; // Store new blobs which came from the File objects, blobs are used to upload to S3
        this.fileUrls = []; // Store image urls which came from blobs, urls are used to display in the browser
        this.fileBlobs = []; // Store blobs which aren't image which came from the File objects, blobs are used to upload to S3
        this.thumbnailBlobs = []; // Store resized image blobs which came from Pica, blobs are used to upload to S3
        this.thumbnailUrls = []; // Store resized image urls which came from blobs which came from Pica, urls are used to display in the browser (deprecated)
        this.contents = []; // The contents field in the final package payload
        this.previews = []; // The previews field in the final package payload

        this.state = {
          name: '',
          price: '',
          currency: 'BTC',
          description: '',
          tags: '',
          is_adult: true,
          pagePreviews: [], // The previews (red border) which are visually display in the Upload Component
          isDragging: false,
          thumbnails: [],
          files: [],
          blocked_regions: [],
          isLoadingPreviews: false,
          isPublishingPackage: false,
          isSavingPackage: false,
          btcValue: '',
          priceInUSD: '',
          userHasBtcAddress: false,
          showErrorModal: false,
          showImageSettingsModal: false,
          errorState: '',
          filesInPackage: 0,
          license: PACKAGE_LICENSE.PERSONAL_USE
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handlePackageDataChange = this.handlePackageDataChange.bind(this);
        this.publishPackage = this.publishPackage.bind(this);
        this.savePackageEdit = this.savePackageEdit.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDragIn = this.handleDragIn.bind(this);
        this.handleDragOut = this.handleDragOut.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleClickToUpload = this.handleClickToUpload.bind(this);
        this.clearUploads = this.clearUploads.bind(this);
        this.updateUploadProgressIndicator = this.updateUploadProgressIndicator.bind(this);
        this.handleErrorModalClose = this.handleErrorModalClose.bind(this);
        this.handleItemSettingsModalClose = this.handleItemSettingsModalClose.bind(this);
        this.showErrorModal = this.showErrorModal.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.canPublish = this.canPublish.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
        this.handleItemSettings = this.handleItemSettings.bind(this);
        this.handleCoverClick = this.handleCoverClick.bind(this);
        this.handleLicenseChange = this.handleLicenseChange.bind(this);
        this.renderImageSettingsModalMessage = this.renderImageSettingsModalMessage.bind(this);
        this.renderImageSettingsModalTitle = this.renderImageSettingsModalTitle.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        RateService.btcToOneUsd().then(value => this.setState({btcValue: value}));
        this.fetchUserData();
        this.attemptFetchPackage();


        /*
            We want a brand new package id when this page loads.
            This is because we don't want any dangling files in the S3 folders associated with each package.
         */
        this.clearTemporaryPackageId();

        if (this.isEditing) {
            return;
        }

        const dropTargetElement = this.dropTargetReference.current;
        dropTargetElement.addEventListener('dragenter', this.handleDragIn);
        dropTargetElement.addEventListener('dragleave', this.handleDragOut);
        dropTargetElement.addEventListener('dragend', this.handleDragOut);
        dropTargetElement.addEventListener('dragover', this.handleDrag);
        dropTargetElement.addEventListener('drop', this.handleDrop);
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.isEditing) {
            return;
        }

        const dropTargetElement = this.dropTargetReference.current;
        dropTargetElement.removeEventListener('dragenter', this.handleDragIn);
        dropTargetElement.removeEventListener('dragleave', this.handleDragOut);
        dropTargetElement.removeEventListener('dragend', this.handleDragOut);
        dropTargetElement.removeEventListener('dragover', this.handleDrag);
        dropTargetElement.removeEventListener('drop', this.handleDrop);
    }

    clearTemporaryPackageId() {
        this.temporaryPackageId = '';
    }

    getTemporaryPackageId() {
        if (!this.temporaryPackageId) {
            this.temporaryPackageId = MetaDataService.generateRandomString();
        }

        return this.temporaryPackageId;
    }

    handleFormSubmit(event) {
        event.preventDefault();
    }

    handlePackageDataChange(event) {
        if (event.target.name === 'is_adult') {
            this.setState(prevState => { return {is_adult: !prevState.is_adult}});
        } else if (event.target.name === "blocked_regions") {
            if (this.maximumBlockedRegionsExceeded(event)) {
                return;
            }
            this.setState({[event.target.name]: this.collectSelectedBlockedRegions(event)});
        } else if (event.target.name === 'price') {
           this.setState({[event.target.name]: this.formatCustomPrice(event.target.value)});
        }  else if (event.target.name === 'currency') {
            this.setState({price: '', [event.target.name]: event.target.value});
        } else {
            this.setState({[event.target.name]: event.target.value});
        }
    }

    formatCustomPrice(customPrice) {
        if (customPrice < 0) {
            return  0
        }

        const numberOfDecimals = customPrice.split(".")[1]?.length || 0;

        if (this.state.currency === 'BTC' && numberOfDecimals > 6) {
            return parseFloat(customPrice).toFixed(6);
        } else if (this.state.currency === 'USD' && numberOfDecimals > 2) {
            return parseFloat(customPrice).toFixed(2);
        }

        return parseFloat(customPrice);
    }

    collectSelectedBlockedRegions(event) {
        let result = [];
        let options = event && event.target;

        for (let i = 0; i < options.length; i++) {
            let option = options[i];

            if (option.selected) {
                result.push(option.value || option.text);
            }
        }
        return result;
    }

    canAddFilesForUpload() {
        return !this.areFilesBeingUploaded() && !this.state.isLoadingPreviews;
    }

    maximumBlockedRegionsExceeded(event) {
        let count = 0;

        for (let i = 0; i < event.target.length; i++) {
            if (event.target[i].selected) {
                count++;
            }
        }

        return count > 5;
    }

    handleClickToUpload(event) {
        if (this.canAddFilesForUpload()) {
            this.beginUploadProcess([...event.target.files]);
        }
    }

    handleDrag(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({isDragging: true});
    }

    handleDragIn(event) {
        event.preventDefault();

        this.setState({isDragging: true});
    }

    handleDragOut(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({isDragging: false});
    }

    handleDrop(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.canAddFilesForUpload()) {
            this.setState({isDragging: false});
            return;
        }

        this.beginUploadProcess([...event.dataTransfer.files]);

        this.setState({isDragging: false});
    }

    handleLicenseChange(event) {
        this.setState({license: event.target.value});
    }

    fetchUserData() {
        UserService.fetchCurrent().then(user => this.handleSuccessfulUserDataFetch(user.data)).catch(error => this.handleFailedUserDataFetch());
    }

    handleSuccessfulUserDataFetch(user) {
        //TODO implement per package blocked regions being displayed
        // We need to combine user blocked regions and package blocked regions as they come from the API
        this.user = user;

        if (user.personal_btc_address) {
            this.setState({userHasBtcAddress: true, blocked_regions: user.blocked_regions});
        } else {
            this.setState({userHasBtcAddress: false, blocked_regions: user.blocked_regions});
            this.showErrorModal(UPLOAD_ERROR_STATE.NO_BTC_ADDRESS);
        }
    }

    handleFailedUserDataFetch() {
        this.showErrorModal(UPLOAD_ERROR_STATE.NO_BTC_ADDRESS);
    }

    attemptFetchPackage() {
        let packageId = this.extractPackageIdFromQueryString();

        if (packageId) {
            PackageService.fetchPackage(packageId).then(response => this.handleSuccessfulPackageFetch(response.data.packages[0])).catch(error => console.log(error));
        }
    }

    handleSuccessfulPackageFetch(packagePayload) {
        this.packagePayload = {...packagePayload};

        this.setState({name: packagePayload.name,
                            price: packagePayload.price,
                            description: packagePayload.description,
                            tags: packagePayload.tags.join(", "),
                            is_adult: packagePayload.is_adult,
                            currency: packagePayload.currency,
                            license: packagePayload.license,
                        });
    }

    extractPackageIdFromQueryString() {
        let packageId = null;

        let history = this.props.navigation.exposeHistory();

        let queryString = history.location.search.replace('?', '');
        let queryStringArray = queryString.split('&');

        if (queryStringArray[0]) {
            packageId = queryStringArray[0].split("=")[1];
        }

        return packageId
    }

    extractIsEditingFromQueryString() {
        let isEditing = false;
        let history = this.props.navigation.exposeHistory();

        let queryString = history.location.search.replace('?', '');
        let queryStringArray = queryString.split('&');

        if (queryStringArray[1]) {
            isEditing = !!(parseInt(queryStringArray[1].split("=")[1]));
        }

        return isEditing;
    }

    beginUploadProcess(files) {
        this.newFiles = this.removeDupes(files);

        if (this.newFiles.length === 0) {
            this.setState({isLoadingPreviews: false});
            return;
        }

        if (this.newFiles.length > MAX_PACKAGE_QTY) {
            this.showErrorModal(UPLOAD_ERROR_STATE.PACKAGE_QTY_TOO_HIGH);
            this.setState({isLoadingPreviews: false});
            this.files = [];
            this.newFiles = [];
            return;
        }

        if (this.isPackageTooBig()) {
            this.showErrorModal(UPLOAD_ERROR_STATE.PACKAGE_TOO_BIG);
            this.setState({isLoadingPreviews: false});
            this.files = [];
            this.newFiles = [];
            return;
        }

        this.generateBlobs();
    }

    handleErrorModalClose() {
        this.setState({showErrorModal: false});
    }

    handleItemSettingsModalClose() {
        this.setState({showImageSettingsModal: false});
    }

    showErrorModal(errorState) {
        this.setState({showErrorModal: true, errorState });
    }

    /*
    handlePreviewsChange toggles if an item is a preview or not
    addToThumbnailPreviews changes, only adds the item to previews
     */
    addToThumbnailPreviews(preview) {
        let previews = this.state.pagePreviews;

        if (previews.length >= MAX_PACKAGE_PREV && !previews.includes(preview)) {
            this.showErrorModal(UPLOAD_ERROR_STATE.PACKAGE_PREVIEW_QTY_TOO_HIGH);
            return;
        }

        if (!previews.includes(preview)) {
            previews.push(preview);
            this.setState({pagePreviews: previews});
        }
    }

    handlePreviewsChange(preview, type) {
        if (!this.isImage(type)) {
            return;
        }

        let previews = this.state.pagePreviews;

        if (previews.length >= MAX_PACKAGE_PREV && !previews.includes(preview)) {
            this.showErrorModal(UPLOAD_ERROR_STATE.PACKAGE_PREVIEW_QTY_TOO_HIGH);
            return;
        }

        if (previews.includes(preview)) {
            previews.splice(previews.indexOf(preview), 1);

            /*
                Removing the preview also removes it as Cover Image
            */
            if (preview === this.currentCoverPreview) {
                this.currentCoverPreview = '';
            }
        } else {
            previews.push(preview);
        }

        this.setState({pagePreviews: previews});
    }

    handleDeleteItem(itemName) {
        this.setState({showImageSettingsModal: false});

        /* Remove from contents */
        let contentToDelete = this.contents.find(content => content.uri.includes(itemName));

        if (contentToDelete) {
            this.contents.splice(this.contents.indexOf(contentToDelete), 1);
        }

        /* Remove from thumbnails */
        let thumbnails = this.state.files;

        let thumbnailToDelete = thumbnails.find(thumbnail => thumbnail.name === itemName);

        if (thumbnailToDelete) {
            thumbnails.splice(thumbnails.indexOf(thumbnailToDelete), 1);

            /* Update number of files in package */
            this.setState(prevState => ({filesInPackage: prevState.filesInPackage - 1}));

            this.setState({thumbnails});
        }

        /* Remove from previews */
        let previews = this.state.pagePreviews;

        if (previews.includes(itemName)) {
            previews.splice(previews.indexOf(itemName), 1);
        }

        this.setState({pagePreviews: previews});

        /* Remove from fileBlobs */
        let fileBlobToDelete = this.fileBlobs.find(fileBlob => fileBlob.name === itemName);

        if (fileBlobToDelete) {
            this.fileBlobs.splice(this.fileBlobs.indexOf(fileBlobToDelete), 1);
        }

        /* Remove from fileUrls */
        let fileUrlToDelete = this.fileUrls.find(fileUrl => fileUrl.name === itemName);

        if (fileUrlToDelete) {
            this.fileUrls.splice(this.fileUrls.indexOf(fileUrlToDelete), 1);
        }

        /* Remove from newFileBlobs */
        let newFileBlobToDelete = this.newFileBlobs.find(newFileBlob => newFileBlob.name === itemName);

        if (newFileBlobToDelete) {
            this.newFileBlobs.splice(this.newFileBlobs.indexOf(newFileBlobToDelete), 1);
        }

        /* Remove from newFiles */
        let newFileToDelete = this.newFiles.find(newFile => newFile.name === itemName);

        if (newFileToDelete) {
            this.newFiles.splice(this.newFiles.indexOf(newFileToDelete), 1);
        }

        /* Remove from files */
        let fileToDelete = this.files.find(file => file.name === itemName);

        if (fileToDelete) {
            this.files.splice(this.files.indexOf(fileToDelete), 1);
        }

        /* Remove from thumbnail blobs */
        let thumbnailBlobToDelete = this.thumbnailBlobs.find(thumbnailBlob => thumbnailBlob.name === itemName);

        if (thumbnailBlobToDelete) {
            this.thumbnailBlobs.splice(this.thumbnailBlobs.indexOf(thumbnailBlobToDelete), 1);
        }

        if (itemName === this.currentCoverPreview) {
            this.currentCoverPreview = '';
        }

        if (this.fileUrls.length === 0) {
            this.clearUploads();
        }
    }

    handleItemSettings(event) {
        event.stopPropagation();

        this.currentSettingsItem = event.target.name;
        this.setState({showImageSettingsModal: true});
    }

    handleCoverClick(itemName) {
        this.currentCoverPreview = itemName;
        this.addToThumbnailPreviews(itemName);
        this.setState({showImageSettingsModal: false});
    }

    handleSuccessfulFileUpload(key) {
        /* If the file hasn't been deleted, include it in the contents */
        if (this.fileExists(this.extractFilenameFromKey(key))) {
            const fullKey = `private/${this.props.currentUser.userId}/${key.key}`;
            this.contents.push({uri: fullKey});
        }
    }

    async handleFailedFileUpload(error, failedFileBlob, componentContext) {
        Sentry.addBreadcrumb({
            category: "upload",
            message: "Upload failed for file " + failedFileBlob.name + " for user " + this.props.currentUser.userId,
            level: "info",
        });

        componentContext.resetUploadProgressIndicator(failedFileBlob.name);

        try {
            let key = `packages/${this.getTemporaryPackageId()}/${MetaDataService.generateRandomString()}/${failedFileBlob.name}`;
            let result = await Storage.put(key, failedFileBlob.content, {
                level: 'private',
                contentType: failedFileBlob.type,
                tagging: 'package:id=none&package:state=unpublished',
                useAccelerateEndpoint: true,
                progressCallback(progress) {
                    componentContext.updateUploadProgressIndicator(key, (progress.loaded / progress.total));
                },
            });

            this.handleSuccessfulFileUpload(result);
        } catch (error) {
            if (this.fileExists(failedFileBlob.name) && this._isMounted) {
                await this.handleFailedFileUpload(error, failedFileBlob, componentContext);
            }
        }
    }

    /* NOTE: as of MVP thumbnails are only uploaded for previews */
    handleSuccessfulThumbUpload(thumbKey) {
        const contentFullKey = this.contents.find(content => {
            const contentFileName = content.uri.split('/')[5];
            return thumbKey.key.includes(contentFileName);
        });

        const thumbFullKey = `private/${this.props.currentUser.userId}/${thumbKey.key}`;
        this.addToPreviews({thumb_uri: thumbFullKey, uri: contentFullKey.uri});
    }

    addToPreviews(preview) {
        const existingPreview = this.previews.find(aPreview => aPreview.uri === preview.uri);

        if (!existingPreview) {
            this.previews.push(preview);
        }
    }

    fileExists(itemName) {
        return !!this.state.files.find(thumbnail => thumbnail.name === itemName);
    }

    extractFilenameFromKey(key) {
        /*
        key example: {key: packages/9680e9d6/38d8shs/artiom-vallat-jlifOZe8UC8-unsplash.jpg}
         */
        const splitKey = key.key.split("/");
        return (splitKey.length === 4) ? splitKey[3] : null;
    }

    removeDupes(newFiles) {
        let newFilesTemp = [];

        for (let newFile of newFiles) {
            if (!this.files.find(file => file.name === newFile.name)) {
                newFilesTemp.push(newFile);
            }
        }

        return newFilesTemp
    }

    removeDupeTags(tags) {
        return [...new Set(tags)];
    }

    isPackageTooBig() {
        let totalPackageSize = 0;

        this.files.map(file => totalPackageSize += file.size);

        this.fileBlobs.map(fileBlob => totalPackageSize += fileBlob.size);

        return totalPackageSize >= MAX_PACKAGE_SIZE;
    }

    isImage(type) {
        return type.includes('image');
    }

    generateBlobs() {
        this.setState({isLoadingPreviews: true, filesInPackage: this.newFiles.length});

        for (let i = 0; i < this.newFiles.length; i++) {
            if (this.isImage(this.newFiles[i].type)) {
                const reader = new FileReader();

                reader.onload = (event) => {
                    let fileBlob = new Blob([new Uint8Array(event.target.result)], {type: this.newFiles[i].type});

                    this.fileUrls.push({name: this.newFiles[i].name, content: this.urlCreator.createObjectURL(fileBlob), type: this.newFiles[i].type, progress: 0});

                    if (this.fileUrls.length === (this.newFiles.length + this.files.length)) {
                        this.setState({isLoadingPreviews: false, filesInPackage: (this.files.length + this.newFiles.length), files: this.fileUrls});
                        this.uploadFiles();
                    }
                };

                reader.onerror = (error) => {
                    this.showErrorModal(UPLOAD_ERROR_STATE.UNSUPPORTED_FILE);
                    this.setState({isLoadingPreviews: false});
                    this.clearUploads();
                };

                reader.readAsArrayBuffer(this.newFiles[i]);
            } else {
                this.fileUrls.push({name: this.newFiles[i].name, content: this.generateFileThumbnails(this.newFiles[i].type), type: this.newFiles[i].type, progress: 0});

                if (this.fileUrls.length === (this.newFiles.length + this.files.length)) {
                    this.setState({isLoadingPreviews: false, filesInPackage: (this.files.length + this.newFiles.length), files: this.fileUrls});

                    this.uploadFiles();
                }
            }
        }
    }

    generateFileThumbnails(type) {
        if (type.includes('video')) {
            return videoThumbnail;
        }

        return fileThumbnail;
    }

    generateThumbnails(completion) {
        for (let i = 0; i < this.files.length; i++) {

            if (!this.state.pagePreviews.includes(this.files[i].name)) {
                continue; /* Only upload thumbnails which have been selected as previews */
            }

            this.thumbnailBlobs.push({name: this.files[i].name, file: this.files[i]});

            if (this.thumbnailBlobs.length === this.state.pagePreviews.length) {
                completion();
            }
        }
    }

    async uploadThumbnails() {
        for (let i = 0; i < this.thumbnailBlobs.length; i++) {

            if (!this.state.pagePreviews.includes(this.thumbnailBlobs[i].name)) {
                continue; /* Only upload thumbnails which have been selected as previews */
            }

            let thumbKey = await Storage.put(`packages/${this.getTemporaryPackageId()}/thumb_${this.thumbnailBlobs[i].name}`, this.thumbnailBlobs[i].file, {
                level: 'private',
                contentType: 'image/jpeg',
                tagging: 'package:id=none&package:state=unpublished',
                useAccelerateEndpoint: true
            });

            this.handleSuccessfulThumbUpload(thumbKey);
        }

        this.thumbnailBlobs = [];
    }

    async uploadFiles() {
        const componentContext = this;

        for (let i = 0; i < this.newFiles.length; i++) {
            try {
                let key = `packages/${this.getTemporaryPackageId()}/${MetaDataService.generateRandomString()}/${this.newFiles[i].name}`;
                let result = await Storage.put(key, this.newFiles[i], {
                    level: 'private',
                    contentType: this.newFiles[i].type,
                    tagging: 'package:id=none&package:state=unpublished',
                    useAccelerateEndpoint: true,
                    progressCallback(progress) {
                        componentContext.updateUploadProgressIndicator(key, (progress.loaded / progress.total));
                    },
                });

                this.handleSuccessfulFileUpload(result);
            } catch (error) {
                await this.handleFailedFileUpload(error, this.newFiles[i], componentContext);
            }
        }

        this.files = this.files.concat(this.newFiles);
        this.newFiles = [];
    }

    updateUploadProgressIndicator(s3Key, percentageComplete, isResetting = false) {
        let files = this.state.files;

        let fileToUpdate = files.find(file => s3Key.includes(file.name) || file.name === s3Key);
        let index = files.indexOf(fileToUpdate);

        try {
            if (isResetting) {
                fileToUpdate.progress = 0;
            } else {
                let percentageCompleteInteger = (percentageComplete * 100);
                fileToUpdate.progress = (fileToUpdate.progress > percentageCompleteInteger) ? fileToUpdate.progress : percentageCompleteInteger;
            }

            files.splice(index, 1, fileToUpdate);

            this.setState({files: files});
        } catch(error) {

        }
    }

    resetUploadProgressIndicator(s3Key) {
        this.updateUploadProgressIndicator(s3Key, 0, true);
    }

    setQuickPrice(quickPrice) {
       this.state.currency === 'BTC' ? this.setQuickPriceInBTC(quickPrice) : this.setQuickPriceInUSD(quickPrice);
    }

    setQuickPriceInBTC(quickPrice) {
        let priceInBTC = quickPrice / this.state.btcValue;

        this.setState({price: priceInBTC.toFixed(6)});
    }

    setQuickPriceInUSD(quickPrice) {
        this.setState({price: quickPrice});
    }

    renderQuickPrice(quickPrice) {
        return this.state.currency === 'BTC' ? this.renderQuickPriceQuickPriceInBTC(quickPrice) : this.renderQuickPriceQuickPriceInUSD(quickPrice);
    }

    renderQuickPriceQuickPriceInBTC(quickPrice) {
        let priceInBTC = quickPrice / this.state.btcValue;

        return priceInBTC.toFixed(6) + ' BTC';
    }

    renderQuickPriceQuickPriceInUSD(quickPrice) {
        return quickPrice + ' USD';
    }

    convertBTCPriceToUSD() {
        return (this.state.price * this.state.btcValue).toFixed(2);
    }

    clearUploads() {
        this.clearTemporaryPackageId();
        this.files = [];
        this.fileUrls = [];
        this.newFiles = [];
        this.fileBlobs = [];
        this.newFileBlobs = [];
        this.contents = [];
        this.previews = [];
        this.thumbnailUrls = [];
        this.thumbnailBlobs = [];
        this.currentCoverPreview = '';
        this.setState({pagePreviews: [], thumbnails: [], files: [], filesInPackage: 0});
    }

    sortPreviewsAccordingToCoverImage() {
        let updatedPreviews = [];
        let counter = 1;

        for (let i = 0; i < this.previews.length; i++) {
            let currentPreview = this.previews[i];
            if (currentPreview.uri.includes(this.currentCoverPreview)) {
                currentPreview.order = 0;

                if (counter === 1) {
                    counter--;
                }

            } else {
                currentPreview.order = counter;
            }

            counter++;

            updatedPreviews.push(currentPreview);
        }

        updatedPreviews.sort((preview1, preview2) => {
            return (preview1.order > preview2.order) ? 1 : -1;
        });

        return updatedPreviews;
    }

    buildPackageBody() {
        this.contents.sort((content1, content2) => {
            return (content1.uri > content2.uri) ? 1 : -1;
        });

        const sortedPreviews = this.sortPreviewsAccordingToCoverImage();

        return {
            name: this.state.name,
            price: parseFloat(this.state.price),
            description: this.state.description,
            is_adult: this.state.is_adult,
            tags: this.removeDupeTags(this.state.tags.split(",").filter(tag => tag.trim() !== '').map(tag => tag.trim().replace(/\s+/g, ''))),
            contents: this.contents,
            previews: sortedPreviews,
            blocked_regions: this.state.blocked_regions,
            license: this.state.license,
            currency: this.state.currency,
        };
    }

    buildPackagePartialBody() {
        let editedProperties = {};

        if (this.packagePayload.name !== this.state.name) {
            editedProperties.name = this.state.name;
        }

        if (this.packagePayload.price !== this.state.price) {
            editedProperties.price = this.state.price;
            editedProperties.currency = this.state.currency;
        }

        if (this.packagePayload.description !== this.state.description) {
            editedProperties.description = this.state.description;
        }

        if (this.packagePayload.is_adult !== this.state.is_adult) {
            editedProperties.is_adult = this.state.is_adult;
        }

        if (this.packagePayload.tags.join(", ") !== this.state.tags) {
            editedProperties.tags = this.removeDupeTags(this.state.tags.split(",").filter(tag => tag.trim() !== '').map(tag => tag.trim().replace(/\s+/g, '')));
        }

        if (this.user.blocked_regions !== this.state.blocked_regions) {
            editedProperties.blocked_regions = this.state.blocked_regions;
        }

        if (this.packagePayload.license !== this.state.license) {
            editedProperties.license = this.state.license;
        }

        if (this.packagePayload.currency !== this.state.currency) {
            editedProperties.currency = this.state.currency;
        }

        return editedProperties;
    }

    async publishPackage(generateThumbnails = true) {
        if (!this.isPackageMetaDataComplete()) {
            return;
        }

        if (!this.canPublish()) {
            this.showErrorModal(this.cannotPublishReason());
            return;
        }

        this.setState({isPublishingPackage: true});

        if (generateThumbnails) {
            this.generateThumbnails(async () => {
                await this.uploadThumbnails();
                PackageService.savePackage(this.buildPackageBody()).then(response => this.handleSuccessfulPackagePublish()).catch(error => this.handleFailedPackagePublish(error));
            });
        } else {
            PackageService.savePackage(this.buildPackageBody()).then(response => this.handleSuccessfulPackagePublish()).catch(error => this.handleFailedPackagePublish(error));
        }
    }

    async savePackageEdit() {
        if (!this.isPackageMetaDataComplete()) {
            return;
        }

        if (!this.canEdit()) {
            this.showErrorModal(this.cannotEditReason());
            return;
        }

        const partialPackageBody = this.buildPackagePartialBody();

        if (Object.keys(partialPackageBody).length === 0) {
            return;
        }

        this.setState({isSavingPackage: true});

        PackageService.editPackage(this.extractPackageIdFromQueryString(), partialPackageBody).then(response => this.handleSuccessfulPackageEdit()).catch(error => this.handleFailedPackageEdit(error));
    }


    handleSuccessfulPackagePublish() {
        this.setState({isPublishingPackage: false});
        this.clearTemporaryPackageId();
        this.props.navigation.manageContent();
    }

    handleSuccessfulPackageEdit() {
        this.setState({isSavingPackage: false});
        this.props.navigation.manageContent();
    }

    handleFailedPackagePublish(error) {
        if (this.isBackendStillMovingFiles(error)) {
            this.currentuploadRetries++;
            setTimeout(this.publishPackage, 1000);
            return;
        }

        if (this.doesPackageContainDuplicates(error)) {
            this.removeDuplicateFiles(error.response.data.details);
            setTimeout(() => this.publishPackage(false), 1000);
            return;
        }

        this.setState({isPublishingPackage: false});

        this.showErrorModal(this.generateErrorState(error));
    }

    handleFailedPackageEdit(error) {
        this.setState({isSavingPackage: false});

        this.showErrorModal(this.generateErrorState(error));
    }

    isBackendStillMovingFiles(error) {
        if (!error || !error.response || !error.response.data || !error.response.data.error) {
            return false;
        }

        return error.response.data.error === '#MissingAssets' || error.response.data.error === '#NoReservation';
    }

    doesPackageContainDuplicates(error) {
        return !!(error && error.response && error.response.data && error.response.data.error && error.response.data.error === '#DuplicatedFile');
    }

    removeDuplicateFiles(duplicateFiles) {
        duplicateFiles.map(duplicateFile => {
            const contentToRemove = this.contents.find(content => content.uri === duplicateFile);

            this.contents.splice(this.contents.indexOf(contentToRemove), 1);

            return null;
        });
    }

    generateErrorState(error) {
        if (!error || !error.response || !error.response.data || !error.response.data.error) {
            return UPLOAD_ERROR_STATE.UNKNOWN;
        }

        switch (error.response.data.error) {
            case '#DuplicatedFile':
                return UPLOAD_ERROR_STATE.DUPLICATE_FILES;
            case '#BadPackageTags':
                return UPLOAD_ERROR_STATE.INVALID_TAGS;
            default:
                return UPLOAD_ERROR_STATE.UNKNOWN;
        }
    }

    areTagCharactersValid() {
        /*
        We remove all empty tags, we trim all tags, and look for tags with invalid characters
         */
        return !this.state.tags.split(",").filter(tag => tag.trim() !== '').map(tag => tag.trim().replace(/\s+/g, '')).find(tag => !(/^[a-z0-9]+$/i.test(tag)));
    }

    isPackageMetaDataComplete() {
        return this.state.name && this.state.description && this.state.tags && this.state.price;
    }

    canPublish() {
        return this.state.userHasBtcAddress && !this.state.isLoadingPreviews && !this.areFilesBeingUploaded() && this.contents.length > 0 && this.state.pagePreviews.length > 0 && parseFloat(this.state.price) >= MIN_PRICE && this.currentuploadRetries <= MAX_UPLOAD_RETRIES && this.areTagCharactersValid() && this.state.tags.split(",").length <= 1000;
    }

    canEdit() {
        return parseFloat(this.state.price) >= MIN_PRICE && this.currentuploadRetries <= MAX_UPLOAD_RETRIES && this.areTagCharactersValid() && this.state.tags.split(",").length <= 1000;
    }

    cannotPublishReason() {
        if (!this.state.userHasBtcAddress) {
            return UPLOAD_ERROR_STATE.NO_BTC_ADDRESS;
        }

        if (this.state.isLoadingPreviews) {
            return UPLOAD_ERROR_STATE.FILES_PROCESSING;
        }

        if (this.areFilesBeingUploaded()) {
            return UPLOAD_ERROR_STATE.FILE_UPLOADING;
        }

        if (this.contents.length === 0) {
            return UPLOAD_ERROR_STATE.NO_FILES;
        }

        if (this.state.pagePreviews.length === 0) {
            return UPLOAD_ERROR_STATE.NO_PREVIEWS;
        }

        if (this.state.isLoadingPreviews) {
            return UPLOAD_ERROR_STATE.FILES_PROCESSING;
        }

        if (parseFloat(this.state.price) < MIN_PRICE) {
            return UPLOAD_ERROR_STATE.PRICE_TOO_LOW;
        }

        if (this.currentuploadRetries === MAX_UPLOAD_RETRIES) {
            return UPLOAD_ERROR_STATE.UPLOAD_RETRY_EXCEEDED
        }

        if (!this.areTagCharactersValid()) {
            return UPLOAD_ERROR_STATE.INVALID_TAG_CHARACTERS;
        }

        if (this.state.tags.split(",").length > 100) {
            return UPLOAD_ERROR_STATE.TOO_MANY_TAGS;
        }

        return UPLOAD_ERROR_STATE.UNKNOWN;
    }

    cannotEditReason() {
        if (parseFloat(this.state.price) < MIN_PRICE) {
            return UPLOAD_ERROR_STATE.PRICE_TOO_LOW;
        }

        if (this.currentuploadRetries === MAX_UPLOAD_RETRIES) {
            return UPLOAD_ERROR_STATE.UPLOAD_RETRY_EXCEEDED
        }

        if (!this.areTagCharactersValid()) {
            return UPLOAD_ERROR_STATE.INVALID_TAG_CHARACTERS;
        }

        if (this.state.tags.split(",").length > 100) {
            return UPLOAD_ERROR_STATE.TOO_MANY_TAGS;
        }

        return UPLOAD_ERROR_STATE.UNKNOWN;
    }

    areFilesBeingUploaded() {
        for (let i = 0; i < this.state.files.length; i++) {
            if (this.state.files[i].progress < 100) {
                return true;
            }
        }

        return false;
    }

    numberOfFilesUploading() {
        let count = 0;

        for (let i = 0; i < this.state.files.length; i++) {
            if (this.state.files[i].progress < 100) {
                count++;
            }
        }

        return count;
    }

    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation}/>
    }

    renderFooter() {
        return <Footer />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation}/>
    }

    renderEmptyUploadContainer() {
        return <div className="box__input">
            <svg className="box__icon" xmlns="http://www.w3.org/2000/svg" width="50"
                 height="43" viewBox="0 0 50 43">
                <path
                    d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
            </svg>
            <input onChange={this.handleClickToUpload} type="file" name="myfiles" id="myfiles" className="box__file" multiple />
            <label htmlFor="myfiles">Drop your files here or <div className='upload-container-partial-text'><strong>click to upload</strong></div></label>
        </div>;
    }

    renderThumbnailState(thumbnailName) {
        if (thumbnailName === this.currentCoverPreview) {
            return 'content-preview-item content-preview-item-is-cover';
        }

        if (this.state.pagePreviews.includes(thumbnailName)) {
            return 'content-preview-item content-preview-item-is-preview';
        }

        return "content-preview-item";
    }

    renderThumbnails() {
        return this.state.files.sort((file1, file2) => file1.name > file2.name ? 1 : -1).map((thumbnail, index) => {
            return <div onClick={() => this.handlePreviewsChange(thumbnail.name, thumbnail.type)} key={index} className={this.renderThumbnailState(thumbnail.name)}>
                <img alt='Package Item Settings' className='content-preview-item-delete' src={imageSettingsIcon} name={thumbnail.name} onClick={this.handleItemSettings} />
                {(thumbnail.progress < 100) && <CircularProgressbar styles={this.renderUploadProgressIndicatorStyles()} className="upload-loading-indicator" value={thumbnail.progress} /> }
                <img alt='' key={index} src={thumbnail.content} className="" />
                <br/>
                <span>{thumbnail.name}</span>
            </div>
        });
    }

    renderUploadProgressIndicatorStyles() {
        return buildStyles({
            pathColor: "#3e94d6",
            trailColor: "#afc7e6"
        });
    }

    renderFilesInPackageText() {
        let processingPrefix = '';
        let newPostfix = '';
        let totalPostfix = '';

        if (this.state.isLoadingPreviews) {
            processingPrefix = 'Processing ';
            newPostfix = ' new';
        } else {
            totalPostfix = 'total';
        }

        if (this.state.filesInPackage === 1) {
            return processingPrefix + this.state.filesInPackage + newPostfix + ' file ' + totalPostfix + ' in this Package';
        } else if (this.state.filesInPackage > 1) {
            return processingPrefix + this.state.filesInPackage + newPostfix + ' files ' + totalPostfix + ' in this Package';
        }

        return '';
    }

    renderPriceInputPlaceholder() {
        return this.state.currency === 'BTC' ? 'Custom Price in BTC' : 'Custom Price in USD';
    }

    renderUploadContentEditing() {
        return <section className="featured my settings upload">

            <form onSubmit={this.handleFormSubmit} className="form-signin" action="#">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="category">
                                <div className="category-header">{this.renderPackageTitle()}</div>
                                <div className="user-avatar">
                                    <div className="category-title">Package name</div>
                                    <input onChange={this.handlePackageDataChange} value={this.state.name} disabled={true} id="packageName" type="text" name="name" className="form-control" placeholder="Name" required />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Package description</div>
                                    <textarea onChange={this.handlePackageDataChange} value={this.state.description} id="packageDescription" name="description" className="form-control"  placeholder="Description" rows="6" required />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Tags</div>
                                    <input onChange={this.handlePackageDataChange} value={this.state.tags} id="packageTags" type="text" name="tags" className="form-control" placeholder="Tags (Comma separated)" required />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Geo Block</div>
                                    <select key={this.state.blocked_regions.toString()} name="blocked_regions" onChange={this.handlePackageDataChange} className="custom-select" value={this.state.blocked_regions} multiple>
                                        {
                                            this.countries.map(country => <option key={country.value} value={country.value}>{country.label}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title" style={{"paddingBottom": "0"}}>
                                        <div className="custom-control custom-switch">
                                            <input onChange={this.handlePackageDataChange} checked={this.state.is_adult} value={this.state.is_adult} name="is_adult" disabled={true} type="checkbox" className="custom-control-input" id="18plus" />
                                            <label className="custom-control-label" htmlFor="18plus">Is Package Adult (Not Safe for Work)?</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Reference Currency</div>
                                    <div className="quick-price-container">
                                        <select name="currency" onChange={this.handlePackageDataChange} className="custom-select" value={this.state.currency}>
                                            <option key={CURRENCY.BTC} value={CURRENCY.BTC}>{CURRENCY.BTC}</option>
                                            <option key={CURRENCY.USD} value={CURRENCY.USD}>{CURRENCY.USD}</option>
                                        </select>
                                    </div>
                                    <div className="quick-price-container">
                                        <span className='reference-currency-text'>While your package will always be sold in BTC and you will always be paid in BTC, you can choose a reference currency to avoid the volatility of BTC. If you pick a reference currency
                                            other than BTC, your package price in BTC relative to the reference currency will be dynamically calculated everytime the package is displayed.</span>
                                    </div>
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Price</div>
                                    <div className="quick-price-container">
                                        <div onClick={() => this.setQuickPrice(10)} className="quick-price">{this.renderQuickPrice(10)}</div>
                                        <div onClick={() => this.setQuickPrice(15)} className="quick-price">{this.renderQuickPrice(15)}</div>
                                        <div onClick={() => this.setQuickPrice(25)} className="quick-price">{this.renderQuickPrice(25)}</div>
                                        <div onClick={() => this.setQuickPrice(50)} className="quick-price">{this.renderQuickPrice(50)}</div>
                                        <div onClick={() => this.setQuickPrice(100)} className="quick-price">{this.renderQuickPrice(100)}</div>
                                        <div onClick={() => this.setQuickPrice(500)} className="quick-price">{this.renderQuickPrice(500)}</div>
                                        <div onClick={() => this.setQuickPrice(1000)} className="quick-price">{this.renderQuickPrice(1000)}</div>
                                    </div>
                                    <input onChange={this.handlePackageDataChange} id="packagePrice" type="number" name="price" value={this.state.price} step={0.0000001} className="form-control" placeholder={this.renderPriceInputPlaceholder()} required />
                                    <br />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Copyright License</div>
                                    <div className="quick-price-container">
                                        <select name="license" onChange={this.handleLicenseChange} className="custom-select" value={this.state.license}>
                                            <option key={PACKAGE_LICENSE.ATTRIBUTION} value={PACKAGE_LICENSE.ATTRIBUTION}>{PACKAGE_LICENSE.ATTRIBUTION}</option>
                                            <option key={PACKAGE_LICENSE.ATTRIBUTION_NONCOMMERCIAL} value={PACKAGE_LICENSE.ATTRIBUTION_NONCOMMERCIAL}>{PACKAGE_LICENSE.ATTRIBUTION_NONCOMMERCIAL}</option>
                                            <option key={PACKAGE_LICENSE.PERSONAL_USE} value={PACKAGE_LICENSE.PERSONAL_USE}>{PACKAGE_LICENSE.PERSONAL_USE}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container inputs">
                    <div id="SignupMessage" style={{"display":"none"}}></div>

                    <div id="SignupButtonHolder">
                        <button onClick={this.savePackageEdit} id="SignupButton" disabled={this.state.isSavingPackage} className={this.state.isSavingPackage ? "btn--gray button-loading-border" : "btn--blueGr"}>Save</button>
                    </div>
                </div>
            </form>
        </section>
    }

    renderUploadContentNewPackage() {
        return <section className="featured my settings upload">

            <form onSubmit={this.handleFormSubmit} className="form-signin" action="#">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="category">
                                <div className="category-header">Create a Package</div>
                                <div className="user-avatar">
                                    <div className="category-title">Package name</div>
                                    <input onChange={this.handlePackageDataChange} value={this.state.name} id="packageName" type="text" name="name" className="form-control" placeholder="Name" required />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Package description</div>
                                    <textarea onChange={this.handlePackageDataChange} value={this.state.description} id="packageDescription" name="description" className="form-control"  placeholder="Description" rows="6" required />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Tags</div>
                                    <input onChange={this.handlePackageDataChange} value={this.state.tags} id="packageTags" type="text" name="tags" className="form-control" placeholder="Tags (Comma separated)" required />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Geo Block</div>
                                    <select key={this.state.blocked_regions.toString()} name="blocked_regions" onChange={this.handlePackageDataChange} className="custom-select" value={this.state.blocked_regions} multiple>
                                        {
                                            this.countries.map(country => <option key={country.value} value={country.value}>{country.label}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title" style={{"paddingBottom": "0"}}>
                                        <div className="custom-control custom-switch">
                                            <input onChange={this.handlePackageDataChange} checked={this.state.is_adult} value={this.state.is_adult} name="is_adult" type="checkbox" className="custom-control-input" id="18plus" />
                                            <label className="custom-control-label" htmlFor="18plus">Is Package Adult (Not Safe for Work)?</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Reference Currency</div>
                                    <div className="quick-price-container">
                                        <select name="currency" onChange={this.handlePackageDataChange} className="custom-select" value={this.state.currency}>
                                            <option key={CURRENCY.BTC} value={CURRENCY.BTC}>{CURRENCY.BTC}</option>
                                            <option key={CURRENCY.USD} value={CURRENCY.USD}>{CURRENCY.USD}</option>
                                        </select>
                                    </div>
                                    <div className="quick-price-container">
                                        <span className='reference-currency-text'>While your package will always be sold in BTC and you will always be paid in BTC, you can choose a reference currency to avoid the volatility of BTC. If you pick a reference currency
                                            other than BTC, your package price in BTC relative to the reference currency will be dynamically calculated everytime the package is displayed.</span>
                                    </div>
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Price</div>
                                    <div className="quick-price-container">
                                        <div onClick={() => this.setQuickPrice(10)} className="quick-price">{this.renderQuickPrice(10)}</div>
                                        <div onClick={() => this.setQuickPrice(15)} className="quick-price">{this.renderQuickPrice(15)}</div>
                                        <div onClick={() => this.setQuickPrice(25)} className="quick-price">{this.renderQuickPrice(25)}</div>
                                        <div onClick={() => this.setQuickPrice(50)} className="quick-price">{this.renderQuickPrice(50)}</div>
                                        <div onClick={() => this.setQuickPrice(100)} className="quick-price">{this.renderQuickPrice(100)}</div>
                                        <div onClick={() => this.setQuickPrice(500)} className="quick-price">{this.renderQuickPrice(500)}</div>
                                        <div onClick={() => this.setQuickPrice(1000)} className="quick-price">{this.renderQuickPrice(1000)}</div>
                                    </div>
                                    <input onChange={this.handlePackageDataChange} id="packagePrice" type="number" name="price" value={this.state.price} step={0.0000001} className="form-control" placeholder={this.renderPriceInputPlaceholder()} required />
                                    <br />
                                </div>
                                <div className="user-avatar">
                                    <div className="category-title">Copyright License</div>
                                    <div className="quick-price-container">
                                        <select name="license" onChange={this.handleLicenseChange} className="custom-select" value={this.state.license}>
                                            <option key={PACKAGE_LICENSE.ATTRIBUTION} value={PACKAGE_LICENSE.ATTRIBUTION}>{PACKAGE_LICENSE.ATTRIBUTION}</option>
                                            <option key={PACKAGE_LICENSE.ATTRIBUTION_NONCOMMERCIAL} value={PACKAGE_LICENSE.ATTRIBUTION_NONCOMMERCIAL}>{PACKAGE_LICENSE.ATTRIBUTION_NONCOMMERCIAL}</option>
                                            <option key={PACKAGE_LICENSE.PERSONAL_USE} value={PACKAGE_LICENSE.PERSONAL_USE}>{PACKAGE_LICENSE.PERSONAL_USE}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="upload-dashboard">
                                    <div className="files-in-packages">{this.renderFilesInPackageText()}</div>
                                    <div className={this.canAddFilesForUpload() ? "clear-uploads" : "clear-uploads gray"}>
                                            <span className="add-files">
                                            <input onChange={this.handleClickToUpload} disabled={!this.canAddFilesForUpload()} type="file" name="myaddfiles" id="myaddfiles" className="box__file" multiple />
                                             <label htmlFor="myaddfiles"><strong>Add Files</strong></label>
                                        </span>
                                    </div>
                                    <div onClick={() => this.clearUploads()} className="clear-uploads">Clear Uploads</div>
                                </div>
                                <div className="upload-container">
                                    <div ref={this.dropTargetReference} id="upload-files"  className={this.state.isDragging ? "box has-advanced-upload is-dragover" : "box has-advanced-upload"}>
                                        {this.state.isLoadingPreviews ? <LoadingIndicator/> : (this.state.files.length === 0 ? this.renderEmptyUploadContainer() : this.renderThumbnails())}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container inputs">
                    <div id="SignupMessage" style={{"display":"none"}}></div>

                    <div id="SignupButtonHolder">
                        <button onClick={this.publishPackage} id="SignupButton" disabled={this.state.isPublishingPackage} className={this.state.isPublishingPackage ? "btn--gray button-loading-border" : "btn--blueGr"}>Publish</button>
                    </div>
                </div>
            </form>
        </section>
    }

    renderPackageTitle() {
        return this.isEditing ? 'Edit a Package' : 'Create a Package';
    }

    renderUploadContent() {
        return this.isEditing ? this.renderUploadContentEditing() : this.renderUploadContentNewPackage();
    }

    renderErrorStateMessage() {
        switch(this.state.errorState) {
            case UPLOAD_ERROR_STATE.UNSUPPORTED_FILE:
                return "One of the files you've trying to upload isn't supported.";
            case UPLOAD_ERROR_STATE.PACKAGE_TOO_BIG:
                return "The files you're trying to upload are too big. Total Package size is limited to " + (MAX_PACKAGE_SIZE / BYTES_IN_GB) + ' GB';
            case UPLOAD_ERROR_STATE.NO_BTC_ADDRESS:
                return "Make sure you've entered a BTC address to which we can deposit your earnings before publishing your package. You can do this in the Settings page.";
            case UPLOAD_ERROR_STATE.FILE_UPLOADING:
                return "Files are still uploading. Please wait until uploading finishes.";
            case UPLOAD_ERROR_STATE.FILES_PROCESSING:
                return "Files are still processing. Please wait until uploading finishes.";
            case UPLOAD_ERROR_STATE.NO_FILES:
                return "Please make sure to upload content before publishing your package.";
            case UPLOAD_ERROR_STATE.NO_PREVIEWS:
                return "Please make sure to provide at least one image preview in your package. To select a preview, click / tap on one or more images in your uploads.";
            case UPLOAD_ERROR_STATE.PRICE_TOO_LOW:
                return `Please make sure to price your package above ${MIN_PRICE} BTC.`;
            case UPLOAD_ERROR_STATE.PACKAGE_QTY_TOO_HIGH:
                return `Packages are limited to a maximum of ${MAX_PACKAGE_QTY} items.`;
            case UPLOAD_ERROR_STATE.PACKAGE_PREVIEW_QTY_TOO_HIGH:
                return `Packages are limited to a maximum of ${MAX_PACKAGE_PREV} previews.`;
            case UPLOAD_ERROR_STATE.DUPLICATE_FILES:
                return 'Packages cannot contain duplicate files. Please remove any duplicates in your package and try publishing again.'
            case UPLOAD_ERROR_STATE.CONNECTION_ERROR:
                return `The package could not be published. Please try reducing the number of files in the package.`;
            case UPLOAD_ERROR_STATE.INVALID_TAGS:
                return `The package could not be published. Please make sure you have less than 40 tags, each tag is less than 100 characters and contains no special characters.`;
            case UPLOAD_ERROR_STATE.UPLOAD_RETRY_EXCEEDED:
                return `We're still processing your Package but have encountered a few delays! Please wait and retry publishing in a few minutes.`;
            case UPLOAD_ERROR_STATE.INVALID_TAG_CHARACTERS:
                return 'Some tags in your Package contain invalid characters. Please make sure that your tags contain only letters and or numbers';
            case UPLOAD_ERROR_STATE.TOO_MANY_TAGS:
                return 'You have too many tags in this Package. Please limit tag number to less than 100';
            default:
                return "An unknown error has occurred. Please contact support.";
        }
    }

    renderUploadStatusBar() {
        return <div className="upload-statusbar">{this.numberOfFilesUploading()} {this.numberOfFilesUploading() === 1 ? 'File' : 'Files'} left to upload</div>
    }

    renderModal() {
        return <Modal isActive={this.state.showErrorModal} handleClose={this.handleErrorModalClose} title="Error!" message={this.renderErrorStateMessage()} />
    }

    renderImageSettingsModalTitle() {
        return this.currentSettingsItem;
    }

    renderImageSettingsModalMessage() {
        return `What would you like to do with ${this.currentSettingsItem}?`;
    }

    renderImageSettingsModal() {
        return <ImageSettingsModal isActive={this.state.showImageSettingsModal} handleClose={this.handleItemSettingsModalClose} handleDeleteClick={this.handleDeleteItem} handleCoverClick={this.handleCoverClick} item={this.currentSettingsItem} isItemCurrentCover={this.currentSettingsItem === this.currentCoverPreview} title={this.renderImageSettingsModalTitle()} message={this.renderImageSettingsModalMessage()} />
    }

    renderUploadPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            {this.renderUploadContent()}
            {this.renderFooter()}
            {this.areFilesBeingUploaded() ? this.renderUploadStatusBar() : ''}
            {this.renderRenderResponsiveNavigation()}
            {this.renderModal()}
            {this.renderImageSettingsModal()}
            <HelmetComponent title='Upload Package' description='OpenSwap.io package upload.' />
        </div>
    }

    render() {
        return this.renderUploadPage();
    }
}

export default UploadPage;
