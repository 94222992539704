import {Auth} from "aws-amplify";
import {API} from "@aws-amplify/api";

class Authorization {
    static async authorizationHeader() {
        try {
            const cognitoUserSession = await Auth.currentSession();
            if (cognitoUserSession.getIdToken().decodePayload()['user_id']) {
                return Authorization.cognitoSessionToAuthorizationHeader(cognitoUserSession);
            }
            await Authorization.onboardUser(cognitoUserSession); // for users that didn't onboard during sign up
            await Authorization.refreshToken(); // for users with tokens without the 'user_id' claim
            return Authorization.cognitoSessionToAuthorizationHeader(await Auth.currentSession());
        } catch (e) {
        }
        return {}
    }

    static async refreshToken() {
        return await Auth.currentAuthenticatedUser({
            bypassCache: true
        });
    }

    static async onboardUser(currentSession) {
        const init = {
            body: {
                "IdentityJWT": "Bearer " + currentSession.getIdToken().getJwtToken()
            }
        };
        return await API.post('BffUiMain', '/api/onboard', init)
            .then(() => {
                console.info('User onboarded');
            }).catch(e => {
                console.info("Calling the Onboard User API failed", e);
            });
    }

    static cognitoSessionToAuthorizationHeader(cognitoUserSession) {
        return {
            'Authorization': `Bearer ${cognitoUserSession.getIdToken().getJwtToken()}`
        };
    }
}

export default Authorization;