import EnvironmentService from "./environment";
import axios from "axios";
import Authorization from "./authorization";

const api = axios.create({
    baseURL: `${EnvironmentService.baseURL()}/api`,
    timeout: 25000,
    responseType: "json"
});

class SaleService {
    static async fetchMySales() {
        let path = '/purchases/sales';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true,
        };

        return await api.get(path, {headers: myInit["headers"]});
    }
}

export default SaleService;
