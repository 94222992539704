let EnvironmentService = {
    staticURL() {
        return `${EnvironmentService.baseURL()}/static`;
    },

    baseURL() {
        return (window.location.host.includes('localhost')) ? `https://develop.openswap.io` : `https://${window.location.host}`;
    },

    blockChainInfoURL() {
        return (window.location.host.includes('localhost') || window.location.host.includes('develop')) ? 'https://api.blockcypher.com/v1/btc/test3' : 'https://api.blockcypher.com/v1/btc/main';
    }
};

export default EnvironmentService
