import CURRENCY from "../constants/currency";

class PriceResolver {
    static resolvePriceForPackage(packagePayload) {
        if (!packagePayload) {
            return 0;
        }

        const currency = packagePayload.currency;

        if (currency === CURRENCY.USD) {
            return packagePayload.price_btc;
        }

        return packagePayload.price;
    }
}

export default PriceResolver;
