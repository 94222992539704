import React, {Component} from 'react';
import queryString from 'query-string'
import Popup from 'react-popup';
import './App.css';
import { Amplify } from 'aws-amplify'
import {Hub} from '@aws-amplify/core';
import {graphqlOperation} from '@aws-amplify/api-graphql'
import {API} from '@aws-amplify/api'
import {Auth} from '@aws-amplify/auth'
import awsconfig from '../../aws-exports';
import * as subscriptions from '../../graphql/subscriptions';
import * as mutations from '../../graphql/mutations';

let urlsIn = awsconfig.oauth.redirectSignIn.split(",");
let urlsOut = awsconfig.oauth.redirectSignOut.split(",");
const oauth = {
    domain: awsconfig.oauth.domain,
    scope: awsconfig.oauth.scope,
    redirectSignIn: awsconfig.oauth.redirectSignIn,
    redirectSignOut: awsconfig.oauth.redirectSignOut,
    responseType: awsconfig.oauth.responseType
};
let hasLocalhost = (hostname) => Boolean(hostname.match(/localhost/) || hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/));
let hasHostname = (hostname) => Boolean(hostname.includes(window.location.hostname));
let isLocalhost = hasLocalhost(window.location.hostname);
if (isLocalhost) {
    urlsIn.forEach((e) => {
        if (hasLocalhost(e)) {
            oauth.redirectSignIn = e;
        }
    });
    urlsOut.forEach((e) => {
        if (hasLocalhost(e)) {
            oauth.redirectSignOut = e;
        }
    });
} else {
    urlsIn.forEach((e) => {
        if (hasHostname(e)) {
            oauth.redirectSignIn = e;
        }
    });
    urlsOut.forEach((e) => {
        if (hasHostname(e)) {
            oauth.redirectSignOut = e;
        }
    });
}
let configUpdate = awsconfig;
configUpdate.oauth = oauth;


if (window.location.host.startsWith('www')) {
    Amplify.Logger.LOG_LEVEL = 'WARN';
} else {
    console.log("Not production, going INFO");
    Amplify.Logger.LOG_LEVEL = 'INFO'
}
Amplify.configure(configUpdate);

/** The prompt content component */
class Prompt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue
        };

        this.onChange = (e) => this._onChange(e);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.props.onChange(this.state.value);
        }
    }

    _onChange(e) {
        let value = e.target.value;

        this.setState({value: value});
    }

    render() {
        return <input type="text" placeholder={this.props.placeholder} className="mm-popup__input"
                      value={this.state.value} onChange={this.onChange}/>;
    }
}

/** Prompt plugin */
Popup.registerPlugin('prompt', function (defaultValue, placeholder, callback) {
    let promptValue = null;
    let promptChange = function (value) {
        promptValue = value;
    };

    this.create({
        title: 'What\'s your name?',
        content: <Prompt onChange={promptChange} placeholder={placeholder} value={defaultValue}/>,
        buttons: {
            left: ['cancel'],
            right: [{
                text: 'Save',
                key: '⌘+s',
                className: 'success',
                action: function () {
                    callback(promptValue);
                    Popup.close();
                }
            }]
        }
    });
});

class ChangeCognitoAttributeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {attributeName: '', attributeValue: ''};

        this.handleChangeA = this.handleChangeA.bind(this);
        this.handleChangeB = this.handleChangeB.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeA(event) {
        this.setState({attributeName: event.target.value});
    }

    handleChangeB(event) {
        this.setState({attributeValue: event.target.value});
    }

    handleSubmit(event) {
        Auth.currentAuthenticatedUser()
            .then(user => {
                let data = {};
                data[this.state.attributeName] = this.state.attributeValue;
                return Auth.updateUserAttributes(user, data)
            });
    }

    render() {
        return (
            <div>
                <label>
                    Cognito Attribute Name:
                    <input type="text" onChange={this.handleChangeA} value={this.state.attributeName}/>
                </label><br/>
                <label>
                    Cognito Attribute Value:
                    <input type="text" onChange={this.handleChangeB} value={this.state.attributeValue}/>
                </label><br/>
                <button onClick={this.handleSubmit}>Change Attribute</button>
            </div>
        );
    }
}

class RunCognitoAttributeVerificationButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {verificationState: ''};
    }

    componentDidMount() {
        console.log('on component mount (RunCognitoAttributeVerificationButton)');
        const values = queryString.parse(window.location.href.replace(window.origin + '/?', ''));

        if (typeof values.verifyAttribute !== 'undefined') {
            return Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => Auth.verifyUserAttributeSubmit(user, values.verifyAttribute, values.code))
                .then(() => {
                    this.setState({verificationState: 'Verified!!!'});
                })
                .catch(err => {
                    console.log(err);
                    this.setState({verificationState: 'Verification failed'});
                });
        }
        this.setState({verificationState: 'Verification not attempted'});
        return Promise.resolve(true);
    }

    render() {
        return (
            <div>
                <label>
                    {this.state.verificationState}
                </label>
            </div>
        );
    }
}

class Debug extends Component {

    handleClickVerifyEmail = () => {
        return Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => Auth.verifyUserAttribute(user, 'email'))
            .catch(err => console.log(err));
    };

    handleClickCurrentSession = () => {
        return Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => console.log(user))
            .catch(err => console.log(err));
    };

    handleClickMostSold = () => {
        let apiName = 'BffUiMain';
        let path = '/packages/most_sold';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {}
        }

        let limit = prompt("Limit value, type '###' for none");
        let nextKey = prompt("Next Key value, type '###' for none");
        let filterAttribute = prompt("Filter Attribute, type '###' for none");
        let filterValue = prompt("Filter value, type '###' for none");
        let returnComments = prompt("Return comments? y/n");

        if (returnComments === "y") {
            myInit.queryStringParameters["returnComments"] = "true";
        }

        if (filterAttribute && filterValue && filterAttribute !== "###" && filterValue !== "###") {
            myInit.queryStringParameters["filterAttribute[" + filterAttribute + "]"] = filterValue;
        }

        while (prompt("Another filter? y/n") === "y") {
            filterAttribute = prompt("Filter Attribute");
            filterValue = prompt("Filter value");
            myInit.queryStringParameters["filterAttribute[" + filterAttribute + "]"] = filterValue;
        }

        if (limit && limit !== "###") {
            myInit.queryStringParameters["limit"] = limit;
        }

        if (nextKey && nextKey !== "###") {
            myInit.queryStringParameters["nextKey"] = nextKey;
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickGetUserPackages = (addedPath) => {
        let apiName = 'BffUiMain';
        let path = '/packages' + addedPath;
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {}
        }

        let limit = prompt("Limit value, type '###' for none");
        let nextKey = prompt("Next Key value, type '###' for none");
        let filterAttribute = prompt("Filter Attribute, type '###' for none");
        let filterValue = prompt("Filter value, type '###' for none");
        let returnContents = prompt("Return contents? y/n");
        let returnComments = prompt("Return comments? y/n");

        if (returnComments === "y") {
            myInit.queryStringParameters["returnComments"] = "true";
        }

        if (returnContents === "y") {
            myInit.queryStringParameters["returnContents"] = "true";
        }

        if (filterAttribute && filterValue && filterAttribute !== "###" && filterValue !== "###") {
            myInit.queryStringParameters["filterAttribute[" + filterAttribute + "]"] = filterValue;
        }

        while (prompt("Another filter? y/n") === "y") {
            filterAttribute = prompt("Filter Attribute");
            filterValue = prompt("Filter value");
            myInit.queryStringParameters["filterAttribute[" + filterAttribute + "]"] = filterValue;
        }

        if (limit && limit !== "###") {
            myInit.queryStringParameters["limit"] = limit;
        }

        if (nextKey && nextKey !== "###") {
            myInit.queryStringParameters["nextKey"] = nextKey;
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickCommentPackage = () => {
        let apiName = 'BffUiMain';
        let path = '';
        let myInit = { // OPTIONAL
            headers: {},
            body: {}
        }

        let theComment = prompt("Comment?");
        let packageId = prompt("PackageID?");

        path = "/packages/" + packageId + "/comment";

        myInit.body = {
            comments: [
                {
                    comment: theComment
                }
            ]
        }

        return API.post(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickPostPackage = (state) => {
        let apiName = 'BffUiMain';
        let path = '/packages';
        let myInit = {
            body: state,
            headers: {}
        }

        return API.post(apiName, path, myInit).then(response => {
            console.info(response);
        }).catch(error => {
            console.log(error);
        });
    };

    handleClickDeletePackage = () => {
        let apiName = 'BffUiMain';
        let path = '';
        let myInit = { // OPTIONAL
            headers: {}
        }

        let packageId = prompt("PackageID?");
        path = "/packages/" + packageId;

        return API.del(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickPostPurchase = () => {
        let apiName = 'BffUiMain';
        let path = '/purchases';
        let myInit = {
            body: {
                package: {
                    _id: prompt("Package ID")
                }
            },
            headers: {}
        }

        return  Auth.currentAuthenticatedUser({bypassCache: false }).then(userData => {
            myInit.body["username"] = userData.username;
            return API.post(apiName, path, myInit).then(response => {
                console.info(response);
            }).catch(error => {
                console.log(error);
            })
        });
    };

    handleClickGetAllPurchases = () => {
        let apiName = 'BffUiMain';
        let path = '/purchases';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {}
        }

        let limit = prompt("Limit value, type '###' for none");
        let nextKey = prompt("Next Key value, type '###' for none");

        if (limit && limit !== "###") {
            myInit.queryStringParameters["limit"] = limit;
        }

        if (nextKey && nextKey !== "###") {
            myInit.queryStringParameters["nextKey"] = nextKey;
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickGetSales = () => {
        let apiName = 'BffUiMain';
        let path = '/purchases/sales';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {}
        }

        let limit = prompt("Limit value, type '###' for none");
        let nextKey = prompt("Next Key value, type '###' for none");

        if (limit && limit !== "###") {
            myInit.queryStringParameters["limit"] = limit;
        }

        if (nextKey && nextKey !== "###") {
            myInit.queryStringParameters["nextKey"] = nextKey;
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickGetPurchase = () => {
        let apiName = 'BffUiMain';
        let path = '/purchases/';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {}
        }

        let filterAttribute = prompt("Filter Attribute, type '###' for none");
        let filterValue = prompt("Filter value, type '###' for none");

        if (filterAttribute && filterValue && filterAttribute !== "###" && filterValue !== "###") {
            myInit.queryStringParameters["filterAttribute[" + filterAttribute + "]"] = filterValue;
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickGetUser = () => {
        let apiName = 'BffUiMain';
        let path = '/users/info';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickGetUserNickname = () => {
        let apiName = 'BffUiMain';
        let path = '/users/info';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {  // OPTIONAL
                nickname: ''
            }
        };

        return Auth.currentAuthenticatedUser().then(user => {
            myInit.queryStringParameters.nickname = user.signInUserSession.idToken.payload.nickname;
        }).then(() => {
            return API.get(apiName, path, myInit).then(response => {
                console.info(response)
            }).catch(error => {
                console.log(error)
            })
        });
    };

    handleClickGetUserFollowers = () => {
        let apiName = 'BffUiMain';
        let path = '/users/followers';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {}
        }

        let limit = prompt("Limit value, type '###' for none");
        let nextKey = prompt("Next Key value, type '###' for none");
        let filterValue = prompt("Filter value, type '###' for none");

        if (filterValue && filterValue !== "###") {
            myInit.queryStringParameters["filterValue"] = filterValue;
            myInit.queryStringParameters["filterAttribute"] = "_id";
        }

        if (limit && limit !== "###") {
            myInit.queryStringParameters["limit"] = limit;
        }

        if (nextKey && nextKey !== "###") {
            myInit.queryStringParameters["nextKey"] = nextKey;
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickGetUserFollowing = () => {
        let apiName = 'BffUiMain';
        let path = '/users/following';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {}
        }

        let limit = prompt("Limit value, type '###' for none");
        let nextKey = prompt("Next Key value, type '###' for none");
        let filterValue = prompt("Filter value, type '###' for none");

        if (filterValue && filterValue !== "###") {
            myInit.queryStringParameters["filterValue"] = filterValue;
            myInit.queryStringParameters["filterAttribute"] = "_id";
        }

        if (limit && limit !== "###") {
            myInit.queryStringParameters["limit"] = limit;
        }

        if (nextKey && nextKey !== "###") {
            myInit.queryStringParameters["nextKey"] = nextKey;
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickGetOtherUser = () => {
        let apiName = 'BffUiMain';
        let path = '/users/info';
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true,
            queryStringParameters: {
                publicName: prompt("Public Name Query Param")
            }
        }

        return API.get(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickPutUserFollow = () => {
        let apiName = 'BffUiMain';
        let path = '/users/follow/' + prompt("Identity ID");
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true
        }

        return API.put(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickDeleteUserFollow = () => {
        let apiName = 'BffUiMain';
        let path = '/users/follow/' + prompt("Identity ID");
        let myInit = { // OPTIONAL
            headers: {}, // OPTIONAL
            response: true
        }

        return API.del(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleClickPutUser = () => {
        let apiName = 'BffUiMain';
        let path = '/users';
        let myInit = {
            body: {}
        }

        myInit.body[prompt("Which Attribute?")] = prompt("Value?")

        return API.put(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };



    handleClickAdminListUsersInGroup() {
        let apiName = 'AdminQueries';
        let path = '/listUsersInGroup';
        let myInit = {
            queryStringParameters: {
                "groupname": "admins"
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: ''
            }
        }

        Auth.currentSession().then(currentSession => {
            myInit.headers.Authorization = currentSession.getAccessToken().getJwtToken()
            return Promise.resolve();
        }).then(() => {
            return API.get(apiName, path, myInit).then(response => {
                console.info(response);
            }).catch(error => {
                console.log(error)
            })
        });
    }

    constructor(props) {
        super(props);
        this.signOut = this.signOut.bind(this);
        // let the Hub module listen on Auth events
        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    this.setState({
                        authState: 'signedIn',
                        authData: data.payload.data,
                        packageBody: this.getState().packageBody || {}
                    });
                    break;
                case 'signIn_failure':
                    this.setState({
                        authState: 'signIn',
                        authData: null,
                        authError: data.payload.data,
                        packageBody: this.getState().packageBody || {}
                    });
                    break;
                default:
                    break;
            }
        });
        this.handleChangePackageBody = this.handleChangePackageBody.bind(this);
        this.state = {
            authState: 'loading',
            authData: null,
            authError: null,
            packageBody: {}
        }
    }

    handleChangePackageBody(event) {
        try {
            this.setState({packageBody: JSON.parse(event.target.value)});
        } catch (e) {
            console.info("Ignored that text");
        }
    }

    handleClickObserveInbox = async () => {
        const username = await Auth.currentSession().then(userData => userData.getIdToken().decodePayload()['cognito:username']);
        const subscription = API.graphql(
            graphqlOperation(subscriptions.onSendChatMessage, {owner: username})
        ).subscribe({
            next: ({provider, value}) => console.log("Received:", {provider, value})
        });

        console.log("Subscription id", subscription);
    }

    handleClickSendMessage = () => {
        API.graphql(graphqlOperation(mutations.sendChatMessage, {
                text: prompt("Type in the message"),
                recipientSub: prompt("Type in the other user's sub ID")
            })
        );
    }

    handleCreatePost = async () => {
        API.graphql({
                query: mutations.createSocialPost,
                variables: {
                    text: prompt("Type in the post text"),
                    assetPath: prompt("Type in assetPath in private/ folder"),
                    isAdult: prompt("is it Adult? (yes/no)") === "yes"
                }
            }, {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        });
    }

    handleLikePost = async () => {
        API.graphql({
            query: mutations.likeSocialPost,
            variables: {
                postId: prompt("Post ID?")
            }
        }, {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        });
    }

    handleUnlikePost = async () => {
        API.graphql({
            query: mutations.unlikeSocialPost,
            variables: {
                likeId: prompt("Like ID?")
            }
        }, {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        });
    }

    handleListenToPosts = async () => {
        const subscription = API.graphql({
            query: subscriptions.listenToSocialPosts
        }, {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        }).subscribe({
            next: ({provider, value}) => console.log("Received:", {provider, value})
        });

        console.log("Subscription id", subscription);
    }

    componentDidMount() {
        console.log('on component mount');
        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
            console.log(user);
            this.setState({authState: 'signedIn'});
        }).catch(e => {
            console.log(e);
            this.setState({authState: 'signIn'});
        });
    }

    signOut() {
        Auth.signOut().then(() => {
            this.setState({authState: 'signIn'});
        }).catch(e => {
            console.log(e);
        });
    }

    handleEditPackage = () => {
        let apiName = 'BffUiMain';
        let path = "/packages/" + prompt("PackageID?");
        let myInit = {
            headers: {},
            body: {}
        }

        const body = {};

        do {
            const key = prompt("Property?");
            let value = prompt("Value?");

            if (value === "666") {
                value = [];
            } else if (value.startsWith("666")) {
                value = value.slice(3).split(",");
            }

            body[key] = value;
        } while (prompt("another property ?") === "y");

        myInit.body = body

        return API.put(apiName, path, myInit).then(response => {
            console.info(response)
        }).catch(error => {
            console.log(error)
        });
    };

    handleOnboard() {
        let apiName = 'BffUiMain';
        let path = '/api/onboard';
        let myInit = {
        }

        Auth.currentSession().then(currentSession => {
            myInit.body = {
                "IdentityJWT" : "Bearer " + currentSession.getIdToken().getJwtToken()
            }
            return Promise.resolve();
        }).then(() => {
            return API.post(apiName, path, myInit).then(response => {
                console.info(response);
            }).catch(error => {
                console.log(error)
            })
        });
    }

    handleRefreshToken() {
        return Auth.currentAuthenticatedUser({
            bypassCache: true
        });
    }



    render() {
        const {authState} = this.state;
        return (
            <div className="App">
                <header className="App-header">
                    <div>
                        <ChangeCognitoAttributeForm/>
                        <button onClick={this.handleClickVerifyEmail}>Verify Email</button>
                        <button onClick={this.handleClickCurrentSession}>Current User</button>
                        <button onClick={() => this.handleClickGetUserPackages("")}>GET User Packages API</button>
                        <button onClick={this.handleClickMostSold}>GET Most Sold Packages API</button>
                        <button onClick={() => this.handleClickGetUserPackages("/new")}>GET New Packages API</button>
                        <button onClick={this.handleClickCommentPackage}>POST Comment Package API</button>
                        <button onClick={() => this.handleClickPostPackage(this.state.packageBody)}>POST Package API</button>
                        <button onClick={this.handleClickDeletePackage}>DELETE Package API</button>
                        <button onClick={this.handleClickGetUser}>GET User API</button>
                        <button onClick={this.handleClickGetUserNickname}>GET User API with Nickname</button>
                        <button onClick={this.handleClickGetUserFollowers}>GET User Followers API</button>
                        <button onClick={this.handleClickGetUserFollowing}>GET User Following API</button>
                        <button onClick={this.handleClickPutUserFollow}>PUT User Follow API</button>
                        <button onClick={this.handleClickDeleteUserFollow}>DELETE User Follow API</button>
                        <button onClick={this.handleClickGetOtherUser}>GET Other User API</button>
                        <button onClick={this.handleClickPutUser}>PUT User API</button>
                        <button onClick={this.handleClickGetAllPurchases}>GET All Purchases</button>
                        <button onClick={this.handleClickGetPurchase}>GET Purchase</button>
                        <button onClick={this.handleClickGetSales}>GET All Sales</button>
                        <button onClick={this.handleClickPostPurchase}>POST Purchase</button>
                        <button onClick={this.handleClickAdminListUsersInGroup}>Admin List Users in Group</button>
                        <button onClick={this.handleClickObserveInbox}>Observe Inbox</button>
                        <button onClick={this.handleClickSendMessage}>Send Message</button>
                        <button onClick={this.handleClickListInbox}>List Inbox</button>
                        <button onClick={this.handleClickListConversation}>List Conversation</button>
                        <button onClick={this.handleClickRemoveNewestFromInbox}>Remove Newest Message from Inbox</button>
                        <button onClick={this.handleClickChangeMessageState}>Change State of Newest Message in a Conversation</button>
                        <button onClick={this.handleEditPackage}>Edit package</button>
                        <button onClick={this.handleOnboard}>Onboard User</button>
                        <button onClick={this.handleRefreshToken}>Refresh token</button>
                        <button onClick={this.handleCreatePost}>Create Post</button>
                        <button onClick={this.handleListenToPosts}>Listen to Posts</button>
                        <button onClick={this.handleLikePost}>Like Post</button>
                        <button onClick={this.handleUnlikePost}>Unlike Post</button>
                        <RunCognitoAttributeVerificationButton/>
                    </div>
                    <div>
                        <label>
                            Package Body:
                            <textarea onChange={this.handleChangePackageBody}/>
                        </label>
                    </div>
                </header>
                {authState === 'loading' && (<div>loading...</div>)}
                {authState === 'signedIn' && <button onClick={this.signOut}>Sign out</button>}
            </div>
        );
    }
}

export default Debug;
