import React from "react";

import PURCHASE_STATE from "../../constants/purchase-status";

class SaleRow extends React.Component {
    render() {
        return <tr>
            <td className="w20">&nbsp;</td>
            <td>
                {this.props.position}
            </td>
            <td>
                {this.props.packageName}
            </td>
            <td>
                {this.props.status === PURCHASE_STATE.JOSE ? this.props.total : 'Pending...'}
            </td>
            <td>
                {this.props.currency}
            </td>
            <td>
                {this.props.date}
            </td>
            <td className="w20">&nbsp;</td>
        </tr>
    }
}

export default SaleRow;
