import React from "react";

class InputModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputData: '',
        };

        this.closeClick = this.closeClick.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.handleDataChange = this.handleDataChange.bind(this);
    }

    closeClick() {
        this.setState({inputData: ''});
        this.props.handleClose();
    }

    saveClick() {
        this.props.handleSave(this.state.inputData);
        this.setState({inputData: ''});
    }

    canSave() {
        return this.state.inputData !== '';
    }

    handleDataChange(event) {
        this.setState({ inputData: event.target.value });
    }

    renderModal() {
        return <div className={this.props.isActive ? "modalHolder fadeIn" : "modalHolder fadeOut"}>
            <div onClick={() => this.closeClick()} className="popupBG"></div>
            <div className={this.props.isActive ? "modal modal--sample active fadeIn" : "modal modal--sample fadeOut"}>
                <button onClick={this.closeClick} className="modal__close">
                    <svg viewBox="0 0 100 100" fill="#5d80a2">
                        <use xlinkHref="#closeIcon">
                        </use>
                    </svg>
                </button>
                <div className="modal__title">{this.props.title}</div>
                <div className="modal__content modal__content--white">
                    <p>
                        <textarea onChange={this.handleDataChange} id="inputData" value={this.state.inputData} name="inputData" className="form-control" placeholder={this.props.placeholder} rows="6"/>
                    </p>
                </div>
                <div className="input-modal-controls">
                    <button disabled={!this.canSave()} onClick={this.saveClick} className="btn--blueGr">{this.props.saveLabel}</button>
                </div>
            </div>
        </div>;
    }

    render() {
        return this.renderModal();
    }
}

export default InputModal;
