import EnvironmentService from "./environment";

let MetaDataService = {
    async credentialsForPackageAssets(packageOwnerUserId, packageId, cognitoUsername, jwtToken) {
        return await fetch(`${EnvironmentService.baseURL()}/credentials/static/secured/${packageOwnerUserId}/packages/${packageId}/${cognitoUsername}.json`, {
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                credentials: 'include',
                mode: 'cors',
            },
        });
    }
};

export default MetaDataService;
