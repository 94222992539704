import React from 'react';

import UserService from "../../services/user";
import PackageService from "../../services/package";
import PostService from "../../services/post";
import PreferenceService from "../../services/preference";

import AvatarImage from "../../components/avatar-image";
import CoverImage from "../../components/cover-image";
import LoadingIndicator from "../../components/loading-indicator";
import Navigation from '../../components/navigation';
import Footer from '../../components/footer';
import ResponsiveNavigation from "../../components/responsive-navigation";
import HelmetComponent from "../../components/helmet";
import PackageList from "../../components/package-list";
import ContentConstants from "../../constants/content";
import PostList from "../../components/post-list";

class UserPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userData: '',
            userPackages: [],
            packagePaging: '',
            userPosts: [],
            postsPaging: '',
            isLoadingFollowing: false,
            isFollowing: false,
            isLoadingMore: false,
            isLoading: true,
            selectedContent: ContentConstants.POSTS
        };

        this.fetchPackages = this.fetchPackages.bind(this);
        this.fetchPosts = this.fetchPosts.bind(this);
        this.handleUserPackageFetchSuccess = this.handleUserPackageFetchSuccess.bind(this);
        this.follow = this.follow.bind(this);
        this.unfollow = this.unfollow.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.attemptLoadMore = this.attemptLoadMore.bind(this);
        this.handleContentSwitch = this.handleContentSwitch.bind(this);
    }

    componentDidMount() {
        PreferenceService.subscribe('explicitFilterStateChange', () => this.fetchPackages(this.state.userData));

        this.fetchUser();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.fetchUser();
        }
    }

    componentWillUnmount() {
        PreferenceService.unsubscribe('explicitFilterStateChange', () => this.fetchPackages(this.state.userData));
    }

    getUserId() {
        return this.state.userData._id ? this.state.userData._id : null;
    }

    getAvatarKey() {
        return this.getUserId() ? this.getUserId() : Math.random();
    }

    getUserPublicName() {
        return (this.state.userData && this.state.userData.publicName) ? this.state.userData.publicName : '';
    }

    fetchUser(fetchUserContent = true) {
        const visitedUser = this.retrievePublicName();

        if (visitedUser) {
            UserService.fetch(visitedUser).then(response => this.handleUserFetchSuccess(response.data, fetchUserContent)).catch(error => this.props.navigation.featured());
        } else {
            UserService.fetchCurrent().then(response => this.handleUserFetchSuccess(response.data, fetchUserContent)).catch(error => console.log(error));
        }
    }

    loadMore() {
        this.setState({isLoadingMore: true});
        this.state.selectedContent === ContentConstants.PACKAGES ? this.fetchPackages(this.state.userData, this.state.packagePaging ? this.state.packagePaging.nextKey : null) : this.fetchPosts(this.state.userData, this.state.postsPaging ? this.state.postsPaging.nextKey : null);
    }

    attemptLoadMore() {
        if (this.canLoadMore()) {
            this.loadMore();
        }
    }

    isLoggedIn() {
        return !!this.props.currentUser;
    }

    fetchIsFollowing() {
        UserService.isFollowing(this.getUserId()).then(response => this.handleIsFollowingSuccess(response)).catch(error => this.setState({isFollowing: false}));
    }

    handleIsFollowingSuccess(response) {
        if (!response || !response.data || !response.data.following || response.data.following.length === 0) {
            this.setState({isFollowing: false});

        } else {
            this.setState({isFollowing: true});
        }
    }

    retrievePublicName() {
        let user = this.props.location.pathname.split("/");
        return (user && user[2] && user[2] !== 'page') ? user[2] : null;
    }

    fetchPackages(userData, nextKey) {
        let newUserData = userData ? userData : this.state.userData;
        PackageService.fetchPackagesForUser(newUserData._id, nextKey, 25).then(response => this.handleUserPackageFetchSuccess(response.data.packages, response.data.paging)).catch(error => this.handleUserPackageFetchFailure(error));
    }

    fetchPosts(userData, nextKey) {
        let newUserData = userData ? userData : this.state.userData;
        PostService.fetchPostsForUserName(newUserData.publicName, nextKey, 25).then(response => this.handleUserPostsFetchSuccess(response.data.posts, response.data.paging)).catch(error => this.handleUserPostsFetchFailure(error));
    }

    follow(event) {
        event.preventDefault();

        if (this.isAuthenticated()) {
            this.setState({isLoadingFollowing: true});

            UserService.follow(this.state.userData._id).then(() => {
                this.fetchUser(false);
            });
        } else {
            this.props.navigation.signIn();
        }
    }

    unfollow(event) {
        event.preventDefault();

        this.setState({isLoadingFollowing: true});
        UserService.unfollow(this.state.userData._id).then(() => {
            this.fetchUser(false);
        });
    }

    handleUserFetchSuccess(userData, fetchUserContent) {
        this.setState({ userData: userData, isLoadingFollowing: false });

        if (fetchUserContent) {
            this.fetchPackages(userData, null);
            this.fetchPosts(userData, null);
        }

        if (this.isLoggedIn()) {
            this.fetchIsFollowing(userData._id);
        }
    }

    handleUserPackageFetchSuccess(userPackages, paging) {
        this.setState((prevState) => ({ userPackages: prevState.userPackages.concat(userPackages), packagePaging: paging, isLoading: false, isLoadingMore: false }));
    }

    handleUserPostsFetchSuccess(userPosts, paging) {
        this.setState((prevState) => ({ userPosts: prevState.userPosts.concat(userPosts), postsPaging: paging, isLoading: false, isLoadingMore: false }));
    }

    handleUserPackageFetchFailure(error) {
        if (error) {
            this.setState({userPackages: [], packagePaging: '', isLoading: false, isLoadingMore: false});
        }
    }

    handleUserPostsFetchFailure(error) {
        if (error) {
            this.setState({userPosts: [], postsPaging: '', isLoading: false, isLoadingMore: false});
        }
    }

    userHasPackages() {
        return Array.isArray(this.state.userPackages) && this.state.userPackages.length > 0;
    }

    userHasPosts() {
        return Array.isArray(this.state.userPosts) && this.state.userPosts.length > 0;
    }

    isLoading() {
        return this.state.isLoading;
    }

    canLoadMore() {
        return this.state.selectedContent === ContentConstants.PACKAGES ? !!this.state.packagePaging.nextKey : !!this.state.postsPaging.nextKey;
    }

    isAuthenticated() {
        return !!this.props.currentUser;
    }

    isVisitedUserSelf() {
        return this.props.currentUser && this.state.userData && (this.state.userData._id === this.props.currentUser.userId);
    }

    shouldDisplayFollowButton() {
        return !this.state.isFollowing && !this.isVisitedUserSelf();
    }

    shouldDisplayUnfollowButton() {
        return this.state.isFollowing && !this.isVisitedUserSelf();
    }

    handleContentSwitch(selectedContentEvent) {
        const selectedContent = selectedContentEvent.target.id === 'posts' ? ContentConstants.POSTS : ContentConstants.PACKAGES;
        this.setState({selectedContent});
    }

    renderNavigation() {
        return <Navigation isAuthenticated={this.isAuthenticated()} currentUser={this.props.currentUser} navigation={this.props.navigation}/>
    }

    renderFooter() {
        return <Footer />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={this.isAuthenticated()} currentUser={this.props.currentUser} navigation={this.props.navigation}/>
    }

    renderFollowButton(idiom) {
        return <div className={idiom === 'mobile' ? "follow-button-mobile" : "follow-button-desktop"}>
                    <a href={`/follow`} onClick={this.follow}>Follow</a>
        </div>;
    }

    renderUnfollowButton(idiom) {
        return <div className={idiom === 'mobile' ? "follow-button-mobile" : "follow-button-desktop"}>
                    <a href={`/unfollow`} onClick={this.unfollow}>Unfollow</a>
        </div>;
    }

    renderFollowLoading(idiom) {
        return <div className={idiom === 'mobile' ? "follow-button-mobile" : "follow-button-desktop"}>
                    <LoadingIndicator positionClassName={idiom === 'mobile' ? 'follow-loading-indicator-position-mobile': 'follow-loading-indicator-position' } contentClassName='follow-loading-indicator' customPosition={true}/>
        </div>
    }

    renderPosts() {
        return <div className={!this.userHasPosts() ? "row block-display" : "row"}>
            <PostList posts={this.state.userPosts} navigation={this.props.navigation} loadMore={this.attemptLoadMore} />
        </div>;
    }

    renderPackages() {
        return <div className={!this.userHasPackages() ? "row block-display" : "row"}>
            <PackageList packages={this.state.userPackages} navigation={this.props.navigation} loadMore={this.attemptLoadMore} />
        </div>;
    }

    renderContent() {
        return <section key={this.getUserId()} className="featured my">
            <CoverImage identityId={this.getUserId()}>
                { this.state.isLoadingFollowing ? this.renderFollowLoading('mobile') : '' }
                { !this.state.isLoadingFollowing && this.shouldDisplayFollowButton() && this.renderFollowButton('mobile') }
                { !this.state.isLoadingFollowing && this.shouldDisplayUnfollowButton() && this.renderUnfollowButton('mobile') }
            </CoverImage>

            <div className="container bio">
                <div className="row">
                    <div className="col-md-auto user-header-col-padding">
                        <div className="user-header">
                            <AvatarImage identityId={this.getUserId()} />
                            <div className='td-center'>@{this.state.userData.publicName}</div>
                            <div className='td-center name-min-height'>{this.state.userData.name}</div>
                        </div>
                        <div className="mobile-user-header">
                            <AvatarImage identityId={this.getUserId()} extraClassNames="user-img" />
                            <div className="user-header-name">
                                <div className="mobile-user-header-name">@{this.state.userData.publicName}</div>
                                <div className="mobile-user-header-name">{this.state.userData.name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 user-bio-padding desktop-hide">
                        <span>{this.state.userData.bio === '' ? "This user prefers to maintain an air of mystery..." : this.state.userData.bio}</span>
                    </div>
                </div>
                <div className='row desktop-user-control-panel'>
                    { this.state.isLoadingFollowing ? this.renderFollowLoading('desktop') : '' }
                    { !this.state.isLoadingFollowing && this.shouldDisplayFollowButton() && this.renderFollowButton('desktop') }
                    { !this.state.isLoadingFollowing && this.shouldDisplayUnfollowButton() && this.renderUnfollowButton('desktop') }
                </div>
            </div>
            <div className="container mobile-hide">
                <div className='row desktop-user-description'>
                    <span>{this.state.userData.bio === '' ? "This user prefers to maintain an air of mystery..." : this.state.userData.bio}</span>
                </div>
            </div>
            <div className='user-page-content-selector'>
                <h3 onClick={this.handleContentSwitch} id='posts' className={this.state.selectedContent === ContentConstants.POSTS ? 'user-page-content-selector-posts' : 'user-page-content-selector-posts user-page-content-selector-unselected'}>Posts</h3>
                <h3>/</h3>
                <h3 onClick={this.handleContentSwitch} id='packages' className={this.state.selectedContent === ContentConstants.PACKAGES ? 'user-page-content-selector-packages' : 'user-page-content-selector-packages user-page-content-selector-unselected'}>Packages</h3>
            </div>
            <div className={this.state.selectedContent === ContentConstants.POSTS ? 'container posts' : 'container'} id="container">
                {this.state.selectedContent === ContentConstants.POSTS ? this.renderPosts() : this.renderPackages()}
            </div>
        </section>;
    }

    renderLoading() {
        return <LoadingIndicator/>;
    }

    renderUserPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            {this.isLoading() ? this.renderLoading() : this.renderContent()}

            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            <HelmetComponent title={`Creator - ${this.getUserPublicName()}`} description={`OpenSwap.io creator page for ${this.getUserPublicName()}.`} />
        </div>

    }

    render() {
        return this.renderUserPage();
    }
}

export default UserPage;
