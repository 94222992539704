import React from 'react';

import EnvironmentService from "../../services/environment";
import S3Image from "../s3-image";

const defaultCoverImage = require('../../images/landscape.jpg');
const coverKey = "cover.jpg";

class CoverImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imgSrc: '',
        };

        this.handleImageError = this.handleImageError.bind(this);
        this.handleImageLoad = this.handleImageLoad.bind(this);
    }

    componentDidMount() {
        this.setState({imgSrc: this.getImageSource()});
    }

    getImageSource() {
        return `${EnvironmentService.staticURL()}/protected/${this.props.identityId}/${coverKey}`;
    }

    handleImageError(event) {
        this.setState({imgSrc: defaultCoverImage});
    }

    handleImageLoad(imageSrc) {
        this.setState({imgSrc: imageSrc});
    }

    renderCDNImage() {
        return <img alt='' src={this.state.imgSrc} style={{"display": "none"}} onError={this.handleImageError} />;
    }

    renderS3Image() {
        return <S3Image handleImageLoad={this.handleImageLoad} s3key={coverKey} className={`${this.props.extraClassNames} hide`} defaultImage={defaultCoverImage} identityId={this.props.identityId}  />
    }

    renderCover() {
        /* Dummy img tag needed to validate if the image url is valid or not */
        return <div className="header container" style={{"backgroundImage": "url(" + this.state.imgSrc + ")"}}>
            {this.props.loadFromStorage ? this.renderS3Image() : this.renderCDNImage()}
            {this.props.children}
        </div>
    }

    render() {
        return this.renderCover();
    }
}

export default CoverImage;
