import React from "react";

class DisplayModal extends React.Component {
    constructor(props) {
        super(props);

        this.closeClick = this.closeClick.bind(this);
    }

    closeClick() {
        this.props.handleClose();
    }

    renderModal() {
        return <div className={this.props.isActive ? "modalHolder fadeIn" : "modalHolder fadeOut"}>
            <div onClick={() => this.closeClick()} className="popupBG"></div>
            <div className={this.props.isActive ? "modal modal--sample active fadeIn" : "modal modal--sample fadeOut"}>
                <button onClick={this.closeClick} className="modal__close">
                    <svg viewBox="0 0 100 100" fill="#5d80a2">
                        <use xlinkHref="#closeIcon">
                        </use>
                    </svg>
                </button>
                <div className="modal__title">{this.props.title}</div>
                <div className="modal__content modal__content--white">
                    <p>
                        {this.props.message}
                    </p>
                </div>
            </div>
        </div>;
    }

    render() {
        return this.renderModal();
    }
}

export default DisplayModal;
