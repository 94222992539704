import React from 'react';
import ScrollTracker from "../scroll-tracker";
import PackageCard from "../package-card";
import PreferenceService from "../../services/preference";
import PriceResolver from "../../services/price-resolver";
import EnvironmentService from "../../services/environment";

class PackageList extends React.Component {

    constructor(props) {
        super(props);

        this.scrollTracker = new ScrollTracker('container', this.handleReachedBottom, this);

        this.handleReachedBottom = this.handleReachedBottom.bind(this);
        this.handleClone = this.handleClone.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.scrollTracker.startTrackingScrolling();
    }

    componentWillUnmount() {
        this.scrollTracker.stopTrackingScrolling();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.packages.length && (prevProps.packages.length !== this.props.packages.length)) {
            this.scrollTracker.startTrackingScrolling();
        }
    }

    handleReachedBottom() {
        this.props.loadMore();
    }

    hasPackages() {
        return (this.props.packages && this.props.packages.length > 0);
    }

    isEditing() {
        return !!this.props.isEditing;
    }

    isDeleting(packageId) {
        return this.props.deleting && this.props.deleting.includes(packageId);
    }

    handleDelete(packageId) {
        this.props.onDelete(packageId);
    }

    handleEdit(packageId) {
        this.props.onEdit(packageId);
    }

    handleClone(packageId) {
        this.props.onClone(packageId);
    }

    renderPackageImage(s3Key) {
        return `${EnvironmentService.staticURL()}/${s3Key}`;
    }

    renderPackages() {
        return this.props.packages.map((aPackage) => {
            return (<div key={aPackage._id} className="col-md-3">
                <PackageCard key={aPackage._id} isEditing={this.isEditing()} packageOwner={aPackage.owner_id} navigation={this.props.navigation} packageAlias={aPackage.alias} isDeleting={this.isDeleting(aPackage._id)} onDelete={this.handleDelete} onClone={this.handleClone} onEdit={this.handleEdit} packageId={aPackage._id}  packageImage={this.renderPackageImage(aPackage.previews[0].thumb_uri)} shouldBlur={PreferenceService.getExplicitContentFilter() === 'obscure' && aPackage.is_adult} username={aPackage.owner_public_name} packageName={aPackage.name} price={PriceResolver.resolvePriceForPackage(aPackage)} date={aPackage.date_created} quantity={aPackage.content_qty} packageTag={aPackage.tags[Math.floor(Math.random() * aPackage.tags.length)]}/>
            </div>);
        })
    }

    renderNoPackageLabel() {
        return <div className='col-md-3'><h4>No packages found...</h4></div>;
    }

    render() {
        return this.hasPackages() ? this.renderPackages() : this.renderNoPackageLabel();
    }
}

export default PackageList;