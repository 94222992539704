import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // required by loader
import {Carousel} from 'react-responsive-carousel';

import PackageService from "../../services/package";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveMenu from "../../components/responsive-navigation";
import LoadingIndicator from "../../components/loading-indicator";
import InputModal from "../../components/modal/input";
import CommentItem from "./commentItem";
import PurchaseService from "../../services/purchase";
import EnvironmentService from "../../services/environment";

import PACKAGE_LICENSE from "../../constants/package-licenses";
import HelmetComponent from "../../components/helmet";
import PriceResolver from "../../services/price-resolver";
import PackageList from "../../components/package-list";

class PackagePreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isInitiatingPurchase: false,
            isAddingComment: false,
            description: '',
            tags: [],
            previews: [],
            comments: [],
            name: '',
            price: '',
            packageId: '',
            packageOwnerName: '',
            packageOwnerId: '',
            morePackages: [],
            morePackagesPaging: null,
            license: PACKAGE_LICENSE.PERSONAL_USE,
            packagePayload: null,
        };

        this.showInputModal = this.showInputModal.bind(this);
        this.hideInputModal = this.hideInputModal.bind(this);
        this.initiatePackagePurchase = this.initiatePackagePurchase.bind(this);
        this.saveNewComment = this.saveNewComment.bind(this);
        this.goToUser = this.goToUser.bind(this);
        this.goToTag = this.goToTag.bind(this);
        this.canLoadMore = this.canLoadMore.bind(this);
        this.attemptLoadMore = this.attemptLoadMore.bind(this);
    }

    componentDidMount() {
        this.fetchPackage();
    }

    getTags() {
        return this.state.tags.length > 0 ? this.state.tags : [];
    }

    getComments() {
        return this.state.comments.length > 0 ? this.state.comments : [];
    }

    isLoggedIn() {
        return !!this.props.currentUser;
    }

    fetchPackage() {
        this.retrievePackageId().then(packageId => {
            PackageService.fetchPackage(packageId).then(response => this.handleSuccessfulPackageFetch(response.data.packages[0])).catch(error => this.props.navigation.featured());
        });
    }

    fetchMorePackages(packageOwnerId, nextKey) {
        PackageService.fetchPackagesForUser(packageOwnerId, nextKey, 25).then(response => this.handleSuccessfulMorePackagesFetch(response.data.packages, response.data.paging)).catch(error => console.log(error));
    }

    loadMore() {
        this.fetchMorePackages(this.state.packageOwnerId, this.state.morePackagesPaging ? this.state.morePackagesPaging.nextKey : null);
    }

    attemptLoadMore() {
        if (this.canLoadMore()) {
            this.loadMore();
        }
    }

    async retrievePackageId() {
        const packageAlias = this.retrievePackageAlias();

        return await PackageService.fetchIDFromAlias(packageAlias);
    }

    retrievePackageAlias() {
        let packagePayload = this.props.location.pathname.split("/");
        return (packagePayload && packagePayload[2]) ? packagePayload[2] : null;
    }

    handleSuccessfulPackageFetch(packagePayload) {
        this.setState({
            isLoading: false,
            description: packagePayload.description,
            tags: packagePayload.tags,
            previews: packagePayload.previews,
            comments: packagePayload.comments,
            name: packagePayload.name,
            price: packagePayload.price,
            quantity: packagePayload.content_qty,
            packageId: packagePayload._id,
            packageOwnerName: packagePayload.owner_public_name,
            packageOwnerId: packagePayload.owner_id,
            license: packagePayload.license,
            isAdult: packagePayload.is_adult,
            packagePayload: packagePayload,
        });

        this.fetchMorePackages(packagePayload.owner_id);

        window.scrollTo(0, 0);
    }

    handleSuccessfulMorePackagesFetch(packages, paging) {
        this.setState((prevState) => ({morePackages: prevState.morePackages.concat(packages), morePackagesPaging: paging}));
    }

    async saveNewComment(comment) {
        PackageService.saveComment(await this.retrievePackageId(), comment).then(response => this.handleSuccessfulCommentSave()).catch(error => console.log(error));
    }

    handleSuccessfulCommentSave() {
        this.fetchPackage();
        this.hideInputModal();
    }

    initiatePackagePurchase() {
        if (this.isLoggedIn()) {
            this.setState({isInitiatingPurchase: true}, () => {
                PurchaseService.createPurchase(this.state.packageId, this.props.currentUser.username).then(response => this.handleSuccessfulPurchaseInitiation(response.data)).catch(error => this.handleFailedPurchaseInitiation());
            });
        } else {
            this.props.navigation.signIn();
        }
    }

    goToUser(event) {
        event.preventDefault();
        this.props.navigation.userPage(this.state.packageOwnerName);
    }

    goToTag(event) {
        event.preventDefault();
        this.props.navigation.packagesForTag(event.target.name);
    }

    showInputModal() {
        this.setState({ isAddingComment: true });
    }

    hideInputModal() {
        this.setState({ isAddingComment: false });
    }

    handleSuccessfulPurchaseInitiation(packagePayload) {
        this.setState({isInitiatingPurchase: false}, () => {
            this.props.navigation.purchaseForPackageId(packagePayload._id);
        });
    }

    handleFailedPurchaseInitiation() {
        this.setState({isInitiatingPurchase: false});
    }

    canLoadMore() {
        return !!this.state.morePackagesPaging.nextKey;
    }

    renderPackageLicense() {
        switch(this.state.license) {
            case PACKAGE_LICENSE.PERSONAL_USE:
                return 'This Package is licensed for Personal Use only'
            case PACKAGE_LICENSE.ATTRIBUTION:
                return 'This Package is licensed for any kind of use as long as the original author receives attribution'
            case PACKAGE_LICENSE.ATTRIBUTION_NONCOMMERCIAL:
                return 'This Package is licensed for non commercial use and the original author must receive attribution'
            default:
                return 'This Package is licensed for Personal Use only'
        }
    }

    renderNavigation() {
        return <Navigation isAuthenticated={this.isLoggedIn()} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer/>
    }

    renderRenderResponsiveMenu() {
        return <ResponsiveMenu isAuthenticated={this.isLoggedIn()} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderLoadingIndicator() {
        return <LoadingIndicator />
    }

    renderInputModal() {
        return <InputModal
            isActive={this.state.isAddingComment}
            handleSave={this.saveNewComment}
            handleClose={this.hideInputModal}
            title="Add Your Comment"
            placeholder="Your Comment"
            saveLabel="Save Comment" />;
    }

    renderPurchaseButton() {
        if (this.props.currentUser && (this.props.currentUser.userId === this.state.packageOwnerId)) {
            return;
        }

        return <button onClick={this.initiatePackagePurchase} id="SignupButton" className={this.state.isInitiatingPurchase ? "purchase-button btn--gray button-loading-border" : "purchase-button btn--blueGr"}>
            {this.isLoggedIn() ? `Purchase for ${PriceResolver.resolvePriceForPackage(this.state.packagePayload)} BTC` : `Sign In to Purchase`}
        </button>
    }

    renderPackageImage(s3Key) {
        return `${EnvironmentService.staticURL()}/${s3Key}`;
    }

    renderPackageImageForOpenGraphObject() {
        return this.state.previews[0] ? this.renderPackageImage(this.state.previews[0]['uri']) : '';
    }

    renderPackageURLForOpenGraphObject() {
        return `${EnvironmentService.baseURL()}${this.props.location.pathname}`;
    }

    renderPreviews() {
        const oneTagForEachPreviewUri = this.oneTagForEach(this.state.previews.map(preview => preview.uri));
        return this.state.previews.map(preview => this.renderPreview(preview, oneTagForEachPreviewUri[preview.uri]));
    }

    oneTagForEach(keys) {
        const map = {};
        const tags = this.getTags();
        const nextTag = previousIndex => {
            if (tags.length === 0) {
                return [-1, 'Openswap tag'];
            }
            if (previousIndex < 0) {
                return [0, tags[0]];
            }
            if (tags.length > previousIndex + 1) {
                return [previousIndex + 1, tags[previousIndex + 1]];
            }
            return [previousIndex, tags[previousIndex]];
        };
        let currentIndex = -1;
        keys.forEach(key => {
            const iterationResult = nextTag(currentIndex);
            currentIndex = iterationResult[0];
            map[key] = iterationResult[1];
        });
        return map;
    }

    renderPreview(preview, tag) {
        return <div key={preview.uri}>
            <img alt={tag || 'OpenSwap Image'} src={this.renderPackageImage(preview.uri)} />
        </div>
    }

    userHasPackages() {
        return Array.isArray(this.state.morePackages) && this.state.morePackages.length;
    }

    renderMorePackages() {
        return <>
            <h3>More Packages from <span className='pointer' onClick={this.goToUser}>{this.state.packageOwnerName}</span></h3>
            <div id="container" className="container bio featured">
                <div className={!this.userHasPackages() ? "row block-display" : "row"}>
                    <PackageList packages={this.state.morePackages} navigation={this.props.navigation} loadMore={this.attemptLoadMore} />
                </div>
            </div>
        </>
    }

    renderPackagePreview() {
        return <section className="preview">
            <h3>{this.state.name}</h3>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="owl-carousel owl-theme">
                            <Carousel dynamicHeight={true} showStatus={false}>
                                {this.renderPreviews()}
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="purchased-package">
                            <p>{this.state.description}</p>
                            <p>{this.state.quantity} {this.state.quantity > 1 ? 'items' : 'item'} in purchased Package</p>
                        </div>
                        <div className='purchased-package package-preview-license'>
                            {this.renderPackageLicense()}
                        </div>
                        <div className="purchased-package package-tag">
                            {this.getTags().map(tag => <a key={tag} onClick={this.goToTag} name={tag} href={`/packages/tag/${tag}`}>{tag}</a>)}
                        </div>
                        <div className="comments">
                            <span>{this.getComments().length} {this.getComments().length === 1 ? 'Comment' : 'Comments'}</span>
                            {this.isLoggedIn() && <span className="add-new-comment" onClick={this.showInputModal}>(Add New Comment)</span>}
                            {this.getComments().map(comment => <CommentItem key={comment.date} commenterId={comment.commenter_id} userName={comment.publicName} navigation={this.props.navigation} comment={comment.comment} />)}
                        </div>
                        <div className="text-center">
                            {this.renderPurchaseButton()}
                        </div>
                    </div>
                </div>
            </div>
            {this.renderMorePackages()}
        </section>;
    }

    renderSEODescription() {
        return `${this.state.description.replace(/,/g, "").split(' ').slice(0, 20).join(' ')}, Author: ${this.state.packageOwnerName}, Quantity: ${this.state.quantity} items, Price: ${PriceResolver.resolvePriceForPackage(this.state.packagePayload)} BTC`;
    }

    renderPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            {this.state.isLoading ? this.renderLoadingIndicator() : this.renderPackagePreview()}

            {this.renderFooter()}
            {this.renderRenderResponsiveMenu()}
            {this.renderInputModal()}
            <HelmetComponent title={`Packages - ${this.state.name}`} description={this.renderSEODescription()} keywords={this.getTags()} isAdult={this.state.isAdult} url={this.renderPackageURLForOpenGraphObject()} image={this.renderPackageImageForOpenGraphObject()} />
        </div>
    }

    render() {
        return this.renderPage();
    }
}

export default PackagePreview;
