import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux';
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route } from 'react-router-dom';
import { Hub } from 'aws-amplify';

import { store } from './store';

import './index.css';
import './additions.css';

import SessionService from './services/session';
import NavigationService from './services/navigation';

import FOLLOW_CONTEXT from "./constants/follow-context";

import Debug from './routes/debug';
import SignUp from './routes/signup';
import SignIn from './routes/signin';
import FAQ from './routes/faq';
import Landing from './routes/landing';
import UserPage from './routes/user-page';
import Featured from './routes/featured-packages';
import Settings from './routes/settings';
import Sales from './routes/sales';
import Purchases from './routes/purchases';
import Upload from './routes/upload';
import Following from './routes/follow';
import LoadingIndicator from "./components/loading-indicator";
import PrivacyPolicy from "./routes/privacy";
import TOS from "./routes/tos";
import PackagePreview from "./routes/package-preview";
import Purchase from "./routes/purchase";
import ManageContent from "./routes/manage-content";
import PurchasedPackage from "./routes/purchased-package";
import PackageRoute from "./routes/package-route";
import PackageList from "./routes/package-list";
import Copyright from "./routes/copyright";
import FeaturedPackages from "./routes/featured-packages";
import FeaturedPosts from "./routes/featured-posts";
// import * as serviceWorker from './serviceWorker';

const AUTH_STATE = {
    PENDING: 'pending',
    AUTHENTICATED: 'authenticated',
    UNAUTHENTICATED: 'unauthenticated',
};

class Root extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticationStatus: AUTH_STATE.PENDING,
            currentUser: null,
        };

        try { // GAnalytics only for production
            if (window.location.hostname === 'www.openswap.io' &&
                // Our prerenderer uses an User-Agent that says 'OpenSwap'
                navigator.userAgent.indexOf('OpenSwap') < 0) {
                NavigationService.googleAnalytics();
            }
        } catch (e) {
            console.log(e);
        }

        this.persistAuthState = this.persistAuthState.bind(this);
    }

    componentDidMount() {
        SessionService.subscribe('authStateChange', this.persistAuthState);
        this.verifyAuthState();
    }

    persistAuthState(authState, currentUser, currentUserId) {
        this.setState({ currentUser: currentUser, authenticationStatus: authState });

        try {
            if (window.location.hostname === 'www.openswap.io' &&
                navigator.userAgent.indexOf('OpenSwap') < 0) {
                NavigationService.setUserInGoogleAnalytics(currentUser['userId']);
            }
        } catch (e) {
            console.log(e);
        }
    }

    verifyAuthState() {
       SessionService.getCurrentUser().then(user => {
           this.setState({currentUser: user, authenticationStatus: AUTH_STATE.AUTHENTICATED});

           try {
               if (window.location.hostname === 'www.openswap.io' &&
                   navigator.userAgent.indexOf('OpenSwap') < 0) {
                   NavigationService.setUserInGoogleAnalytics(user['userId']);
               }
           } catch (e) {
               console.log(e);
           }

       }).catch(error => this.setState( { currentUser: null, authenticationStatus: AUTH_STATE.UNAUTHENTICATED }));
    }

    renderOpenRoutes() {
        return <Switch>
            <Route path = "/debug111" component={Debug} />
            <Route path = "/signup" render={(props) => (<SignUp {...props} navigation={NavigationService} />)} />
            <Route path = "/signin" render={(props) => (<SignIn {...props} navigation={NavigationService} />)} />
            <Route path = "/tos" render={(props) => (<TOS {...props} navigation={NavigationService} />)} />
            <Route path = "/privacy" render={(props) => (<PrivacyPolicy {...props} navigation={NavigationService} />)} />
            <Route path = "/faq" render={(props) => (<FAQ {...props} navigation={NavigationService} />)} />
            <Route path = "/copyright" render={(props) => (<Copyright {...props} navigation={NavigationService} />)} />
            <Route path = "/packages/route/*" render={(props) => (<PackageRoute {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/packages/tag/*" render={(props) => (<PackageList {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/packages/*" render={(props) => (<PackagePreview {...props} navigation={NavigationService} />)} />
            <Route path = "/creator/*" render={(props) => (<UserPage {...props} navigation={NavigationService} />)} />
            <Route path = "/featured/packages" render={(props) => (<FeaturedPackages {...props} navigation={NavigationService} />)} />
            <Route path = "/featured/posts" render={(props) => (<FeaturedPosts {...props} navigation={NavigationService} />)} />
            <Route path = "/" render={(props) => (<FeaturedPackages {...props} navigation={NavigationService} />)} />
            <Route path = "/landing" render={(props) => (<Landing {...props} navigation={NavigationService} />)} />
            <Route component={Landing} />
        </Switch>
    }

    renderAuthenticatedRoutes() {
        return <Switch>
            <Route path = "/debug111" component={Debug} />
            <Route path = "/faq" render={(props) => (<FAQ {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/tos" render={(props) => (<TOS {...props} navigation={NavigationService} />)} />
            <Route path = "/copyright" render={(props) => (<Copyright {...props} navigation={NavigationService} />)} />
            <Route path = "/my/page"  render={(props) => (<UserPage {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/my/following"  render={(props) => (<Following {...props} navigation={NavigationService} currentUser={this.state.currentUser} context={FOLLOW_CONTEXT.FOLLOWING} />)} />
            <Route path = "/my/followers"  render={(props) => (<Following {...props} navigation={NavigationService} currentUser={this.state.currentUser} context={FOLLOW_CONTEXT.FOLLOWERS} />)} />
            <Route path = "/my/settings" render={(props) => (<Settings {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/my/sales" render={(props) => (<Sales {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/my/purchases" render={(props) => (<Purchases {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/my/upload" render={(props) => (<Upload {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/my/content" render={(props) => (<ManageContent {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/creator/*" render={(props) => (<UserPage {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/packages/purchased/*" render={(props) => (<PurchasedPackage navigation={NavigationService} {...props} currentUser={this.state.currentUser} />)} />
            <Route path = "/packages/route/*" render={(props) => (<PackageRoute {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/packages/tag/*" render={(props) => (<PackageList {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/packages/*" render={(props) => (<PackagePreview {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/purchases/*" render={(props) => (<Purchase {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/featured/posts" render={(props) => (<FeaturedPosts {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route path = "/" render={(props) => (<FeaturedPackages {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            <Route render={(props) => (<FeaturedPackages {...props} navigation={NavigationService} currentUser={this.state.currentUser} />)} />
            {/*<Route component={Router} />*/}
        </Switch>
    }

    renderRoutes() {
        return (<Provider store={store}>
            <ConnectedRouter history={NavigationService.exposeHistory()}>
                { (this.state.authenticationStatus === AUTH_STATE.AUTHENTICATED) ? this.renderAuthenticatedRoutes() : this.renderOpenRoutes()}
            </ConnectedRouter>
        </Provider>);
    }

    render() {
        return this.state.authenticationStatus === AUTH_STATE.PENDING ? <LoadingIndicator /> : this.renderRoutes();
    }
}

try {
    if (window.location.hostname === 'www.openswap.io' &&
        // Our prerenderer uses an User-Agent that says 'OpenSwap'
        navigator.userAgent.indexOf('OpenSwap') < 0) {
        Sentry.init({
            dsn: "https://d608fe22d3d644c6a7a0c0e0f98bb527@o952019.ingest.sentry.io/5901228",
            integrations: [new Integrations.BrowserTracing()],
            environment: window.location.host.startsWith('www') ? 'production' : window.location.host.indexOf('.') < 0 ? "localhost" : window.location.host.split(".").slice(1).pop(),
            release: process.env.REACT_APP_RELEASE_VERSION,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.9,
        });
    } else {
        console.log('Omitting Sentry.init')
    }
} catch (e) {
    console.log(e);
}

Hub.listen(/.*/, (data) => {
    try {
        if (data.payload.message.match(/error/i)) {
            Sentry.addBreadcrumb({
                category: "amplify",
                message: "Error in the Amplify Hub: " + data.payload.message,
                data: data.payload.data,
                level: "error",
            });
        }
    } catch (e) {
        console.error(e);
        Sentry.addBreadcrumb({
            category: "amplify",
            message: "Error while listening to the Amplify Hub",
            data: e,
            level: "error",
        });
    }
})

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
