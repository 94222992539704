import React from "react";

import PURCHASE_STATE from "../../constants/purchase-status";

class PurchaseRow extends React.Component {
    constructor(props) {
        super(props);

        this.goToPurchasedPackage = this.goToPurchasedPackage.bind(this);
        this.goToPendingPurchase = this.goToPendingPurchase.bind(this);
    }

    goToPurchasedPackage(event) {
        event.preventDefault();
        this.props.navigation.purchasedPackage(event.target.name);
    }

    goToPendingPurchase(event) {
        event.preventDefault();
        this.props.navigation.purchaseForPackageId(event.target.name);
    }

    renderPurchasedPackageLink() {
        return <a onClick={this.goToPurchasedPackage} name={this.props.packageId} href={`/packages/${this.props.packageId}`}>{this.props.packageName}</a>;
    }

    renderPendingPurchaseLink() {
        return <a onClick={this.goToPendingPurchase} name={this.props.purchaseId} href={`/purchases/${this.props.purchaseId}`}>{this.props.packageName}</a>;
    }

    renderPurchasedPackageDate() {
        return (this.props.purchaseStatus === PURCHASE_STATE.PENDING) ? <span>Pending...</span> : this.props.date;
    }

    renderLink() {
        return (this.props.purchaseStatus === PURCHASE_STATE.PENDING) ? this.renderPendingPurchaseLink() : this.renderPurchasedPackageLink();
    }

    render() {
        return <tr>
            <td className="w20">&nbsp;</td>
            <td>
                {this.props.position}
            </td>
            <td>
                {this.renderLink()}
            </td>
            <td>
                {this.props.price}
            </td>
            <td>
                {this.props.currency}
            </td>
            <td>
                {this.renderPurchasedPackageDate()}
            </td>
            <td className="w20">&nbsp;</td>
        </tr>
    }
}

export default PurchaseRow;
