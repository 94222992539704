import React from "react";
import AvatarImage from "../avatar-image";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    PinterestIcon,
    PinterestShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton
} from "react-share";
import LoadingIndicator from "../loading-indicator";
import DateService from '../../services/date';
import PriceResolver from "../../services/price-resolver";

const openSwapLogo = require('../../images/open-swap-ico.svg');

const shareIcon = require('../../images/fa-share.svg');
const deleteIcon = require('../../images/fa-trash-o.svg');
const likedIcon = require('../../images/fa-heart.svg');
const likeIcon = require('../../images/fa-heart-o.svg');
const replyIcon = require('../../images/reply.svg');
const lockIcon = require('../../images/lock-pale.svg');

class PostCard extends React.Component {
    /*
    props:
    - isEditing
    - isDeleting
    - id
    - username
    - image
    - price
    - isPaid
    - currency
    - owner
    - caption
    - likes
    - comments
    - date
    - onDelete
    - onClone
    */

    constructor(props) {
        super(props);

        this.dateService = new DateService();

        this.state = {
            isShowingShare: false,
        };

        this.showShareSheet = this.showShareSheet.bind(this);
        this.hideShareSheet = this.hideShareSheet.bind(this);
        this.toggleShareSheet = this.toggleShareSheet.bind(this);
        this.handleLike = this.handleLike.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.goToUser = this.goToUser.bind(this);
        this.handlePostCardCommentChange = this.handlePostCardCommentChange.bind(this);
    }

    goToUser(event) {
        event.preventDefault();
        this.props.navigation.userPage(event.currentTarget.name);
    }

    isPaid() {
        return this.props.isPaid;
    }

    handleLike() {

    }

    handlePostCardCommentChange(event) {

    }

    handleDelete() {
        this.props.onDelete(this.props.postId);
    }

    showShareSheet() {
        this.setState({isShowingShare: true});
    }

    hideShareSheet() {
        this.setState({isShowingShare: false});
    }

    toggleShareSheet() {
        if (this.state.isShowingShare) {
            this.hideShareSheet();
        } else {
            this.showShareSheet();
        }
    }

    likesCount() {
        return this.props.likes ? this.props.likes.length : 0;
    }

    renderPostURL() {
        return `https://www.openswap.io/posts/${this.props.username}`;
    }

    renderShare() {
        return <>
            <div className={this.state.isShowingShare ? 'post-share-sheet' : 'post-share-sheet hide'}>
                <div className='share-item'>
                    <EmailShareButton subject={`Hey! Check out this great content I just found!`} url={this.renderPostURL()}>
                        <EmailIcon size={35} round={true}/>
                    </EmailShareButton>
                </div>
                <div className='share-item'>
                    <FacebookShareButton quote={this.props.username} url={this.renderPostURL()}>
                        <FacebookIcon size={35} round={true}/>
                    </FacebookShareButton>
                </div>
                <div className='share-item'>
                    <PinterestShareButton media={openSwapLogo} url={this.renderPostURL()}>
                        <PinterestIcon size={35} round={true}/>
                    </PinterestShareButton>
                </div>
                <div className='share-item'>
                    <RedditShareButton title={this.props.username} url={this.renderPostURL()}>
                        <RedditIcon size={35} round={true}/>
                    </RedditShareButton>
                </div>
                <div className='share-item'>
                    <TwitterShareButton title={this.props.username} url={this.renderPostURL()}>
                        <TwitterIcon size={35} round={true}/>
                    </TwitterShareButton>
                </div>
            </div>
        </>
    }

    renderPurchaseButton() {
        return <div className='post-card-purchase-button-container'>
            <div className='post-card-purchase-button'>
                <img className='post-card-lock-img' src={lockIcon} />
                <div className='post-card-price'>{this.props.currency + ' ' + this.props.price}</div>
            </div>
        </div>
    }

    renderDelete() {
        return this.props.isDeleting ? <LoadingIndicator positionClassName='delete-packages-loading-position' contentClassName='delete-packages-loading-content' customPosition={true}/> : <img title='Delete Package' alt='Delete Package' onClick={this.handleDelete} src={deleteIcon} />;
    }

    renderPostCardCaptionBox(user, caption) {
        return <div className="post-caption-box">
            {this.renderPostCardCaption(user, caption)}
        </div>;
    }

    renderPostCardCaption(user, caption, navigateToUser) {
        return <div key={user+caption}>
            <span className="post-caption-user">{user}</span>
            <span className="post-caption">{caption}</span>
        </div>
    }

    renderPostCardComments() {
        return <div className="card-box-comments">
            {this.props.comments.map((aComment) => {
                return (this.renderPostCardCaption(aComment.username, aComment.text))
            })}
        </div>
    }

    renderPostCard() {
        return <div className="card-box posts-box">
            <div className="card-box-user">
                <a onClick={this.goToUser} name={this.props.username} href={`/creator/${this.props.username}`} className="card-box-user-img-link">
                    <AvatarImage size='small' identityId={this.props.owner} />
                </a>
                <div className="card-box-user-name">
                    <a onClick={this.goToUser} name={this.props.username} href={`/creator/${this.props.username}`}>
                        {this.props.username}
                    </a>
                </div>
            </div>
            <div className='card-box-img-container'>
                <div className="card-box-img">
                    <img alt={this.props.username} src={this.props.image} className="full-width" />
                    {this.isPaid() ? this.renderPurchaseButton() : ''}
                </div>
            </div>
            {this.props.caption ? this.renderPostCardCaptionBox(this.props.username, this.props.caption) : ''}
            <div className="card-box-actions">
                <div className="post-like-button">
                    <img src={likeIcon} />
                </div>
                <div className="post-share-button">
                    <img onClick={this.toggleShareSheet} src={shareIcon} />
                </div>
                {this.renderShare()}
            </div>
            <div className="card-box-info">
                {/*<span className="post-likes">{this.likesCount()} Likes</span>*/}
                <span className="post-date">{this.dateService.secondsTimestampToLocalizedDateString(this.props.date)}</span>
                <div className="clear"></div>
            </div>
            {/*{this.props.comments ? this.renderPostCardComments() : ''}*/}
            {/*<div className="post-card-footer">*/}
            {/*    <div className="card-box-text-input">*/}
            {/*        <input onChange={this.handlePostCardCommentChange} id="postCardComments" type="text" name="tags" className="form-control post-card-input" placeholder="Add a Comment..." />*/}
            {/*        <div className='post-card-reply-button'>*/}
            {/*            <img src={replyIcon} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>;
    }

    render() {
        return this.renderPostCard();
    }
}

export default PostCard;