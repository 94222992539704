import EnvironmentService from "./environment";
import axios from "axios";
import Authorization from "./authorization";

const api = axios.create({
    baseURL: `${EnvironmentService.baseURL()}/api`,
    timeout: 25000,
    responseType: "json"
});

class UserService {
    static async fetchCurrent() {
        let path = '/users/info';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true,
        };

        return await api.get(path, {headers: myInit["headers"]});
    }

    static async fetch(publicName) {
        let path = '/users/info';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true,
        };

        myInit['queryStringParameters'] = {
            publicName
        };

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async update(userData) {
        let path = '/users';
        let myInit = {
            body: userData,
            headers: {...(await Authorization.authorizationHeader())}
        };

        return await api.put(path, myInit['body'], {headers: myInit["headers"]});
    }

    static async follow(userId) {
        let path = `/users/follow/${userId}`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())}
        };

        return await api.put(path, {}, {headers: myInit["headers"]});
    }

    static async unfollow(userId) {
        let path = `/users/follow/${userId}`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())}
        };

        return await api.delete(path, {headers: myInit["headers"]});
    }

    static async fetchFollowing(nextKey, limit) {
        let path = `/users/following`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            queryStringParameters: {},
        };

        if (limit) {
            myInit['queryStringParameters']['limit'] = limit;
        }

        if (nextKey) {
            myInit['queryStringParameters']['nextKey'] = nextKey;
        }

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async fetchFollowers(nextKey, limit) {
        let path = `/users/followers`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            queryStringParameters: {},
        };

        if (limit) {
            myInit['queryStringParameters']['limit'] = limit;
        }

        if (nextKey) {
            myInit['queryStringParameters']['nextKey'] = nextKey;
        }

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async isFollowing(userId) {
        let path = `/users/following`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())}
        };

        myInit['queryStringParameters'] = {
            filterAttribute: "_id",
            filterValue: userId
        };

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }
}

export default UserService;
