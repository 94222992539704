import {API as GRAPHAPI} from '@aws-amplify/api';
import {Auth} from '@aws-amplify/auth'
import * as mutations from "../graphql/mutations";
import PreferenceService from "../services/preference";
import EnvironmentService from "./environment";
import axios from "axios";
import Authorization from "./authorization";

const RESTAPI = axios.create({
    baseURL: `${EnvironmentService.baseURL()}/api`,
    timeout: 25000,
    responseType: "json"
});

class PostService {
    static async savePost(caption, assetURL, isAdult) {
        GRAPHAPI.graphql({
            query: mutations.createSocialPost,
            variables: {
                text: caption,
                assetPath: assetURL,
                isAdult: isAdult
            }
        }, {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        });
    }

    static async fetchPosts(nextKey, limit) {
        let path = `/social/posts`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            queryStringParameters: {},
            response: true
        };

        if (PreferenceService.shouldFilterAdult()) {
            myInit['queryStringParameters']['filterAttribute[is_adult]'] = false;
        }

        if (limit) {
            myInit['queryStringParameters']['limit'] = limit;
        }

        if (nextKey) {
            myInit['queryStringParameters']['nextKey'] = nextKey;
        }


        return await RESTAPI.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async fetchPostsForUserName(publicName, nextKey, limit) {
        let path = '/social/posts';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true
        };

        myInit['queryStringParameters'] = {
            'filterAttribute[author.publicName]': publicName,
        };

        if (PreferenceService.shouldFilterAdult()) {
            myInit['queryStringParameters']['filterAttribute[is_adult]'] = false;
        }

        if (limit) {
            myInit['queryStringParameters']['limit'] = limit;
        }

        if (nextKey) {
            myInit['queryStringParameters']['nextKey'] = nextKey;
        }

        return await RESTAPI.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }
}

export default PostService;
