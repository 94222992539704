class Rate {
    static async btcToUsd(btc) {
        let result = await fetch('https://blockchain.info/ticker');
        let rates = await result.json();
        return (btc ? rates['USD']['15m'] * btc : rates['USD']['15m']);
    }

    static async btcToOneUsd() {
        let result = await fetch('https://blockchain.info/ticker');
        let rates = await result.json();
        return rates['USD']['15m'];
    }
}

export default Rate;
