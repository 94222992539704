import React from "react";

import UserService from "../../services/user";

import FOLLOW_CONTEXT from "../../constants/follow-context";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveNavigation from "../../components/responsive-navigation";
import LoadingIndicator from "../../components/loading-indicator";
import UserCard from "./userCard";
import HelmetComponent from "../../components/helmet";

const openSwapLogo = require('../../images/open-swap-ico.svg');

const PAGE = 25;

class Follow extends React.Component {
    constructor(props) {
        super(props);

        this.currentContext = null;

        this.state = {
            follow: [],
            isLoading: false,
            isLoadingMore: false,
            paging: {},
        };

        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
       this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.currentContext !== this.props.context) {
            this.currentContext = this.props.context;
            this.setState({follow: []}, () => {
                if (!this.state.isLoading && !this.state.isLoadingMore) {
                    this.fetchData();
                }
            });
        }
    }

    fetchData(includeNextKey = false) {
        if (this.props.context === FOLLOW_CONTEXT.FOLLOWING) {
            this.fetchFollowing(includeNextKey);
        } else {
            this.fetchFollowers(includeNextKey);
        }
    }

    fetchFollowing(includeNextKey) {
        this.setState({[includeNextKey ? 'isLoadingMore' : 'isLoading']: true});
        UserService.fetchFollowing(includeNextKey, PAGE).then(response => this.handleSuccessfulFollowingFetch(response)).catch(() => this.handleFailedFetch());
    }

    fetchFollowers(includeNextKey) {
        this.setState({[includeNextKey ? 'isLoadingMore' : 'isLoading']: true});
        UserService.fetchFollowers(includeNextKey, PAGE).then(response => this.handleSuccessfulFollowersFetch(response)).catch(() => this.handleFailedFetch());
    }

    loadMore() {
        this.setState({isLoadingMore: true});
        this.fetchData(this.state.paging.nextKey ? this.state.paging.nextKey : null);
    }

    handleSuccessfulFollowersFetch(response) {
        console.log(response);
        if (!response || !response.data || !response.data.followers || response.data.followers.length === 0) {
            this.handleFailedFetch();
        } else {
            this.setState((prevState) => ({follow: prevState.follow.concat(response.data.followers), paging: response.data.paging, isLoading: false, isLoadingMore: false}));
        }
    }

    handleSuccessfulFollowingFetch(response) {
        console.log(response);
        if (!response || !response.data || !response.data.following || response.data.following.length === 0) {
            this.handleFailedFetch();
        } else {
            this.setState((prevState) => ({follow: prevState.follow.concat(response.data.following), paging: response.data.paging, isLoading: false, isLoadingMore: false}));
        }
    }

    handleFailedFetch() {
        this.setState({isLoading: false, isLoadingMore: false, follow: [], paging: {}})
    }

    isLoading() {
        return this.state.isLoading;
    }

    isLoadingMore() {
        return this.state.isLoadingMore;
    }

    canLoadMore() {
        return !!this.state.paging.nextKey;
    }


    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFollowList() {
        return this.state.follow.map((followItem, index) => <UserCard key={index} navigation={this.props.navigation} userId={followItem._id} packageQuantity={followItem.publishedPackages} userImage={openSwapLogo} username={followItem.publicName} bio={followItem.bio} />);
    }

    renderEmptyFollowList() {
        return <p className='center follow-empty-text-container'>{this.props.context === FOLLOW_CONTEXT.FOLLOWING ? "You're not following anyone... for now" : "You have no followers... yet"}</p>;
    }

    renderLoading() {
        return <LoadingIndicator/>;
    }

    renderContent() {
        return <section className="featured my-following">
            {this.props.context === FOLLOW_CONTEXT.FOLLOWING ? <h3>You are following</h3> : <h3>Your followers</h3>}
            <div className="container">
                <div className="row">
                    {this.state.follow.length > 0 ? this.renderFollowList() : this.renderEmptyFollowList()}
                </div>
                {this.canLoadMore() && this.renderLoadMoreButton()}
            </div>
        </section>;
    }

    renderLoadMoreButton() {
        return <div className="row top-padding"><button onClick={this.loadMore} id="SignupButton" className={this.state.isLoadingMore ? "btn--gray button-loading-border margin-center" : "btn--blueGr margin-center"} style={{"maxWidth": "300px"}}>Load More...</button></div>
    }

    renderFollowPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            {this.isLoading() ? this.renderLoading() : this.renderContent()}

            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            <HelmetComponent title='Connections' description='OpenSwap.io list of users that you follow and that follow you.' />
        </div>
    }

    render() {
        return this.renderFollowPage();
    }
}

export default Follow;
