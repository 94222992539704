import React from "react";

import ContentRow from "./contentRow";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveMenu from "../../components/responsive-navigation";
import LoadingIndicator from "../../components/loading-indicator";
import PurchaseService from "../../services/purchase";
import EnvironmentService from "../../services/environment";
import CredentialService from "../../services/credential";
import PackageService from "../../services/package";

import PACKAGE_LICENSE from "../../constants/package-licenses";
import HelmetComponent from "../../components/helmet";

const portraitImage = require('../../images/portrait.jpg');

const CREDENTIALS_REFRESH_PERIOD_SECONDS = 540;

class PurchasedPackage extends React.Component {
    constructor(props) {
        super(props);

        this.credentialsPolling = null;

        this.state = {
            isLoading: true,
            description: '',
            tags: [],
            name: '',
            contents: [],
            previews: [],
            packageOwnerId: '',
            license: PACKAGE_LICENSE.PERSONAL_USE,
        };

        this.fetchCredentials = this.fetchCredentials.bind(this);
    }

    componentDidMount() {
        this.fetchPurchase();

        this.credentialsPolling = setInterval(this.fetchCredentials, CREDENTIALS_REFRESH_PERIOD_SECONDS * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.credentialsPolling);
    }

    getTags() {
        return this.state.tags.length > 0 ? this.state.tags : [];
    }

    fetchPurchase() {
        PurchaseService.fetchPurchaseByPackage(this.retrievePackageId()).then(response => this.handleSuccessfulPurchaseFetch(response.data.purchases[0])).catch(error => this.handleFailedPurchaseFetch(error));
    }

    fetchPackage() {
        PackageService.fetchPackage(this.retrievePackageId(), true).then(response => this.handleSuccessfulPackageFetch(response.data.packages[0])).catch(error => this.handleFailedPackageFetch(error));
    }

    fetchCredentials() {
        CredentialService.credentialsForPackageAssets(this.state.packageOwnerId, this.retrievePackageId(), this.props.currentUser.username, this.props.currentUser.signInUserSession.accessToken.jwtToken);
    }

    retrievePackageId() {
        let packageId = this.props.location.pathname.split("/");
        return (packageId && packageId[3]) ? packageId[3] : null;
    }

    handleSuccessfulPurchaseFetch() {
        this.fetchPackage();
    }

    handleFailedPurchaseFetch() {
        this.props.navigation.home();
    }

    handleSuccessfulPackageFetch(packagePayload) {
        this.setState({isLoading: false, description: packagePayload.description, previews: packagePayload.previews, name: packagePayload.name, tags: packagePayload.tags, contents: packagePayload.contents, packageOwnerId: packagePayload.owner_id}, () => {
            this.fetchCredentials();
        });
    }

    handleFailedPackageFetch() {
        this.props.navigation.home();
    }

    renderPackageLicense() {
        switch(this.state.license) {
            case PACKAGE_LICENSE.PERSONAL_USE:
                return 'This Package is licensed for Personal Use only'
            case PACKAGE_LICENSE.ATTRIBUTION:
                return 'This Package is licensed for any kind of use as long as the original author receives attribution'
            case PACKAGE_LICENSE.ATTRIBUTION_NONCOMMERCIAL:
                return 'This Package is licensed for non commercial use as long as the original author receives attribution'
            default:
                return 'This Package is licensed for Personal Use only'
        }
    }

    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer/>
    }

    renderRenderResponsiveMenu() {
        return <ResponsiveMenu isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderLoadingIndicator() {
        return <LoadingIndicator />
    }

    renderPackageContentsHeader() {
        return <tr>
            <td className="w20">&nbsp;</td>
            <td>
                #
            </td>
            <td>
                Name
            </td>
            <td>
                Download
            </td>
            <td className="w20">&nbsp;</td>
        </tr>;
    }

    renderPackageImageSrc(s3Key) {
        return `${EnvironmentService.staticURL()}/${s3Key}`;
    }

    renderPackageImage() {
        let packageImageSrc = portraitImage;

        if (this.state.previews.length > 0) {
            packageImageSrc = this.renderPackageImageSrc(this.state.previews[0].uri);
        }
        return <div className="package-image"><img alt='' src={packageImageSrc} /></div>;
    }

    renderPackageContents() {
        return <section className="purchases no-padding">
            <div className="container">
                <div className="row">
                    <div className="col" style={{"padding": "0"}}>

                        <h2>Contents</h2>

                        <table className="transactionsTable table-center" border="0" cellPadding="0" cellSpacing="0" width="100%">
                            <tbody>
                            {this.renderPackageContentsHeader()}
                            {this.state.contents ? this.state.contents.map((contentItem, index) => <ContentRow key={index} position={index + 1} contentName={contentItem.uri} downloadKey={contentItem.uri} />) : ''}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </section>
    }

    renderPurchasedPackage() {
        return <section className="featured packages">
            <h3>{this.state.name}</h3>
            <div className="container">
                <div className="row">
                    <div className="col">
                        {this.renderPackageImage()}
                        <div className="purchased-package">
                            <p>{this.state.description}</p>
                        </div>
                        <div className="purchased-package text-center package-tag">
                            {this.getTags().map(tag => <a key={tag} href={`/packages/tag/${tag}`}>{tag}</a>)}
                        </div>
                        <div className='text-center package-preview-license'>
                            {this.renderPackageLicense()}
                        </div>
                        <div className="text-center">
                            <hr></hr>
                            {this.renderPackageContents()}
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }

    renderPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            {this.state.isLoading ? this.renderLoadingIndicator() : this.renderPurchasedPackage()}
            {this.renderFooter()}
            {this.renderRenderResponsiveMenu()}
            <HelmetComponent title={`Purchased - ${this.state.name}`} description='OpenSwap.io purchased package.' />
        </div>
    }

    render() {
        return this.renderPage();
    }
}

export default PurchasedPackage;
