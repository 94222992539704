import PreferenceService from "../services/preference";
import EnvironmentService from "./environment";
import axios from "axios";
import Authorization from "./authorization";

const api = axios.create({
    baseURL: `${EnvironmentService.baseURL()}/api`,
    timeout: 25000,
    responseType: "json"
});

class PackageService {

    static async fetchPackage(packageId, isPurchased = false) {
        let path = `/packages`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true
        };

        myInit['queryStringParameters'] = {
            'filterAttribute[_id]': packageId,
            returnComments: true,
        };

        if (isPurchased) {
            myInit['queryStringParameters']['returnContents'] = true;
        }

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async fetchPackagesForTag(tag, nextKey, limit) {
        let path = `/packages`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            queryStringParameters: {},
            response: true
        };

        myInit['queryStringParameters'] = {
            'filterAttribute[tags]': tag,
        };

        if (limit) {
            myInit['queryStringParameters']['limit'] = limit;
        }

        if (nextKey) {
            myInit['queryStringParameters']['nextKey'] = nextKey;
        }

        if (PreferenceService.shouldFilterAdult()) {
            myInit['queryStringParameters']['filterAttribute[is_adult]'] = 'false';
        }

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async fetchPackagesForUser(userId, nextKey, limit) {
        let path = '/packages';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true
        };

        myInit['queryStringParameters'] = {
            'filterAttribute[owner_id]': userId,
        };

        if (PreferenceService.shouldFilterAdult()) {
            myInit['queryStringParameters']['filterAttribute[is_adult]'] = false;
        }

        if (limit) {
            myInit['queryStringParameters']['limit'] = limit;
        }

        if (nextKey) {
            myInit['queryStringParameters']['nextKey'] = nextKey;
        }

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async fetchNewPackages(nextKey, limit) {
        let path = '/packages';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            queryStringParameters: {},
            response: true
        };

        if (PreferenceService.shouldFilterAdult()) {
            myInit['queryStringParameters']['filterAttribute[is_adult]'] = false;
        }

        if (limit) {
            myInit['queryStringParameters']['limit'] = limit;
        }

        if (nextKey) {
            myInit['queryStringParameters']['nextKey'] = nextKey;
        }

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async saveComment(packageId, comment) {
        let path = `/packages/${packageId}/comment`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            body: {
                    comments: [
                        {
                            comment: comment
                        }
                    ]
            },
            response: true
        };

        return await api.post(path, myInit['body'], {headers: myInit["headers"]});
    }

    static async savePackage(packageBody) {
        let path = '/packages';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            body: packageBody,
            response: true
        };

        return await api.post(path, myInit['body'], {headers: myInit["headers"]});
    }

    static async editPackage(packageId, partialPackageBody) {
        // This is required by the backend for validation purposes
        partialPackageBody._id = packageId;

        let path = `/packages/${packageId}`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            body: partialPackageBody,
            response: true
        };

        return await api.put(path, myInit['body'], {headers: myInit["headers"]});
    }

    static async deletePackage(packageId) {
        let path = `/packages/${packageId}`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true
        };

        return await api.delete(path, {headers: myInit["headers"]});
    }

    static async fetchIDFromAlias(alias) {
        try {
            const response = await fetch(`${EnvironmentService.baseURL()}/static/protected/package/alias/${alias}`);
            const decodedResponse = await response.json();
            return decodedResponse['_id'];
        } catch(error) {
            return null;
        }
    }
}

export default PackageService;
