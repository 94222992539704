import EnvironmentService from "./environment";

class Address {
    static async getBalance(address, confirmations) {
        let result = await fetch(`${EnvironmentService.blockChainInfoURL()}/addrs/${address}/balance`);
        return await result.json();
    }
}

export default Address;
