import React from "react";

import Navigation from '../../components/navigation';
import Footer from '../../components/footer';
import ResponsiveMenu from '../../components/responsive-navigation';
import HelmetComponent from "../../components/helmet";

class FAQ extends React.Component {

    renderNavigation() {
        return <Navigation navigation={this.props.navigation}/>
    }

    renderFooter() {
        return <Footer/>
    }

    renderRenderResponsiveMenu() {
        return <ResponsiveMenu/>
    }

    renderFAQ() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            <section>
                <div className="container">
                    <div className="faq">
                        <h1>Who are we and what do we do?</h1>
                        <p>At OpenSwap, we believe that artists creating content should be able to capture as much of the value they create as possible.</p>
                        <p>Following that belief, we've created a platform that's simple to use and puts the artist first.</p>
                        <p>Whether you're a model, writer, musician, photographer or influencer, OpenSwap allows you to monetize your content and your audience efficiently and easily.</p>
                        <p>We're also the only marketplace which generates an NFT for each Package that you publishing -- giving you true ownership of your content.</p>
                        <p>Learn more about NFTs and how they help artists <a href={'https://time.com/6093982/nft-art-teens-money/'}>here</a>.</p>
                        <br/>
                        <h1>How can I monetize my content or audience?</h1>

                        <p>The OpenSwap platform allows you to create Packages comprised of content you upload. You can price the Package as you wish and share it
                        effortlessly with your audience.</p>
                        <p>Once your content sells, you receive payment immediately.</p>
                        <p>We offer promotional tools which allow you to get your content in front of your audience effortlessly</p>
                        <p>We offer content aggregation tools which allow you to import content from other marketplaces and maximize your revenue.</p>

                        <h2>1. How do I get paid?</h2>
                        <p>As a content creator, you will be paid in Bitcoin to the address which you've provided.</p>

                        <h2>2. How long do I have to wait to get paid?</h2>
                        <p>You get paid within minutes of any sale you make.</p>

                        <h2>3. Is there a minimum balance which I need to hit in order to get paid?</h2>
                        <p>No. You will be paid immediately regardless of how small your balance is.</p>

                        <h2>4. How much do I get to keep after a sale?</h2>
                        <p>You keep 100% of the price of the Package sold.</p>
                        <p>As a reference, here are the fees charged by other content marketplaces</p>

                        <p>
                            <table className="transactionsTable table-center" border="0" cellPadding="0" cellSpacing="0" width="20%">
                                <tbody>
                                <tr>
                                    <td>
                                        BentBox
                                    </td>
                                    <td>
                                        50%
                                    </td>
                                </tr>
                                    <tr>
                                        <td>
                                            Clips4Sale
                                        </td>
                                        <td>
                                            40%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PornHub
                                        </td>
                                        <td>
                                            32%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            ManyVids
                                        </td>
                                        <td>
                                            20%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            OnlyFans
                                        </td>
                                        <td>
                                            20%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </p>

                        <h2>5. Is your platform free?</h2>
                        <p>The OpenSwap platform is free to use for all content creators and content consumers.</p>

                        <h2>6. What can I sell?</h2>
                        <p>You may sell basically any digital content you produce. Be it books, stories, educational material, music, pictures or videography.</p>
                        <p>We're always listening to content creators; if the service we provide doesn't work well for the type of content you produce,
                            let us <a href="mailto:creators@openswap.io">know</a>.</p>

                        <h2>7. What kind of content licenses do you offer?</h2>
                        <p>We offer three licenses which determine how your content may be used by the purchases. These licenses are:</p>
                        <p><b>Attribution</b></p>

                            <p>This license lets others distribute, remix, adapt, and build upon your work, even commercially, as long as they credit you for the original creation. This is the most accommodating of licenses offered. Recommended for maximum dissemination and use of licensed materials.</p>

                            <p><b>Attribution-NonCommercial</b></p>

                            <p>This license lets others remix, adapt, and build upon your work non-commercially, and although their new works must also acknowledge you and be non-commercial, they don’t have to license their derivative works on the same terms.</p>

                            <p><b>Personal Use</b></p>

                            <p>This license restricts the distribution of the purchased content fully. Only the purchaser may enjoy the content in a private manner without any public or semi-public distribution</p>

                        <h2>8. Why do you only accept Bitcoin?</h2>
                        <p>Bitcoin is the technology which allows us to pay you, the content creator, immediately and with no minimum balance. It is also the technology which allows us to have the lowest fees of any content platform.</p>

                        <h2>9. What is Bitcoin?</h2>
                        <p>A few places to start:</p>
                        <p><a href="https://bitcoin.org/en/how-it-works">How does Bitcoin work?</a></p>
                        <p><a href="https://learn.na.bambora.com/bitcoin-explained/">Bitcoin explained</a></p>
                        <p><a href="https://www.coindesk.com/bitcoin-explained-five-year-old">Bitcoin Explained for Five Year Olds</a></p>
                        <p>A few places to get Bitcoin:</p>
                        <p><a href="https://www.kraken.com">Kraken</a></p>
                        <p><a href="https://gemini.com">Gemini</a></p>
                        <p><a href="https://www.coinbase.com">CoinBase</a></p>
                    </div>
                </div>
            </section>
            {this.renderFooter()}
            {this.renderRenderResponsiveMenu()}
            <HelmetComponent title='FAQ' description='OpenSwap.io frequently asked questions.' />
        </div>;
    }

    render() {
        return this.renderFAQ()
    }
}

export default FAQ;
