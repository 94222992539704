import React from "react";

import QRCode from "qrcode.react";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveMenu from "../../components/responsive-navigation";
import LoadingIndicator from "../../components/loading-indicator";
import PurchaseService from "../../services/purchase";
import {Carousel} from "react-responsive-carousel";
import EnvironmentService from "../../services/environment";
import AddressService from "../../services/address";
import PURCHASE_STATE from "../../constants/purchase-status";
import HelmetComponent from "../../components/helmet";

const SHORT_POLL_INTERVAL = 30000;
const LONG_POLL_INTERVAL = 50000;

class Purchase extends React.Component {
    constructor(props) {
        super(props);

        this.shortPollingPointer = null;
        this.longPollingPointer = null;

        this.state = {
            isLoading: true,
            purchase: null,
            purchaseStatus: PURCHASE_STATE.PENDING,
        };

        this.copyAmount = this.copyAmount.bind(this);
        this.copyBTCAddress = this.copyBTCAddress.bind(this);
        this.goToCopyright = this.goToCopyright.bind(this);
    }

    componentDidMount() {
        this.fetchPurchase();
    }

    componentWillUnmount() {
        clearInterval(this.shortPollingPointer);
        clearInterval(this.longPollingPointer);
    }

    fetchPurchase() {
        PurchaseService.fetchPurchaseByPackage(this.retrievePackageId()).then(response => this.handleSuccessfulPurchaseFetch(response.data.purchases[0])).catch(error => this.props.navigation.home());
    }

    retrievePackageId() {
        let packageId = this.props.location.pathname.split("/");
        return (packageId && packageId[2]) ? packageId[2] : null;
    }

    handleSuccessfulPurchaseFetch(purchase) {
        if (!this.shortPollingPointer) {
            this.shortPollingPointer = setInterval(() => this.fetchAddressData(purchase.transaction.to_address), SHORT_POLL_INTERVAL);
        }

        this.setState({isLoading: false, purchase: purchase}, () => {
            if (this.hasPaymentBeenConfirmed()) {
                this.redirectToPurchasedPackage();
            }
        });
    }

    fetchAddressData(address) {
        AddressService.getBalance(address, (this.state.purchaseStatus === PURCHASE_STATE.PENDING ? 0 : 1)).then(result => this.handleSuccessfulAddressDataFetch(result));
    }

    handleSuccessfulAddressDataFetch(result) {
        /*
        As soon as the transaction shows up on the blockchain, confirm with the backend and redirect the user to the Purchased Package page
        */
        const unconfirmedTxs = result.unconfirmed_n_tx;
        const confirmedTxs = result.n_tx;

        if (unconfirmedTxs === 0 && confirmedTxs === 0 && this.state.purchaseStatus === PURCHASE_STATE.PENDING) {

            clearInterval(this.shortPollingPointer);

            this.longPollingPointer = setInterval(() => this.fetchAddressData(this.state.purchase.transaction.to_address), LONG_POLL_INTERVAL);
        } else {
            this.fetchPurchase();
        }
    }

    redirectToPurchasedPackage() {
        this.props.navigation.purchasedPackage(this.state.purchase.package._id);
    }

    goToCopyright(event) {
        event.preventDefault();
        this.props.navigation.copyright();
    }

    copyAmount(event) {
        event.preventDefault();
        navigator.clipboard.writeText(this.state.purchase.transaction.amount);
    }

    copyBTCAddress() {
        navigator.clipboard.writeText(this.state.purchase.transaction.to_address);
    }

    hasPaymentBeenReceived() {
        return (this.state.purchaseStatus === PURCHASE_STATE.PAID) || (this.state.purchase.status === PURCHASE_STATE.PAID);
    }

    hasPaymentBeenConfirmed() {
        return (this.state.purchase.status === PURCHASE_STATE.PAID || this.state.purchase.status === PURCHASE_STATE.CONFIRMED || this.state.purchase.status === PURCHASE_STATE.JOSE || this.state.purchase.status === PURCHASE_STATE.DISBURSED);
    }

    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer/>
    }

    renderRenderResponsiveMenu() {
        return <ResponsiveMenu isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderLoadingIndicator() {
        return <LoadingIndicator />
    }

    renderQRCodeValue() {
        return `bitcoin:${this.state.purchase.transaction.to_address}?amount=${this.state.purchase.transaction.amount}`;
    }

    renderPurchaseDepositDetails() {
        return <>
                <div className="qr-code">
                    <QRCode value={this.renderQRCodeValue()} />
                    <div className='top-padding'>
                        Scan this QR code in your favorite Bitcoin wallet.
                    </div>
                </div>
                <hr />
                <div className="financials-containers">
                    <div className="amount-remaining">
                        <span>Amount</span>
                        <br />
                        <a onClick={this.copyAmount} href="copy/address">{this.state.purchase.transaction.amount} BTC</a>
                    </div>
                    <div className="amount-remaining">
                        <span>Address</span>
                        <br />
                        <span onClick={this.copyBTCAddress} className="btc-address">{this.state.purchase.transaction.to_address}</span>
                    </div>
                    <div className="amount-remaining">
                        <span>Payment Status</span>
                        <br />
                        <span>Waiting...</span>
                    </div>
                </div>
                <hr />
                <div className="transaction-fees">Make sure to send enough to cover any coin transaction fees!
                    <div className="transaction-fees">Payment ID: {this.state.purchase._id}</div>
                    {/*<hr style={{"marginBottom": "30px"}} />*/}
                    {/*<div>Help!</div>*/}
                </div>
            </>;
    }

    renderPurchasePending() {
        return <div>
            <div className="payment-received"><h3>Payment Received</h3></div>
            <p className="payment-received">Thank you, your payment was received. We require 2 confirmations on the BTC transaction before releasing the purchased package. You will be redirected automatically to your package when the transaction confirms.</p>
        </div>
    }

    renderPreviews() {
        return this.state.purchase.package.previews.map(preview => this.renderPreview(preview));
    }

    renderPreview(preview) {
        return <div key={preview.uri}>
            <img alt='' src={this.renderPackageImage(preview.uri)} />
        </div>
    }

    renderPackageImage(s3Key) {
        return `${EnvironmentService.staticURL()}/${s3Key}`;
    }

    renderPurchase() {
        return <section className="purchase">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2>You are purchasing</h2>
                        <div className="package-name">{this.state.purchase.package.name}</div>
                        <div className="package-image">
                            <Carousel dynamicHeight={true} showStatus={false}>
                             {this.renderPreviews()}
                            </Carousel>
                            <p>Note that by purchasing this Package you agree to the OpenSwap.io <a href={'/copyright'} onClick={this.goToCopyright}>Copyright Policy</a></p>
                        </div>
                        <hr />
                        {this.hasPaymentBeenReceived() ? this.renderPurchasePending() : this.renderPurchaseDepositDetails()}
                    </div>
                </div>
            </div>
        </section>;
    }

    renderSEOTitle() {
        return this.state.purchase ? this.state.purchase.package.name : '';
    }

    renderPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            {this.state.isLoading ? this.renderLoadingIndicator() : this.renderPurchase()}
            {this.renderFooter()}
            {this.renderRenderResponsiveMenu()}
            <HelmetComponent title={`Purchasing - ${this.renderSEOTitle()}`} description='OpenSwap.io package purchase page.' />
        </div>
    }

    render() {
        return this.renderPage();
    }
}

export default Purchase;
