import EventEmitter from 'events';
import { Auth } from "aws-amplify";
import Authorization from "./authorization";

const emitter = new EventEmitter();

const AUTH_STATE = {
    PENDING: 'pending',
    AUTHENTICATED: 'authenticated',
    UNAUTHENTICATED: 'unauthenticated',
};

let SessionService = {
    subscribe(event, callback) {
        return emitter.addListener(event, callback);
    },

    unsubscribe(event, callback) {
        return emitter.removeListener(event, callback);
    },

    async getCurrentUser() {
        let currentUser = await Auth.currentAuthenticatedUser();
        let currentUserCredentials = await Auth.currentUserCredentials();

        currentUser['userId'] = currentUserCredentials.identityId;
        return currentUser;
    },

    async signUp(email, password, username) {
        return await Auth.signUp({
            username: email.toLowerCase(),
            password: password,
            attributes: {nickname: username}
        });
    },

    async signIn(email, password, onboardUser) {
        await Auth.signIn({
            username: email.toLowerCase(),
            password: password,
        });

        if (onboardUser) {
            await Authorization.onboardUser(await Auth.currentSession());
            await Authorization.refreshToken();
        }

        emitter.emit('authStateChange', AUTH_STATE.AUTHENTICATED, await SessionService.getCurrentUser());
    },

    async signOut() {
        await Auth.signOut();
        this.data = { currentUser: null };
        emitter.emit('authStateChange', AUTH_STATE.UNAUTHENTICATED, null);
    }
};

export default SessionService;

