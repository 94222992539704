import React from 'react';
import ScrollTracker from "../scroll-tracker";
import PostCard from "../post-card";
import EnvironmentService from "../../services/environment";

class PostList extends React.Component {

    constructor(props) {
        super(props);

        this.scrollTracker = new ScrollTracker('container', this.handleReachedBottom, this);

        this.handleReachedBottom = this.handleReachedBottom.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.scrollTracker.startTrackingScrolling();
    }

    componentWillUnmount() {
        this.scrollTracker.stopTrackingScrolling();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.scrollTracker.startTrackingScrolling();
    }

    handleReachedBottom() {
        this.props.loadMore();
    }

    hasPosts() {
        return (this.props.posts && this.props.posts.length > 0);
    }

    isEditing() {
        return !!this.props.isEditing;
    }

    isDeleting(postId) {
        return this.props.deleting && this.props.deleting.includes(postId);
    }

    handleDelete(postId) {
        this.props.onDelete(postId);
    }

    handleEdit(postId) {
        this.props.onEdit(postId);
    }

    renderPostImage(s3Key) {
        return `${EnvironmentService.staticURL()}/${s3Key}`;
    }

    renderPosts() {
        return this.props.posts.map((aPost) => {
            return (<div key={aPost._id} className="row posts-row">
                <PostCard key={aPost._id} isEditing={this.isEditing()} caption={aPost.text} likes={aPost.likes} owner={aPost.author._id} navigation={this.props.navigation} isDeleting={this.isDeleting(aPost._id)} onDelete={this.handleDelete} onEdit={this.handleEdit} postId={aPost._id} image={this.renderPostImage(aPost.assetPath)} username={aPost.author.publicName} currency={'BTC'} isPaid={false} price={0} comments={[]} date={aPost.dateCreated} postTag={0}/>
            </div>);
        })
    }

    renderNoPostsLabel() {
        return <div className='col-md-3'><h4>No posts found...</h4></div>;
    }

    render() {
        return this.hasPosts() ? this.renderPosts() : this.renderNoPostsLabel();
    }
}

export default PostList;