import React from 'react';
import AvatarImage from "../../components/avatar-image";

class CommentItem extends React.Component {
    constructor(props) {
        super(props);

        this.goTo = this.goTo.bind(this);
    }

    goTo(event) {
        event.preventDefault();

        this.props.navigation.userPage(event.currentTarget.name);
    }

    renderItem() {
        return <div className="comment">
            <div className="card-box-user user-name">
                <a onClick={this.goTo} name={this.props.userName} href={`/creator/${this.props.userName}`} className="card-box-user-img-link">
                    <AvatarImage size='small' identityId={this.props.commenterId} />
                </a>
                <div className="card-box-user-name">
                    <a onClick={this.goTo} name={this.props.userName} href={`/creator/${this.props.userName}`}>
                        {this.props.userName}
                    </a>
                </div>
            </div>
            <div className="user-comment">
                <p className='text-container-overflow'>{this.props.comment}</p>
            </div>
        </div>
    }

    render() {
        return this.renderItem();
    }
}

export default CommentItem;
