import React from 'react';

import SessionService from "../../services/session";
import PreferenceService from "../../services/preference";

import NavigationEmitter from '../../emitters/navigation';

const saveBrowsingChevron = require('../../images/fa-angle-down.svg');

class ResponsiveNavigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mobileNavigationMenuVisible: false,
            mobileSafeBrowsingMenuVisible: false,
            explicitContentFilter: PreferenceService.getExplicitContentFilter(),
        };

        this.handleMobileSafeBrowsingMenuToggle = this.handleMobileSafeBrowsingMenuToggle.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleExplicitFilterChange = this.handleExplicitFilterChange.bind(this);
        this.handleExternalExplicitFilterChange = this.handleExternalExplicitFilterChange.bind(this);
        this.renderExplicitContentFilterSelection = this.renderExplicitContentFilterSelection.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
        PreferenceService.subscribe('explicitFilterStateChange', this.handleExternalExplicitFilterChange);

        NavigationEmitter.subscribe('mobileNavMenuToggle', (menuState) => {
            this.setState({ mobileNavigationMenuVisible: !menuState });
        });

        this.setState({ explicitContentFilter: PreferenceService.getExplicitContentFilter() });
    }

    componentWillUnmount() {
        PreferenceService.unsubscribe('explicitFilterStateChange', this.handleExternalExplicitFilterChange);
        NavigationEmitter.unsubscribe('mobileNavMenuToggle', () => {});
    }

    handleMobileSafeBrowsingMenuToggle() {
        this.setState((prevState) => {
            return { mobileSafeBrowsingMenuVisible: !prevState.mobileSafeBrowsingMenuVisible }
        });
    }

    handleExplicitFilterChange(event) {
        PreferenceService.setExplicitContentFilter(event.target.name);
        this.setState({explicitContentFilter: event.target.name});
    }

    handleExternalExplicitFilterChange() {
        this.setState({explicitContentFilter: PreferenceService.getExplicitContentFilter()});
    }

    goTo(event) {
        const navigation = this.props.navigation;

        event.preventDefault();
        switch (event.target.name) {
            case '/my/settings':
                navigation.settings();
                break;
            case '/my/upload':
                navigation.upload();
                break;
            case '/my/content':
                navigation.manageContent();
                break;
            case '/my/sales':
                navigation.sales();
                break;
            case '/my/purchases':
                navigation.purchases();
                break;
            case '/my/following':
                navigation.following();
                break;
            case '/my/followers':
                navigation.followers();
                break;
            case '/my/page':
                navigation.myPage();
                break;
            case '/featured/packages':
                navigation.featuredPackages();
                break;
            case '/featured/posts':
                navigation.featuredPosts();
                break;
            case '/signin':
                navigation.signIn();
                break;
            case '/signup':
                navigation.signUp();
                break;
            case '/faq':
                navigation.faq();
                break;
            default:
                navigation.home();
        }
    }

    async handleLogout(event) {
        event.preventDefault();
        await SessionService.signOut();
        this.props.navigation.home();
    }

    renderExplicitContentFilterSelection(inputName) {
        return this.state.explicitContentFilter === inputName;
    }

    renderNavigation() {
        return <div className={this.state.mobileNavigationMenuVisible ? "responsiveMenu fadeIn" : "responsiveMenu fadeOut"}>
            <ul>
                <li><a onClick={this.goTo} name='/featured/posts' href="/featured/posts">Posts</a></li>
                <li><a onClick={this.goTo} name='/featured/packages' href="/featured/packages">Packages</a></li>
                <li onClick={this.handleMobileSafeBrowsingMenuToggle} style={{"cursor": "default"}}>Safe browsing <img width="10" alt='' src={saveBrowsingChevron} />
                    <ul className={this.state.mobileSafeBrowsingMenuVisible ? "dropdown dropdown-mobile dropdown-mobile-top dropdown-mobile-visible" : "dropdown dropdown-mobile dropdown-mobile-top dropdown-mobile-hidden"}>
                        <li className="not-link">
                            <div className="checkbox">
                                <input onClick={this.handleExplicitFilterChange} onChange={this.handleExplicitFilterChange} type="radio" id="hide-explicit-content-mobile" name="hide" checked={this.renderExplicitContentFilterSelection('hide')} />
                                <label  htmlFor="hide-explicit-content-mobile">
                                    <svg id="checkmarkIcon" width="100" height="100" viewBox="0 -2 12.23 9.18">
                                        <path d="M12.17.87,5.11,7.93,1.36,4.18" transform="translate(-0.65 -0.16)"
                                              fill="none" strokeMiterlimit="10" strokeWidth="2"></path>
                                    </svg>
                                </label>
                            </div>
                            <label htmlFor="hide-explicit-content-mobile" className="nav-checkbox-label">Hide explicit
                                content</label>
                        </li>
                        <li className="not-link">
                            <div className="checkbox">
                                <input onChange={this.handleExplicitFilterChange} type="radio" id="obscure-explicit-content-mobile" name="obscure" checked={this.renderExplicitContentFilterSelection('obscure')} />
                                <label htmlFor="obscure-explicit-content-mobile">
                                    <svg id="checkmarkIcon" width="100" height="100" viewBox="0 -2 12.23 9.18">
                                        <path d="M12.17.87,5.11,7.93,1.36,4.18" transform="translate(-0.65 -0.16)"
                                              fill="none" strokeMiterlimit="10" strokeWidth="2"></path>
                                    </svg>
                                </label>
                            </div>
                            <label htmlFor="obscure-explicit-content-mobile" className="nav-checkbox-label">Obscure explicit content</label>
                        </li>
                        <li className="not-link">
                            <div className="checkbox">
                                <input onChange={this.handleExplicitFilterChange} type="radio" id="show-everything-mobile" name="show" checked={this.renderExplicitContentFilterSelection('show')} />
                                <label htmlFor="show-everything-mobile">
                                    <svg id="checkmarkIcon" width="100" height="100" viewBox="0 -2 12.23 9.18">
                                        <path d="M12.17.87,5.11,7.93,1.36,4.18" transform="translate(-0.65 -0.16)"
                                              fill="none" strokeMiterlimit="10" strokeWidth="2"></path>
                                    </svg>
                                </label>
                            </div>
                            <label htmlFor="show-everything-mobile" className="nav-checkbox-label">Show
                                everything</label>
                        </li>
                    </ul>
                </li>
                <li><a onClick={this.goTo} name='/signin' href="/signin">Sign In</a></li>
                <li><a onClick={this.goTo} name='signup' href="/signup">Sign Up</a></li>
                <li><a onClick={this.goTo} name='/faq' href="/faq">FAQ</a></li>
            </ul>
        </div>;
    }

    renderAuthenticatedNavigation() {
        return <div className={this.state.mobileNavigationMenuVisible ? "responsiveMenu fadeIn" : "responsiveMenu fadeOut"}>
            <ul>
                <li><a onClick={this.goTo} name='/featured/posts' href="/featured/posts">Posts</a></li>
                <li><a onClick={this.goTo} name='/featured/packages' href="/featured/packages">Packages</a></li>
                <li onClick={this.handleMobileSafeBrowsingMenuToggle} style={{"cursor": "default"}}>Safe browsing <img width="10" alt='' src={saveBrowsingChevron} />
                    <ul className={this.state.mobileSafeBrowsingMenuVisible ? "dropdown dropdown-mobile dropdown-mobile-top dropdown-mobile-visible" : "dropdown dropdown-mobile dropdown-mobile-top dropdown-mobile-hidden"}>
                        <li className="not-link">
                            <div className="checkbox">
                                <input onClick={this.handleExplicitFilterChange} onChange={this.handleExplicitFilterChange} type="radio" id="hide-explicit-content-mobile" name="hide" checked={this.renderExplicitContentFilterSelection('hide')} />
                                <label  htmlFor="hide-explicit-content-mobile">
                                    <svg id="checkmarkIcon" width="100" height="100" viewBox="0 -2 12.23 9.18">
                                        <path d="M12.17.87,5.11,7.93,1.36,4.18" transform="translate(-0.65 -0.16)"
                                              fill="none" strokeMiterlimit="10" strokeWidth="2"></path>
                                    </svg>
                                </label>
                            </div>
                            <label htmlFor="hide-explicit-content-mobile" className="nav-checkbox-label">Hide explicit
                                content</label>
                        </li>
                        <li className="not-link">
                            <div className="checkbox">
                                <input onChange={this.handleExplicitFilterChange} type="radio" id="obscure-explicit-content-mobile" name="obscure" checked={this.renderExplicitContentFilterSelection('obscure')} />
                                <label htmlFor="obscure-explicit-content-mobile">
                                    <svg id="checkmarkIcon" width="100" height="100" viewBox="0 -2 12.23 9.18">
                                        <path d="M12.17.87,5.11,7.93,1.36,4.18" transform="translate(-0.65 -0.16)"
                                              fill="none" strokeMiterlimit="10" strokeWidth="2"></path>
                                    </svg>
                                </label>
                            </div>
                            <label htmlFor="obscure-explicit-content-mobile" className="nav-checkbox-label">Obscure explicit content</label>
                        </li>
                        <li className="not-link">
                            <div className="checkbox">
                                <input onChange={this.handleExplicitFilterChange} type="radio" id="show-everything-mobile" name="show" checked={this.renderExplicitContentFilterSelection('show')} />
                                <label htmlFor="show-everything-mobile">
                                    <svg id="checkmarkIcon" width="100" height="100" viewBox="0 -2 12.23 9.18">
                                        <path d="M12.17.87,5.11,7.93,1.36,4.18" transform="translate(-0.65 -0.16)"
                                              fill="none" strokeMiterlimit="10" strokeWidth="2"></path>
                                    </svg>
                                </label>
                            </div>
                            <label htmlFor="show-everything-mobile" className="nav-checkbox-label">Show
                                everything</label>
                        </li>
                    </ul>
                </li>
                <li style={{"cursor": "default", "paddingBottom": "0"}}>
                    <a onClick={this.goTo} name="/my/page" href="/my/page">My Page</a>
                    <ul className="dropdown account-mobile">
                        <li><a onClick={this.goTo} name="/my/settings"  href="/my/settings">Settings</a></li>
                        <li><a onClick={this.goTo} name="/my/upload" href="/my/upload">Upload Content</a></li>
                        <li><a onClick={this.goTo} name="/my/content" href="/my/content">Manage Content</a></li>
                        <li><a onClick={this.goTo} name="/my/sales" href="/my/sales">Sales</a></li>
                        <li><a onClick={this.goTo} name="/my/purchases" href="/my/purchases">Purchases</a></li>
                        <li><a onClick={this.goTo} name="/my/following" href="/my/following">Following</a></li>
                        <li><a onClick={this.goTo} name="/my/followers" href="/my/followers">Followers</a></li>
                        <li><a className="heavy" href="/" onClick={this.handleLogout}>Logout</a></li>
                    </ul>
                </li>
            </ul>
        </div>;
    }

    render() {
        return this.props.isAuthenticated ? this.renderAuthenticatedNavigation() : this.renderNavigation();
    }
}

export default ResponsiveNavigation;
