class ScrollTracker {
    constructor(elementToTrack, reachedBottomCallback, callingComponent) {
        this.elementToTrack = elementToTrack;
        this.reachedBottomCallback = reachedBottomCallback;
        this.callingComponent = callingComponent;
        this.isListening = false;

        this.trackScrolling = this.trackScrolling.bind(this);
    }

    startTrackingScrolling() {
        if (!this.isListening) {
            this.isListening = true;
            document.addEventListener('scroll', this.trackScrolling);
        }
    }

    stopTrackingScrolling() {
        this.isListening = false;
        document.removeEventListener('scroll', this.trackScrolling);
    }

    isBottom(e) {
        let isBottom = false;
        try {
            isBottom = e.getBoundingClientRect().bottom <= window.innerHeight;
        } catch (e) {
            this.stopTrackingScrolling();
        }

        return isBottom;
    }

    trackScrolling() {
        const wrappedElement = document.getElementById(this.elementToTrack);

        if (this.isBottom(wrappedElement)) {
            this.stopTrackingScrolling();
            this.reachedBottomCallback.call(this.callingComponent);
        }
    }
}

export default ScrollTracker;
