import React from "react";

import SessionService from "../../services/session";

import Navigation from '../../components/navigation';
import Footer from '../../components/footer';
import ResponsiveMenu from '../../components/responsive-navigation';
import HelmetComponent from "../../components/helmet";

class SignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            username: '',
            password: '',
            isLoading: false,
            errorsInFields: [],
            errorMessage: '',
        };

        this.updateFormSate = this.updateFormSate.bind(this);
        this.signUp = this.signUp.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.shouldDisplayFormFieldError = this.shouldDisplayFormFieldError.bind(this);
    }

    updateFormSate(event) {
        this.clearErrors();
        this.setState({ [event.target.name]: event.target.value });
    }

    handleErrorResponse(errorPayload) {
        switch (errorPayload.code) {
            case "InvalidParameterException":
                this.handleInvalidParameterException(errorPayload);
                break;
            case "UsernameExistsException":
                this.handleUsernameExistsException();
                break;
            default:
                this.handleNicknameExistsException();
                break;
        }
    }

    handleInvalidParameterException(errorPayload) {
        if (errorPayload.message.includes("password")) {
            this.setState({errorsInFields: ["password"], errorMessage: "Your password should contain at least 6 characters."});
        } else if (errorPayload.message.includes("email")) {
            this.setState({errorsInFields: ["email"], errorMessage: "Your e-mail is invalid."});
        }
    }

    handleUsernameExistsException() {
        this.setState({errorsInFields: ["email"], errorMessage: "This e-mail already exists. Please use it to Sign In."});
    }

    handleNicknameExistsException() {
        this.setState({errorsInFields: ["username"], errorMessage: "This username already exists. Please pick a different one."});
    }

    clearErrors() {
        this.setState({errorsInFields: [], errorMessage: '', isLoading: false});
    }

    shouldDisplayFormFieldError(formFieldName) {
        return this.state.errorsInFields.includes(formFieldName) ? "form-control invalid" : "form-control";
    }

    async signUp() {
        if (!this.canSignUp()) {
            return
        }

        this.setState({ isLoading: true }, async () => {
            try {
                await SessionService.signUp(this.state.email, this.state.password, this.state.username);

                await SessionService.signIn(this.state.email, this.state.password, true);

                this.clearErrors();
                this.props.navigation.myPage();
            }
            catch (err) {
                this.handleErrorResponse(err);
                this.setState({ isLoading: false });
            }
        });
    }

    canSignUp() {
        return this.state.email && this.state.password && this.state.username;
    }

    submitForm(event) {
        event.preventDefault();
    }

    renderNavigation() {
        return <Navigation isAuthenticated={false} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer/>
    }

    renderRenderResponsiveMenu() {
        return <ResponsiveMenu isAuthenticated={false} navigation={this.props.navigation} />
    }

    renderSignUp() {
        return <div className="signup-background cover">
            <header>
                <div className="nav-container nav-home">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            <section>
                <div className="container">
                    <form className="form-signin" onSubmit={this.submitForm} action="#">
                        <p className="signupfree">Sign up, it's free</p>
                        <input id="emailInput" name="email" onChange={this.updateFormSate} type="email" className={this.shouldDisplayFormFieldError('email')} placeholder="Email" required />
                        {/*<input id="emailInput" type="email" className="form-control invalid" placeholder="Email" required />*/}
                        <input id="passwordInput" name="password" onChange={this.updateFormSate} type="password" className={this.shouldDisplayFormFieldError('password')} placeholder="Password" required />
                        <input id="usernameInput" name="username" onChange={this.updateFormSate} type="text" className={this.shouldDisplayFormFieldError('username')} placeholder="Username" required />

                        <div id="SignupMessage" className={this.state.errorMessage ? "signup-message" : "hide"}>{this.state.errorMessage ? this.state.errorMessage : ''}</div>

                        <br />
                        <button id="SignupButton" disabled={this.state.isLoading ? "disabled" : ""} onClick={this.signUp} className={this.state.isLoading ? "btn--gray button-loading-border" : "btn--blueGr"}>Sign up</button>
                        <p className="terms">By clicking on Sign Up you agree to
                            our <a href="/tos">terms of
                                use</a> and <a href="/privacy">privacy
                                policy</a></p>
                    </form>
                </div>
            </section>
            {this.renderFooter()}
            {this.renderRenderResponsiveMenu()}
            <HelmetComponent title='Sign Up' description='Sign Up to OpenSwap.io.' />
        </div>;
    }

    render() {
        return this.renderSignUp()
    }
}

export default SignUp;
