import { history } from '../store';
import ReactGA from 'react-ga4';

let NavigationService = {
    exposeHistory() {
        return history;
    },

    featured() {
        history.push('/featured/packages');
    },

    featuredPosts() {
        history.push('/featured/posts');
    },

    featuredPackages() {
        history.push('/featured/packages');
    },

    myPage() {
        history.push('/my/page');
    },

    userPage(publicName) {
        history.push(`/creator/${publicName}`);
    },

    packagePage(packageId, replace = false) {
        replace ? history.replace(`/packages/${packageId}`) : history.push(`/packages/${packageId}`);
    },

    packageRoute(packageAlias) {
        history.push(`/packages/route/${packageAlias}`);
    },

    purchaseForPackageId(packageId) {
        history.push(`/purchases/${packageId}`);
    },

    purchasedPackage(packageId, replace = false) {
        replace ? history.replace(`/packages/purchased/${packageId}`) : history.push(`/packages/purchased/${packageId}`);
    },

    packagesForTag(tag) {
        history.push(`/packages/tag/${tag}`);
    },

    signIn() {
        history.push('/signin');
    },

    signUp() {
        history.push('/signup');
    },

    faq() {
        history.push('/faq');
    },

    terms() {
        history.push('/tos');
    },

    privacy() {
        history.push('/privacy');
    },

    copyright() {
        history.push('/copyright');
    },

    settings() {
        history.push('/my/settings');
    },

    upload() {
        history.push('/my/upload');
    },

    packageClone(packageId) {
        history.push(`/my/upload?packageId=${packageId}`);
    },

    packageEdit(packageId) {
        history.push(`/my/upload?packageId=${packageId}&isEditing=1`);
    },

    manageContent() {
        history.push('/my/content');
    },

    sales() {
        history.push('/my/sales');
    },

    purchases() {
        history.push('/my/purchases');
    },

    following() {
        history.push('/my/following');
    },

    followers() {
        history.push('/my/followers');
    },

    home() {
        history.push('/');
    },

    googleAnalytics() {
        ReactGA.initialize('G-KC7B5YEPZM');
        ReactGA.send('pageview');

        // Page view tracking
        history.listen(location => {
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        });
    },

    setUserInGoogleAnalytics(userId) {
        try {
            ReactGA.set({userId: userId});
        } catch (e) {
            console.log("Failed to set user Id in Google Analytics");
        }
    }
};

export default NavigationService;

