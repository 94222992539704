const PACKAGE_UPLOAD_ERROR_STATE = {
    UNSUPPORTED_FILE: 'unsupported_file',
    PACKAGE_TOO_BIG: 'package_too_big',
    NO_BTC_ADDRESS: 'no_btc_address',
    FILE_UPLOADING: 'files_uploading',
    FILES_PROCESSING: 'files_processing',
    NO_FILES: 'no_files',
    NO_PREVIEWS: 'no_previews',
    WRONG_IMAGE_TYPE: 'wrong_image_type',
    FILE_TOO_BIG: 'file_too_big',
    PRICE_TOO_LOW: 'price_too_low',
    PACKAGE_QTY_TOO_HIGH: 'PACKAGE_QTY_TOO_HIGH',
    PACKAGE_PREVIEW_QTY_TOO_HIGH: 'PACKAGE_PREVIEW_QTY_TOO_HIGH',
    DUPLICATE_FILES: 'DUPLICATE_FILES',
    CONNECTION_ERROR: 'CONNECTION_ERROR',
    INVALID_TAGS: 'INVALID_TAGS',
    INVALID_TAG_CHARACTERS: 'INVALID_TAG_CHARACTERS',
    TOO_MANY_TAGS: 'TOO_MANY_TAGS',
    UPLOAD_RETRY_EXCEEDED: 'UPLOAD_RETRY_EXCEEDED',
    UNKNOWN: 'unknown',
};

export default PACKAGE_UPLOAD_ERROR_STATE;
