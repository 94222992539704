import React from "react";
import PreferenceService from "../../services/preference";
import EnvironmentService from "../../services/environment";
import PackageCard from "../../components/package-card";
import Navigation from "../../components/navigation";
import ResponsiveNavigation from "../../components/responsive-navigation";
import Footer from "../../components/footer";
import LoadingIndicator from "../../components/loading-indicator";
import PackageService from "../../services/package";
import HelmetComponent from "../../components/helmet";
import PriceResolver from "../../services/price-resolver";

class PackageList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paging: '',
            packages: [],
            isLoadingMore: false,
            isLoading: true,
        };

        this.handlePackageSuccess = this.handlePackageSuccess.bind(this);
        this.handlePackageFailure = this.handlePackageFailure.bind(this);
        this.fetchPackages = this.fetchPackages.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        PreferenceService.subscribe('explicitFilterStateChange', this.handleFilterChange);

        this.fetchPackages();
    }

    componentWillUnmount() {
        PreferenceService.unsubscribe('explicitFilterStateChange', this.handleFilterChange);
    }

    fetchPackages(nextKey) {
        PackageService.fetchPackagesForTag(this.retrievePackageTag(), nextKey, 25).then(response => this.handlePackageSuccess(response.data.packages, response.data.paging)).catch(error => this.handlePackageFailure(error));
    }

    loadMore() {
        this.setState({isLoadingMore: true});
        this.fetchPackages(this.state.paging.nextKey);
    }

    handlePackageSuccess(packages, paging) {
        this.setState((prevState) => ({packages: prevState.packages.concat(packages), paging: paging, isLoading: false, isLoadingMore: false}));
    }

    handlePackageFailure(error) {
        this.setState({packages: [], paging: {}, isLoading: false, isLoadingMore: false});
    }

    handleFilterChange() {
        this.setState({isLoading: true, packages: [], paging: ''}, () => {
            this.fetchPackages();
        });
    }

    retrievePackageTag() {
        let packagePayload = this.props.location.pathname.split("/");
        return (packagePayload && packagePayload[2]) ? packagePayload[3] : null;
    }

    canLoadMore() {
        return !!this.state.paging.nextKey;
    }

    hasPackages() {
        return this.state.packages.length > 0;
    }

    isLoading() {
        return this.state.isLoading;
    }

    isLoadingMore() {
        return this.state.isLoadingMore;
    }

    renderPackageImage(s3Key) {
        return `${EnvironmentService.staticURL()}/${s3Key}`;
    }

    renderPackages() {
        return this.state.packages.map((featuredPackage) => {
            return (<div key={featuredPackage._id} className="col-md-3">
                <PackageCard key={featuredPackage._id} navigation={this.props.navigation} packageOwner={featuredPackage.owner_id} packageAlias={featuredPackage.alias} packageId={featuredPackage._id} shouldBlur={PreferenceService.getExplicitContentFilter() === 'obscure' && featuredPackage.is_adult} username={featuredPackage.owner_public_name} packageImage={this.renderPackageImage(featuredPackage.previews[0].thumb_uri)} packageName={featuredPackage.name} price={PriceResolver.resolvePriceForPackage(featuredPackage)} quantity={featuredPackage.content_qty} packageTag={featuredPackage.tags[Math.floor(Math.random() * featuredPackage.tags.length)]}/>
            </div>);
        })
    }

    renderNoPackageLabel() {
        return <div className='col-md-3'><h4>No packages found...</h4></div>;
    }

    renderLoadMoreButton() {
        return <div className="row top-padding"><button onClick={this.loadMore} id="SignupButton" className={this.state.isLoadingMore ? "btn--gray button-loading-border margin-center" : "btn--blueGr margin-center"} style={{"maxWidth": "300px"}}>Load More...</button></div>
    }

    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer />
    }

    renderContent() {
        return <section className="featured">
            <h3>Packages for {this.retrievePackageTag()}</h3>
            <div className="container">
                <div className="row">
                    {this.hasPackages() ? this.renderPackages() : this.renderNoPackageLabel()}
                </div>
                {this.canLoadMore() && this.renderLoadMoreButton()}
            </div>
        </section>;
    }

    renderLoading() {
        return <LoadingIndicator/>;
    }

    renderPackageList() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            {this.isLoading() ? this.renderLoading() : this.renderContent()}
            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            <HelmetComponent title={`Tag - ${this.retrievePackageTag()}`} description={`OpenSwap.io packages that have the ${this.retrievePackageTag()} tag.`} />
        </div>
    }

    render() {
        return this.renderPackageList();
    }
}

export default PackageList;
