/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendChatMessage = /* GraphQL */ `
  mutation SendChatMessage($text: String!, $recipientSub: String!) {
    sendChatMessage(text: $text, recipientSub: $recipientSub) {
      id
      inboxId
      senderSub
      recipientSub
      owner
      createdAt
      s
      t
    }
  }
`;
export const createSocialPost = /* GraphQL */ `
  mutation CreateSocialPost(
    $text: String
    $assetPath: String!
    $isAdult: Boolean!
    $price: PostPrice
  ) {
    createSocialPost(
      text: $text
      assetPath: $assetPath
      isAdult: $isAdult
      price: $price
    ) {
      _id
      author {
        publicName
        _id
      }
      dateCreated
      text
      price
      isAdult
      currency
      assetPath
      license
      likeCount
      deleted
    }
  }
`;
export const deleteSocialPost = /* GraphQL */ `
  mutation DeleteSocialPost($postId: ID!) {
    deleteSocialPost(postId: $postId) {
      _id
      author {
        publicName
        _id
      }
      dateCreated
      text
      price
      isAdult
      currency
      assetPath
      license
      likeCount
      deleted
    }
  }
`;
export const replyToSocialPost = /* GraphQL */ `
  mutation ReplyToSocialPost($postId: ID!, $reply: String!) {
    replyToSocialPost(postId: $postId, reply: $reply) {
      _id
      author {
        publicName
        _id
      }
      dateCreated
      text
      price
      isAdult
      currency
      assetPath
      license
      likeCount
      deleted
    }
  }
`;
export const deleteReplyFromSocialPost = /* GraphQL */ `
  mutation DeleteReplyFromSocialPost($postId: ID!, $reply: String!) {
    deleteReplyFromSocialPost(postId: $postId, reply: $reply) {
      _id
      author {
        publicName
        _id
      }
      dateCreated
      text
      price
      isAdult
      currency
      assetPath
      license
      likeCount
      deleted
    }
  }
`;
export const likeSocialPost = /* GraphQL */ `
  mutation LikeSocialPost($postId: ID!) {
    likeSocialPost(postId: $postId) {
      _id
      author {
        publicName
        _id
      }
      dateCreated
      text
      price
      isAdult
      currency
      assetPath
      license
      likeCount
      deleted
    }
  }
`;
export const unlikeSocialPost = /* GraphQL */ `
  mutation UnlikeSocialPost($likeId: ID!) {
    unlikeSocialPost(likeId: $likeId) {
      _id
      author {
        publicName
        _id
      }
      dateCreated
      text
      price
      isAdult
      currency
      assetPath
      license
      likeCount
      deleted
    }
  }
`;
export const purchaseSocialPost = /* GraphQL */ `
  mutation PurchaseSocialPost($postId: ID!) {
    purchaseSocialPost(postId: $postId)
  }
`;
