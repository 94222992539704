import EnvironmentService from "./environment";
import axios from "axios";
import Authorization from "./authorization";

const api = axios.create({
    baseURL: `${EnvironmentService.baseURL()}/api`,
    timeout: 25000,
    responseType: "json"
});

class PurchaseService {
    static async fetchMyPurchases() {
        let path = '/purchases';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true,
        };

        return await api.get(path, {headers: myInit["headers"]});
    }

    static async fetchPurchaseByPackage(packageId) {
        let path = `/purchases`;
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true,
            queryStringParameters: {},
        };

        myInit['queryStringParameters'] = {
            'filterAttribute[package._id]': packageId,
        };

        return await api.get(path, {params: myInit['queryStringParameters'], headers: myInit["headers"]});
    }

    static async createPurchase(packageId, purchaserUsername) {
        let path = '/purchases' +
            '/';
        let myInit = {
            headers: {...(await Authorization.authorizationHeader())},
            response: true,
            body: {
                package: {
                    _id: packageId
                },
                username: purchaserUsername,
            }
        };

        return await api.post(path, myInit['body'], {headers: myInit["headers"]});
    }
}

export default PurchaseService;
