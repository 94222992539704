import React from "react";

import PreferenceService from "../../services/preference";
import PostService from "../../services/post";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import LoadingIndicator from "../../components/loading-indicator";
import SelectionModal from "../../components/modal/selection";
import ResponsiveNavigation from "../../components/responsive-navigation";
import HelmetComponent from "../../components/helmet";
import PostList from "../../components/post-list";

class FeaturedPosts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            postsPaging: '',
            featuredPosts: [],
            isLoadingMore: false,
            isLoading: true,
            showAdultWarningModal: false,
        };

        this.fetchPosts = this.fetchPosts.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleAdultWarningModalYesClick = this.handleAdultWarningModalYesClick.bind(this);
        this.handleAdultWarningModalNoClick = this.handleAdultWarningModalNoClick.bind(this);
        this.handleAdultWarningModalClose = this.handleAdultWarningModalClose.bind(this);
        this.attemptLoadMore = this.attemptLoadMore.bind(this);
        this.canLoadMore = this.canLoadMore.bind(this);
    }

    componentDidMount() {
        PreferenceService.subscribe('explicitFilterStateChange', this.handleFilterChange);

        this.displayAdultWarningIfNeeded();

        this.fetchPosts();
    }

    componentWillUnmount() {
        PreferenceService.unsubscribe('explicitFilterStateChange', this.handleFilterChange);
    }

    fetchPosts(includeNextKey = false) {
        this.fetchNewPosts(includeNextKey ? this.state.postsPaging.nextKey : null, 25);
    }

    fetchNewPosts(nextKey, limit) {
        PostService.fetchPosts(nextKey, limit).then(response => this.handlePostsSuccess(response.data.posts, response.data.paging)).catch(error => this.handlePostsFailure(error));
    }

    loadMore() {
        this.fetchPosts(true);
    }

    isLoading() {
        return this.state.isLoading;
    }

    displayAdultWarningIfNeeded() {
        if (!PreferenceService.isExplicitContentFilterSet()) {
            this.setState({showAdultWarningModal: true});
        }
    }

    handleFilterChange() {
        this.setState({isLoading: true, featuredPosts: [], postsPaging: ''}, () => {
            this.fetchPosts();
        });
    }

    handlePostsSuccess(posts, paging) {
        this.setState((prevStats) => ({featuredPosts: prevStats.featuredPosts.concat(posts), postsPaging: paging, isLoading: false}));
    }

    handlePostsFailure(error) {
        if (error?.response?.status === 404) {
            this.setState({featuredPosts: [], isLoading: false});
        } else {
            setTimeout(this.fetchPosts, 1000);
        }
    }

    handleAdultWarningModalClose() {
        this.setState({showAdultWarningModal: false})
    }

    handleAdultWarningModalNoClick() {
        PreferenceService.setExplicitContentFilter('hide');

        this.handleAdultWarningModalClose();
    }

    handleAdultWarningModalYesClick() {
        PreferenceService.setExplicitContentFilter('show');
        this.handleAdultWarningModalClose();
    }

    canLoadMore() {
        return this.state.postsPaging?.nextKey;
    }

    isLoggedIn() {
        return !!this.props.currentUser;
    }

    attemptLoadMore() {
        if (this.canLoadMore()) {
            this.loadMore();
        }
    }

    renderNavigation() {
        return <Navigation isAuthenticated={this.isLoggedIn()} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={this.isLoggedIn()} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer />
    }

    renderPosts() {
        return <section className="featured">
            <h3>Featured Posts</h3>
            <div className="container posts" id="container">
                <div className="row posts-row">
                    <PostList posts={this.state.featuredPosts} navigation={this.props.navigation} loadMore={this.attemptLoadMore} />
                </div>
            </div>
        </section>;

    }

    renderLoading() {
        return <LoadingIndicator/>;
    }

    renderModal() {
        return <SelectionModal isActive={this.state.showAdultWarningModal} handleClose={this.handleAdultWarningModalClose} handleNoClick={this.handleAdultWarningModalNoClick} handleYesClick={this.handleAdultWarningModalYesClick} title="Warning! 18+ Material" message="This site contains Adult material. Would you like to see it or filter it out?" />
    }

    renderFeaturedPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            {this.isLoading() ? this.renderLoading() : this.renderPosts()}
            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            {this.renderModal()}
            <HelmetComponent title='Featured Posts' description='OpenSwap.io currently featured posts.' />
        </div>
    }

    render() {
        return this.renderFeaturedPage();
    }
}

export default FeaturedPosts;
