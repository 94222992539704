import React from 'react';

import CDNImage from "../../components/cdn-image";
import S3Image from "../../components/s3-image";

const openSwapLogo = require('../../images/open-swap-ico.svg');
const avatarKey = "avatar.jpg";

class AvatarImage extends React.Component {

    determineBaseStyleClass() {
        if (this.props.size === 'small') {
            return 'avatarImage-small';
        }

        return 'avatarImage-large';
    }

    renderCDNImage() {
        return <CDNImage {...this.props} className={this.props.extraClassNames ? `${this.determineBaseStyleClass()} ${this.props.extraClassNames}` : this.determineBaseStyleClass()} defaultImage={openSwapLogo} identityId={this.props.identityId} imageKey={avatarKey}  />
    }

    renderS3Image() {
        return <S3Image s3key={avatarKey} {...this.props} className={this.props.extraClassNames ? `${this.determineBaseStyleClass()} ${this.props.extraClassNames}` : this.determineBaseStyleClass()} defaultImage={openSwapLogo} identityId={this.props.identityId}  />
    }

    renderImage() {
        return this.props.loadFromStorage ? this.renderS3Image() : this.renderCDNImage();
    }

    render() {
        return this.renderImage();
    }
}

export default AvatarImage;
