import React from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import {Storage} from "aws-amplify";
import * as Sentry from "@sentry/react";
import PostService from "../../services/post";
const imageSettingsIcon = require('../../images/image-settings.svg');

class CreatePost extends React.Component {
    constructor(props) {
        super(props);

        this.dropTargetReference = React.createRef();
        this.newFiles = [];
        this.fileUrls = [];
        this.urlCreator = window.URL || window.webkitURL;
        this.contents = [];

        this.state = {
            caption: '',
            price: '',
            files: [],
            isDragging: false,
            isUploading: false,
        };

        this.closeClick = this.closeClick.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.handleDataChange = this.handleDataChange.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDragIn = this.handleDragIn.bind(this);
        this.handleDragOut = this.handleDragOut.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleClickToUpload = this.handleClickToUpload.bind(this);
    }

    componentDidMount() {
        const dropTargetElement = this.dropTargetReference.current;
        dropTargetElement.addEventListener('dragenter', this.handleDragIn);
        dropTargetElement.addEventListener('dragleave', this.handleDragOut);
        dropTargetElement.addEventListener('dragend', this.handleDragOut);
        dropTargetElement.addEventListener('dragover', this.handleDrag);
        dropTargetElement.addEventListener('drop', this.handleDrop);
    }

    componentWillUnmount() {
        const dropTargetElement = this.dropTargetReference.current;
        dropTargetElement.removeEventListener('dragenter', this.handleDragIn);
        dropTargetElement.removeEventListener('dragleave', this.handleDragOut);
        dropTargetElement.removeEventListener('dragend', this.handleDragOut);
        dropTargetElement.removeEventListener('dragover', this.handleDrag);
        dropTargetElement.removeEventListener('drop', this.handleDrop);
    }

    closeClick() {
        this.setState({price: '', caption: '', files: []});
        this.newFiles = [];
        this.fileUrls = [];
        this.props.handleClose();
    }

    async saveClick() {
        /* TODO
        build Post API creation payload (caption, S3 key of asset, price)
        send request
        if success -> clear state -> close dialog
        if failed -> displayed error
         */
        try {
            await PostService.savePost(this.state.caption, this.contents[0].uri, false);
            this.props.handlePostCreationSuccess();
        } catch (error) {
            console.log(error);
        }
    }

    handleDrag(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({isDragging: true});
    }

    handleDragIn(event) {
        event.preventDefault();

        this.setState({isDragging: true});
    }

    handleDragOut(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({isDragging: false});
    }

    handleDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({isDragging: false});

        if (!this.canAddFilesForUpload()) {
            return;
        }

        this.beginUploadProcess([...event.dataTransfer.files]);
    }

    canAddFilesForUpload() {
        return true;
    }

    isImage(type) {
        return type.includes('image');
    }

    handleClickToUpload(event) {
        if (this.canAddFilesForUpload()) {
            this.beginUploadProcess([...event.target.files]);
        }
    }

    beginUploadProcess(files) {
        this.newFiles = files;

        if (this.newFiles.length === 0 || this.newFiles.length > 1) {
            return;
        }

        this.setState({isUploading: true});

        this.generateBlobs();
    }

    generateBlobs() {
        for (let i = 0; i < this.newFiles.length; i++) {
            if (this.isImage(this.newFiles[i].type)) {
                const reader = new FileReader();

                reader.onload = (event) => {
                    let fileBlob = new Blob([new Uint8Array(event.target.result)], {type: this.newFiles[i].type});

                    this.fileUrls.push({name: this.newFiles[i].name, content: this.urlCreator.createObjectURL(fileBlob), type: this.newFiles[i].type, progress: 0});

                    if (this.fileUrls.length === this.newFiles.length) {
                        this.setState({files: this.fileUrls});
                        this.uploadFiles();
                    }
                };

                reader.readAsArrayBuffer(this.newFiles[i]);
            }
        }
    }

    async uploadFiles() {
        const componentContext = this;

        for (let i = 0; i < this.newFiles.length; i++) {
            try {
                let key = this.newFiles[i].name;
                let result = await Storage.put(key, this.newFiles[i], {
                    level: 'private',
                    contentType: this.newFiles[i].type,
                    useAccelerateEndpoint: true,
                });

                this.handleSuccessfulFileUpload(result);
                this.setState({isUploading: false});
            } catch (error) {
                await this.handleFailedFileUpload(error, this.newFiles[i], componentContext);
            }
        }

        this.newFiles = [];
    }

    canSave() {
        return this.state.inputData !== '' && this.contents.length > 0 && !this.state.isUploading;
    }

    handleDataChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSuccessfulFileUpload(key) {
        const fullKey = `private/${this.props.currentUser.userId}/${key.key}`;
        this.contents.push({uri: fullKey});
    }

    async handleFailedFileUpload(error, failedFileBlob) {
        console.log(error);
        Sentry.addBreadcrumb({
            category: "upload",
            message: "Upload failed for file " + failedFileBlob && failedFileBlob.name ? failedFileBlob.name : 'undefined',
            level: "info",
        });
    }

    renderThumbnails() {
        return this.state.files.sort((file1, file2) => file1.name > file2.name ? 1 : -1).map((thumbnail, index) => {
            return <div onClick={() => this.handlePreviewsChange(thumbnail.name, thumbnail.type)} key={index} className={this.renderThumbnailState(thumbnail.name)}>
                <img alt='Package Item Settings' className='content-preview-item-delete' src={imageSettingsIcon} name={thumbnail.name} onClick={this.handleItemSettings} />
                {(thumbnail.progress < 100) && <CircularProgressbar styles={this.renderUploadProgressIndicatorStyles()} className="upload-loading-indicator" value={thumbnail.progress} /> }
                <img alt='' key={index} src={thumbnail.content} className="" />
                <br/>
                <span>{thumbnail.name}</span>
            </div>
        });
    }

    renderEmptyUploadContainer() {
        return <div className="box__input">
            <svg className="box__icon post-card-upload-box-icon" xmlns="http://www.w3.org/2000/svg" width="50"
                 height="43" viewBox="0 0 50 43">
                <path
                    d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
            </svg>
            <input onChange={this.handleClickToUpload} type="file" name="files" id="files" className="box__file" multiple />
            <label className='hidden-on-mobile' htmlFor="files">Drop your pic here or <div className='upload-container-partial-text'><strong>click to upload</strong></div></label>
            <label className='hidden-on-desktop' htmlFor="files"><div className='upload-container-partial-text'><strong>Tap to upload</strong></div></label>
        </div>;
    }

    renderUploadComponent() {
        return <div className="upload-container upload">
            <div ref={this.dropTargetReference} id="upload-files"  className={this.state.isDragging ? "upload-post  box has-advanced-upload is-dragover" : "upload-post box has-advanced-upload"}>
                {this.state.files.length === 0 ? this.renderEmptyUploadContainer() : this.renderThumbnails()}
            </div>
        </div>
    }

    renderPriceInput() {
        return <div className='settings'>
            <div className="upload-container user-avatar form-signin post-card-upload-price">
                <div className="category-title">Price</div>
                <input onChange={this.handleDataChange} id="packagePrice" type="number" name="price" value={this.state.price} step={0.0000001} className="form-control" placeholder='Price in USD' required />
                <br />
            </div>
        </div>
    }

    renderThumbnails() {
        return this.state.files.sort((file1, file2) => file1.name > file2.name ? 1 : -1).map((thumbnail, index) => {
            return <div key={index} className='content-preview-item content-preview-post-item'>
                <img alt='' key={index} src={thumbnail.content} />
            </div>
        });
    }

    renderModal() {
        return <div className={this.props.isActive ? "modalHolder fadeIn" : "modalHolder fadeOut"}>
            <div onClick={() => this.closeClick()} className="popupBG"></div>
            <div className={this.props.isActive ? "modal modal--sample active fadeIn" : "modal modal--sample fadeOut"}>
                <button onClick={this.closeClick} className="modal__close">
                    <svg viewBox="0 0 100 100" fill="#5d80a2">
                        <use xlinkHref="#closeIcon">
                        </use>
                    </svg>
                </button>
                <div className="modal__title">{this.props.title}</div>
                <div className="modal__content modal__content--white">
                    <p>
                        <textarea onChange={this.handleDataChange} id="inputData" value={this.state.inputData} name="caption" className="form-control post-card-upload-caption" placeholder='Share something with your fans...' rows="3"/>
                    </p>
                    {this.renderUploadComponent()}
                    {/*{this.renderPriceInput()}*/}
                </div>
                <div className="input-modal-controls">
                    <button disabled={!this.canSave()} onClick={this.saveClick} className={this.canSave() ? 'btn--blueGr' : 'btn--gray'}>Post</button>
                </div>
            </div>
        </div>;
    }

    render() {
        return this.renderModal();
    }
}

export default CreatePost;
