import React from "react";

import PurchaseService from "../../services/purchase";
import LoadingIndicator from "../../components/loading-indicator";

import PurchaseRow from './purchaseRow'
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveNavigation from "../../components/responsive-navigation";
import HelmetComponent from "../../components/helmet";

class Purchases extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            purchases: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        this.fetchMyPurchases();
    }

    fetchMyPurchases() {
        this.setState({isLoading: true});
        PurchaseService.fetchMyPurchases().then(response => this.handleSuccessfulPurchaseFetch(response.data.purchases)).catch(error => this.handleFailedPurchasesFetch(error));
    }

    handleSuccessfulPurchaseFetch(purchases) {
        this.setState({purchases: this.sortPurchases(purchases), isLoading: false});
    }

    handleFailedPurchasesFetch() {
        this.setState({purchases: [], isLoading: false});
    }

    sortPurchases(purchases) {
        return purchases.sort((a, b) => {
            return b.date_created - a.date_created;
        });
    }

    timestampToDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleDateString("en-US");
    }

    renderPurchasesHeader() {
        return <tr>
            <td className="w20">&nbsp;</td>
            <td>
                #
            </td>
            <td>
                Name
            </td>
            <td>
                Amount
            </td>
            <td>
                Currency
            </td>
            <td>
                Date
            </td>
            <td className="w20">&nbsp;</td>
        </tr>;
    }

    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderPurchasesDetails() {
        return <>
            <table className="transactionsTable table-center" border="0" cellPadding="0" cellSpacing="0" width="100%">
                <tbody>
                {this.renderPurchasesHeader()}
                {this.state.purchases.map((purchase, index) => <PurchaseRow key={index} position={index + 1} navigation={this.props.navigation} packageAlias={purchase.package.alias} purchaseId={purchase._id} purchaseStatus={purchase.status} packageId={purchase.package._id} packageName={purchase.package.name} price={purchase.package.price} currency={"BTC"} date={this.timestampToDate(purchase.date_created)} />)}
                </tbody>
            </table>
            </>;
    }

    renderNoPurchasesLabel() {
        return <div className='row'><div className='col td-center'>You haven't made any purchases</div></div>
    }

    renderPurchasesContent() {
        return <section className="purchases">
            <div className="container">
                <div className="row">
                    <div className="col" style={{"padding": "0"}}>

                        <h2>Purchases</h2>

                        {this.state.purchases.length > 0 ? this.renderPurchasesDetails() : this.renderNoPurchasesLabel()}

                    </div>
                </div>
            </div>
        </section>
    }

    renderPurchasesPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            {this.state.isLoading ? <LoadingIndicator/> : this.renderPurchasesContent()}
            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            <HelmetComponent title='Your Purchases' description='OpenSwap.io purchases page.' />
        </div>;
    }

    render() {
        return this.renderPurchasesPage();
    }
}

export default Purchases;
