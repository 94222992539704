import React from 'react';

import SessionService from "../../services/session";
import PreferenceService from "../../services/preference";

import NavigationEmitter from '../../emitters/navigation';

import CreatePost from '../create-post'

const headerLogo = require('../../images/logo.svg');
const saveBrowsingChevron = require('../../images/fa-angle-down.svg');

class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mobileMenuVisible: false,
            explicitContentFilter: PreferenceService.getExplicitContentFilter(),
            isCreatingPost: false,
        };

        this.handleMenuToggle = this.handleMenuToggle.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleExplicitFilterChange = this.handleExplicitFilterChange.bind(this);
        this.handleExternalExplicitFilterChange = this.handleExternalExplicitFilterChange.bind(this);
        this.renderExplicitContentFilterSelection = this.renderExplicitContentFilterSelection.bind(this);
        this.goTo = this.goTo.bind(this);
        this.openPostCreation = this.openPostCreation.bind(this);
        this.closePostCreation = this.closePostCreation.bind(this);
        this.postCreationSuccess = this.postCreationSuccess.bind(this);
    }

    componentDidMount() {
        PreferenceService.subscribe('explicitFilterStateChange', this.handleExternalExplicitFilterChange);
    }

    componentWillUnmount() {
        PreferenceService.unsubscribe('explicitFilterStateChange', this.handleExternalExplicitFilterChange);
    }

    openPostCreation(event) {
        event.preventDefault();
        this.setState({isCreatingPost: true});
    }

    closePostCreation() {
        this.setState({isCreatingPost: false});
    }

    postCreationSuccess() {
        this.closePostCreation();
        const navigation = this.props.navigation;
        navigation.myPage();
    }

    goTo(event) {
        const navigation = this.props.navigation;

        event.preventDefault();
        switch(event.target.name) {
            case '/my/settings':
                navigation.settings();
                break;
            case '/my/upload':
                navigation.upload();
                break;
            case '/my/content':
                navigation.manageContent();
                break;
            case '/my/sales':
                navigation.sales();
                break;
            case '/my/purchases':
                navigation.purchases();
                break;
            case '/my/following':
                navigation.following();
                break;
            case '/my/followers':
                navigation.followers();
                break;
            case '/my/page':
                navigation.myPage();
                break;
            case '/featured/packages':
                navigation.featuredPackages();
                break;
            case '/featured/posts':
                navigation.featuredPosts();
                break;
            case '/signin':
                navigation.signIn();
                break;
            case '/signup':
                navigation.signUp();
                break;
            case '/faq':
                navigation.faq();
                break;
            default:
                navigation.home();
        }
    }

    handleMenuToggle() {
        NavigationEmitter.mobileNavMenuToggle(this.state.mobileMenuVisible);
        this.setState((prevState) => ({ mobileMenuVisible: !prevState.mobileMenuVisible }));
    }

    handleExplicitFilterChange(event) {
        PreferenceService.setExplicitContentFilter(event.target.id);
        this.setState({explicitContentFilter: event.target.id});
    }

    handleExternalExplicitFilterChange() {
        this.setState({explicitContentFilter: PreferenceService.getExplicitContentFilter()});
    }

    renderExplicitContentFilterSelection(inputName) {
        return this.state.explicitContentFilter === inputName;
    }

    async handleLogout(event) {
        event.preventDefault();
        await SessionService.signOut();
        this.props.navigation.home();
    }

    renderNavigation() {
        return <nav className="navigation">
            <div className="row">
                <div className="col-6 col-md-auto">
                    <a onClick={this.goTo} className="logo" href="/"><img src={headerLogo} height="80px" alt="Logo" /></a>
                </div>
                <div className=" d-none d-md-block">
                    <ul className="navigation__menu d-none d-md-block">
                        <li><a onClick={this.goTo} name="/featured/posts" href="/featured/posts">Posts</a></li>
                        <li><a onClick={this.goTo} name="/featured/packages" href="/featured/packages">Packages</a></li>
                    </ul>
                </div>
                <div className="col-6 col-md-auto">
                    <div className="navigation__responsiveBtn d-block d-md-none">
                        <div onClick={this.handleMenuToggle} className={this.state.mobileMenuVisible ? "hamburger hamburger--slider is-active" : "hamburger hamburger--slider"}>
                            <div className="hamburger-box">
                                <div className="hamburger-inner"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-auto ml-auto d-none d-md-block">
                    <div className="text-right">
                        <ul className="navigation__menu">
                            <li style={{"cursor": "default"}}>Safe browsing <img alt='' width="10" src={saveBrowsingChevron} />
                                <ul className="dropdown">
                                    <li className="not-link">
                                        <div className="checkbox">
                                            <input onChange={this.handleExplicitFilterChange} type="radio" id="hide" checked={this.renderExplicitContentFilterSelection('hide')} />
                                            <label htmlFor="hide">
                                                <svg className="safe-browsing-checkbox" id="checkmarkIcon" width="100" height="100"
                                                     viewBox="0 0 12.23 9.18">
                                                    <path d="M12.17.87,5.11,7.93,1.36,4.18"
                                                          transform="translate(-0.65 -0.16)" fill="none"
                                                          strokeMiterlimit="10" strokeWidth="2"></path>
                                                </svg>
                                            </label>
                                        </div>
                                        <label htmlFor="hide" className="nav-checkbox-label">Hide
                                            explicit content</label>
                                    </li>
                                    <li className="not-link">
                                        <div className="checkbox">
                                            <input onChange={this.handleExplicitFilterChange} type="radio" id="obscure" checked={this.renderExplicitContentFilterSelection('obscure')} />
                                            <label htmlFor="obscure">
                                                <svg className="safe-browsing-checkbox" id="checkmarkIcon" width="100" height="100"
                                                     viewBox="0 0 12.23 9.18">
                                                    <path d="M12.17.87,5.11,7.93,1.36,4.18"
                                                          transform="translate(-0.65 -0.16)" fill="none"
                                                          strokeMiterlimit="10" strokeWidth="2"></path>
                                                </svg>
                                            </label>
                                        </div>
                                        <label htmlFor="obscure" className="nav-checkbox-label">Obscure  explicit content</label>
                                    </li>
                                    <li className="not-link">
                                        <div className="checkbox">
                                            <input onChange={this.handleExplicitFilterChange} type="radio" id="show" checked={this.renderExplicitContentFilterSelection('show') } />
                                            <label htmlFor="show">
                                                <svg className="safe-browsing-checkbox" id="checkmarkIcon" width="100" height="100"
                                                     viewBox="0 0 12.23 9.18">
                                                    <path d="M12.17.87,5.11,7.93,1.36,4.18"
                                                          transform="translate(-0.65 -0.16)" fill="none"
                                                          strokeMiterlimit="10" strokeWidth="2"></path>
                                                </svg>
                                            </label>
                                        </div>
                                        <label htmlFor="show" className="nav-checkbox-label">Show everything</label>
                                    </li>
                                </ul>
                            </li>
                            <li><a onClick={this.goTo} name="/signin" href="/signin">Sign In</a></li>
                            <li><a onClick={this.goTo} name="/signup" href="/signup">Sign Up</a></li>
                            <li><a onClick={this.goTo} name="/faq" href="/faq">FAQ</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>;
    }

    renderAuthenticatedNavigation() {
        return <nav className="navigation">
            <div className="row">
                <div className="col-6 col-md-auto">
                    <a onClick={this.goTo} name="/my/page" className="logo" href="/my/page"><img name="/my/page" src={headerLogo} height="80px" alt="Logo"/></a>
                </div>
                <div className="col-6 col-md-auto">
                    <ul className="navigation__menu d-none d-md-block">
                        <li><a onClick={this.goTo} name="/featured/posts" href="/featured/posts">Posts</a></li>
                        <li><a onClick={this.goTo} name="/featured/packages" href="/featured/packages">Packages</a></li>
                    </ul>
                    <div className="navigation__responsiveBtn d-block d-md-none">
                        {/*<div className='post-create-button-mobile' onClick={this.openPostCreation}>Create</div>*/}
                        <div className='create-box' onClick={this.openPostCreation}>
                            <div className='create-box-inner-vertical'></div>
                            <div className='create-box-inner-horizontal'></div>
                        </div>
                        <div onClick={this.handleMenuToggle} className={this.state.mobileMenuVisible ? "hamburger hamburger--slider is-active" : "hamburger hamburger--slider"}>
                            <div className="hamburger-box">
                                <div className="hamburger-inner"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 ml-auto col-md-auto">
                    <ul className="navigation__menu d-none d-md-block">
                        <li>
                            <div className='font-weight-bold post-create-button'>Create <img alt='' width="10" src={saveBrowsingChevron} /></div>
                            <ul className="dropdown create">
                                <li className="not-link">
                                    <a onClick={this.openPostCreation} href="/create">New Post</a>
                                </li>
                                <li className="not-link">
                                    <a onClick={this.goTo} name="/my/upload" href="/my/upload">New Package</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="col-md-auto ml-auto d-none d-md-block">
                    <div className="text-right">
                        <ul className="navigation__menu">
                            <li style={{"cursor": "default"}}>Safe browsing <img alt='' width="10" src={saveBrowsingChevron} />
                                <ul className="dropdown safe-browsing">
                                    <li className="not-link">
                                        <div className="checkbox">
                                            <input onChange={this.handleExplicitFilterChange} type="radio" id="hide" checked={this.renderExplicitContentFilterSelection('hide')} />
                                            <label htmlFor="hide">
                                                <svg className="safe-browsing-checkbox" id="checkmarkIcon" width="100" height="100"
                                                     viewBox="0 0 12.23 9.18">
                                                    <path d="M12.17.87,5.11,7.93,1.36,4.18"
                                                          transform="translate(-0.65 -0.16)" fill="none"
                                                          strokeMiterlimit="10" strokeWidth="2"></path>
                                                </svg>
                                            </label>
                                        </div>
                                        <label htmlFor="hide" className="nav-checkbox-label">Hide
                                            explicit content</label>
                                    </li>
                                    <li className="not-link">
                                        <div className="checkbox">
                                            <input onChange={this.handleExplicitFilterChange} type="radio" id="obscure" checked={this.renderExplicitContentFilterSelection('obscure')} />
                                            <label htmlFor="obscure">
                                                <svg className="safe-browsing-checkbox" id="checkmarkIcon" width="100" height="100"
                                                     viewBox="0 0 12.23 9.18">
                                                    <path d="M12.17.87,5.11,7.93,1.36,4.18"
                                                          transform="translate(-0.65 -0.16)" fill="none"
                                                          strokeMiterlimit="10" strokeWidth="2"></path>
                                                </svg>
                                            </label>
                                        </div>
                                        <label htmlFor="obscure" className="nav-checkbox-label">Obscure  explicit content</label>
                                    </li>
                                    <li className="not-link">
                                        <div className="checkbox">
                                            <input onChange={this.handleExplicitFilterChange} type="radio" id="show" checked={this.renderExplicitContentFilterSelection('show') } />
                                            <label htmlFor="show">
                                                <svg className="safe-browsing-checkbox" id="checkmarkIcon" width="100" height="100"
                                                     viewBox="0 0 12.23 9.18">
                                                    <path d="M12.17.87,5.11,7.93,1.36,4.18"
                                                          transform="translate(-0.65 -0.16)" fill="none"
                                                          strokeMiterlimit="10" strokeWidth="2"></path>
                                                </svg>
                                            </label>
                                        </div>
                                        <label htmlFor="show" className="nav-checkbox-label">Show everything</label>
                                    </li>
                                </ul>
                            </li>
                            <li style={{"cursor": "default"}}><a onClick={this.goTo} name="/my/page" href="/my/page">Account </a>
                                <img alt='' width="10" src={saveBrowsingChevron} />
                                <ul className="dropdown account">
                                    <li><a onClick={this.goTo} name="/my/page" href="/my/settings">My Page</a></li>
                                    <li><a onClick={this.goTo} name="/my/settings" href="/my/settings">Settings</a></li>
                                    <li><a onClick={this.goTo} name="/my/content" href="/my/content">Manage Content</a></li>
                                    <li><a onClick={this.goTo} name="/my/sales" href="/my/sales">Sales</a></li>
                                    <li><a onClick={this.goTo} name="/my/purchases" href="/my/purchases">Purchases</a></li>
                                    <li><a onClick={this.goTo} name="/my/following" href="/my/following">Following</a></li>
                                    <li><a onClick={this.goTo} name="/my/followers" href="/my/followers">Followers</a></li>
                                    <li><a className="heavy" href="/" onClick={this.handleLogout}>Logout</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <CreatePost isActive={this.state.isCreatingPost} currentUser={this.props.currentUser} handlePostCreationSuccess={this.postCreationSuccess} handleClose={this.closePostCreation} />
        </nav>;
    }

    render() {
        return this.props.isAuthenticated ? this.renderAuthenticatedNavigation() : this.renderNavigation();
    }
}

export default Navigation;
