import React from "react";
import PackageService from "../../services/package";

import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import LoadingIndicator from "../../components/loading-indicator";
import ResponsiveNavigation from "../../components/responsive-navigation";
import HelmetComponent from "../../components/helmet";
import PackageList from "../../components/package-list";

class ManageContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paging: '',
            myPackages: [],
            packagesBeingDeleted: [],
            isLoading: true,
            isLoadingMore: false,
        };

        this.handleFetchPackageSuccess = this.handleFetchPackageSuccess.bind(this);
        this.handlePackageDelete = this.handlePackageDelete.bind(this);
        this.handlePackageClone = this.handlePackageClone.bind(this);
        this.handlePackageEdit = this.handlePackageEdit.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.attemptLoadMore = this.attemptLoadMore.bind(this);
    }

    componentDidMount() {
        this.fetchPackages(this.props.currentUser.userId);
    }

    fetchPackages(userData, nextKey) {
        PackageService.fetchPackagesForUser(userData, nextKey, 25).then(response => this.handleFetchPackageSuccess(response.data.packages, response.data.paging)).catch(error => this.handleFetchPackageFailure(error));
    }

    loadMore() {
        this.fetchPackages(this.props.currentUser.userId, this.state.paging.nextKey);
    }

    attemptLoadMore() {
        if (this.canLoadMore()) {
            this.loadMore();
        }
    }

    canLoadMore() {
        return !!this.state.paging.nextKey;
    }

    isLoading() {
        return this.state.isLoading;
    }

    isLoadingMore() {
        return this.state.isLoadingMore;
    }

    hasPackages() {
        return this.state.myPackages.length > 0;
    }

    handleFetchPackageSuccess(packages, paging) {
        this.setState((prevState) => ({ myPackages: prevState.myPackages.concat(packages), paging: paging, isLoading: false, isLoadingMore: false }));
    }

    handleFetchPackageFailure() {
        this.setState({myPackages: [], isLoading: false});
    }

    handlePackageDelete(packageId) {
        let packagesBeingDeleted = this.state.packagesBeingDeleted;
        packagesBeingDeleted.push(packageId);
        this.setState({packagesBeingDeleted});

        PackageService.deletePackage(packageId).then(response => this.handleSuccessfulPackageDeletion(packageId)).catch(error => console.log(error));
    }

    handleSuccessfulPackageDeletion(packageId) {
        // TODO improve this to remove the deleted package without a fetch call
        this.setState({myPackages: []}, () => {
            this.fetchPackages(this.props.currentUser.userId);
        });

        let packagesBeingDeleted = this.state.packagesBeingDeleted;
        const index = packagesBeingDeleted.indexOf(packageId);
        if (index > -1) {
            packagesBeingDeleted.splice(index, 1);
        }

        this.setState({packagesBeingDeleted});
    }

    handlePackageClone(packageId) {
        this.props.navigation.packageClone(packageId);
    }

    handlePackageEdit(packageId) {
        this.props.navigation.packageEdit(packageId);
    }

    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer />
    }

    renderContent() {
        return <section className="featured">
            <h3>My Packages</h3>
            <div id="container" className="container">
                <div className="row">
                    <PackageList packages={this.state.myPackages} navigation={this.props.navigation} isEditing={true} deleting={this.state.packagesBeingDeleted} onDelete={this.handlePackageDelete} onClone={this.handlePackageClone} onEdit={this.handlePackageEdit} loadMore={this.attemptLoadMore} />
                </div>
            </div>
        </section>;
    }

    renderLoading() {
        return <LoadingIndicator />;
    }

    renderFeaturedPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            {this.isLoading() ? this.renderLoading() : this.renderContent()}
            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            <HelmetComponent title='Manage Content' description='Manage the content which you have uploaded to OpenSwap.io.' />
        </div>
    }

    render() {
        return this.renderFeaturedPage();
    }
}

export default ManageContent;
