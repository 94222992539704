import React from "react";

class ImageSettingsModal extends React.Component {
    constructor(props) {
        super(props);

        this.closeClick = this.closeClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleCoverClick = this.handleCoverClick.bind(this);
    }

    isImage() {
        let extension = this.props.item.split(".").pop().toLowerCase();

        return extension === 'jpg' || extension === 'png' || extension === 'jpeg';
    }

    closeClick() {
        this.props.handleClose();
    }

    handleDeleteClick() {
        this.props.handleDeleteClick(this.props.item);
    }

    handleCoverClick() {
        this.props.handleCoverClick(this.props.item);
    }

    renderSetAsCoverButton() {
        return this.isImage() && !this.props.isItemCurrentCover ? <button onClick={this.handleCoverClick} className="btn--blueGr">Set as cover</button> : ''
    }

    renderModal() {
        return <div className={this.props.isActive ? "modalHolder fadeIn" : "modalHolder fadeOut"}>
            <div onClick={() => this.closeClick()} className="popupBG"></div>
            <div className={this.props.isActive ? "modal modal--sample active fadeIn" : "modal modal--sample fadeOut"}>
                <button onClick={this.closeClick} className="modal__close">
                    <svg viewBox="0 0 100 100" fill="#5d80a2">
                        <use xlinkHref="#closeIcon">
                        </use>
                    </svg>
                </button>
                <div className="modal__title">{this.props.title}</div>
                <div className="modal__content modal__content--white">
                    <p>
                        {this.props.message}
                    </p>
                </div>
                <div className="selection-modal-controls">
                    {this.renderSetAsCoverButton()}
                    <button onClick={this.handleDeleteClick} className="btn--blueGr">Delete</button>
                </div>
            </div>
        </div>;
    }

    render() {
        return this.renderModal();
    }
}

export default ImageSettingsModal;
