import React from "react";

import SaleService from "../../services/sale";
import LoadingIndicator from "../../components/loading-indicator";

import SaleRow from './saleRow'
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveNavigation from "../../components/responsive-navigation";
import PURCHASE_STATE from "../../constants/purchase-status";
import HelmetComponent from "../../components/helmet";

class Sales extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sales: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        this.fetchMySales();
    }

    fetchMySales() {
        this.setState({isLoading: true});
        SaleService.fetchMySales().then(response => this.handleSuccessfulSalesFetch(response.data.sales)).catch(error => this.handleFailedSalesFetch(error));
    }

    handleSuccessfulSalesFetch(sales) {
        this.setState({sales: this.sortSales(sales), isLoading: false});
    }

    handleFailedSalesFetch() {
        this.setState({sales: [], isLoading: false});
    }

    sortSales(sales) {
        return sales.sort((a, b) => {
            return b.date_created - a.date_created;
        });
    }

    timestampToDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleDateString("en-US");
    }

    calculateSalesTotal() {
        let salesTotal = 0;
        this.state.sales.map((sale) => {
            if (sale.status === PURCHASE_STATE.JOSE) {
                salesTotal += parseFloat(sale.transaction.jose);
            }

            return salesTotal;
        });
        return salesTotal.toFixed(6);
    }

    renderSalesSummary() {
        return <table className="transactionsTable" border="0" cellPadding="0" cellSpacing="0" width="100%">
            <tbody>
                <tr>
                    <td className="w20">&nbsp;</td>
                    <td>
                        Packages Sold
                    </td>
                    <td className="td-center">
                        {this.state.sales.length}
                    </td>
                    <td className="w20">&nbsp;</td>
                </tr>

                <tr>
                    <td className="w20">&nbsp;</td>
                    <td>
                        Payouts total (BTC)
                    </td>
                    <td className="td-center">
                        {this.calculateSalesTotal()}
                    </td>
                    <td className="w20">&nbsp;</td>
                </tr>
            </tbody>
        </table>
    }

    renderSalesHeader() {
        return <tr>
            <td className="w20">&nbsp;</td>
            <td>
                #
            </td>
            <td>
                Name
            </td>
            <td>
                Total
            </td>
            <td>
                Currency
            </td>
            <td>
                Date
            </td>
            <td className="w20">&nbsp;</td>
        </tr>;
    }

    renderNavigation() {
        return <Navigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer />
    }

    renderRenderResponsiveNavigation() {
        return <ResponsiveNavigation isAuthenticated={true} currentUser={this.props.currentUser} navigation={this.props.navigation} />
    }

    renderSalesDetails() {
        return <>
            {this.renderSalesSummary()}

            <table className="transactionsTable table-center" border="0" cellPadding="0" cellSpacing="0" width="100%">
                <tbody>
                {this.renderSalesHeader()}
                {this.state.sales.map((sale, index) => <SaleRow key={index} position={index + 1} packageName={sale.package.name} total={sale.transaction.jose} status={sale.status} currency={"BTC"} date={this.timestampToDate(sale.date_created)} />)}
                </tbody>
            </table>
            </>
    }

    renderNoSalesLabel() {
        return <div className='row'><div className='col td-center'>You haven't made any sales</div></div>
    }

    renderSalesContent() {
        return <section className="purchases">
            <div className="container">
                <div className="row">
                    <div className="col" style={{"padding": "0"}}>

                        <h2>Sales</h2>

                        {this.state.sales.length > 0 ? this.renderSalesDetails() : this.renderNoSalesLabel()}

                    </div>
                </div>
            </div>
        </section>
    }

    renderSalesPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            {this.state.isLoading ? <LoadingIndicator/> : this.renderSalesContent()}
            {this.renderFooter()}
            {this.renderRenderResponsiveNavigation()}
            <HelmetComponent title='Your Sales' description='OpenSwap.io sales page.' />
        </div>;
    }

    render() {
        return this.renderSalesPage();
    }
}

export default Sales;
