import React from "react";
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveMenu from "../../components/responsive-navigation";
import HelmetComponent from "../../components/helmet";

class TOS extends React.Component {

    renderNavigation() {
        return <Navigation navigation={this.props.navigation}/>
    }

    renderFooter() {
        return <Footer/>
    }

    renderRenderResponsiveMenu() {
        return <ResponsiveMenu navigation={this.props.navigation}/>
    }

    renderTOS() {
        return <section>
                <div className="container top-padding">
                    <h3>Copyright Policy</h3>
                </div>
                <div className="container">
                    <p>OpenSwap.io respects the intellectual property rights of owners and expects its users to do the same.
                    Note that as a content creator, you retain rights over any Content you submit, post or display on OpenSwap.io.</p>

                    <p>By creating an account on OpenSwap.io and agreeing to our terms and conditions, you acknowledge and understand that OpenSwap.io will never use your private content without your knowledge or permission.
                        Private content being defined as non preview items in a Package. Package previews may be shared by users or cached and indexed by search engines.</p>

                    <p>It is OpenSwap.io's policy, in appropriate circumstances and at its discretion, to terminate the accounts of users who infringe the copyrights or other intellectual property rights of others.</p>

                    <p>Users purchasing OpenSwap.io Packages implicitly agree to respect the Licensing Agreement set by the content creator for the Package being purchased.</p>

                    <p>The default Licensing Agreement on all Packages is Personal Use.</p>

                    <p>
                        The Content Creator creator is and always remains the owner of copyright.

                        OpenSwap.io Content Creators must have written consent (model release form) for public and commercial use from any individuals portrayed in images, videos, and other content.

                        The buyer of a Package can use the content only within the boundaries set by the Licensing Agreement. Any other use (including content trading) is considered infringement of copyright.
                    </p>

                    <p>If, as a Content Creator, you find content which you exclusively sold or are selling on OpenSwap.io on another publicly accessible medium, <a href={'mailto:copyright@openswap.io'}>notify us</a> immediately.</p>

                </div>
        </section>;
    }

    renderContent() {
        return  <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            {this.renderTOS()}
            {this.renderFooter()}
            {this.renderRenderResponsiveMenu()}
            <HelmetComponent title='Copyright Policy' description='OpenSwap.io copyright policy and details.' />
        </div>
    }

    render() {
        return this.renderContent();
    }
}

export default TOS;
