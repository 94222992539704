import React from 'react';

import { Storage } from 'aws-amplify';

class S3Image extends React.Component {
    constructor(props) {
        super(props);

        this.mounted = false;

        this.state = {
          imgSrc: '',
        };

        this.handleImageError = this.handleImageError.bind(this);
        this.onImageLoad = this.onImageLoad.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        Storage.get(this.props.s3key,{ level: this.props.protectionLevel ? this.props.protectionLevel : 'protected', identityId: this.props.identityId }).then(result => this.handleSuccessfulGet(result)).catch(err => this.handleFailedGet());
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleSuccessfulGet(result) {
        if (!this.mounted) {
            return;
        }

        this.setState({imgSrc: result})
    }

    handleFailedGet() {
        if (!this.mounted) {
            return;
        }

        this.setState({imgSrc: this.props.defaultImage})
    }

    handleImageError(event) {
        if (!this.mounted) {
            return;
        }

        this.setState({imgSrc: this.props.defaultImage});
    }

    onImageLoad(event) {
        if (this.props.handleImageLoad) {
            this.props.handleImageLoad(event.target.src);
        }
    }

    renderImage() {
        return <img onLoad={this.onImageLoad} onError={this.handleImageError} className={this.props.className} alt="" src={this.state.imgSrc} />
    }

    render() {
        return this.renderImage();
    }
}

export default S3Image;
