import React from 'react';

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share";

import LoadingIndicator from "../loading-indicator";
import AvatarImage from "../avatar-image";

const openSwapLogo = require('../../images/open-swap-ico.svg');

const shareIcon = require('../../images/fa-share.svg');
const deleteIcon = require('../../images/fa-trash-o.svg');
const cloneIcon = require('../../images/clone.svg');
const editIcon = require('../../images/edit.svg');

class PackageCard extends React.Component {
    constructor(props) {
        super(props);
        /*
        props:
        - isEditing
        - isDeleting
        - package id
        - username
        - package previews
        - package price
        - package owner
        - package quantity
        - shouldBlur
        - date
        - onDelete
        - onClone
        */

        this.state = {
            isShowingShare: false,
        };

        this.showShareSheet = this.showShareSheet.bind(this);
        this.hideShareSheet = this.hideShareSheet.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClone = this.handleClone.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.goToPackage = this.goToPackage.bind(this);
        this.goToUser = this.goToUser.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.hideShareSheet);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.hideShareSheet);
    }

    goToPackage(event) {
        event.preventDefault();
        this.props.navigation.packageRoute(event.currentTarget.name);
    }

    goToUser(event) {
        event.preventDefault();
        this.props.navigation.userPage(event.currentTarget.name);
    }

    handleDelete() {
        this.props.onDelete(this.props.packageId);
    }

    handleClone() {
        this.props.onClone(this.props.packageId);
    }

    handleEdit() {
        this.props.onEdit(this.props.packageId);
    }

    showShareSheet() {
        this.setState({isShowingShare: true});
    }

    hideShareSheet() {
        this.setState({isShowingShare: false});
    }

    // TODO move to service
    renderTimestampToDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleDateString("en-US");
    }

    renderPackageURL() {
        return `https://www.openswap.io/packages/${this.props.packageAlias}`;
    }

    renderPreviewImageUrl() {

    }

    renderLoading() {
        return <LoadingIndicator/>;
    }


    renderShare() {
        return <>
            <div className={this.state.isShowingShare ? 'package-share-sheet fadeIn' : 'package-share-sheet fadeOut'}>
                <div className='share-item'>
                    <EmailShareButton subject={`Hey! Check out this great content I just found: ${this.props.packageName}`} url={this.renderPackageURL()}>
                        <EmailIcon size={35} round={true}/>
                    </EmailShareButton>
                </div>
                <div className='share-item'>
                    <FacebookShareButton quote={this.props.packageName} url={this.renderPackageURL()}>
                        <FacebookIcon size={35} round={true}/>
                    </FacebookShareButton>
                </div>
                <div className='share-item'>
                    <PinterestShareButton media={openSwapLogo} url={this.renderPackageURL()}>
                        <PinterestIcon size={35} round={true}/>
                    </PinterestShareButton>
                </div>
                <div className='share-item'>
                    <RedditShareButton title={this.props.packageName} url={this.renderPackageURL()}>
                        <RedditIcon size={35} round={true}/>
                    </RedditShareButton>
                </div>
                <div className='share-item'>
                    <TwitterShareButton title={this.props.packageName} url={this.renderPackageURL()}>
                        <TwitterIcon size={35} round={true}/>
                    </TwitterShareButton>
                </div>
            </div>
            <img title='Share Package' alt='Share Package' onClick={this.showShareSheet} src={shareIcon} />
        </>
    }

    renderDelete() {
        return this.props.isDeleting ? <LoadingIndicator positionClassName='delete-packages-loading-position' contentClassName='delete-packages-loading-content' customPosition={true}/> : <img title='Delete Package' alt='Delete Package' onClick={this.handleDelete} src={deleteIcon} />;
    }

    renderClone() {
        return <img className='clone-packages-icon' title='Clone Package' alt='Clone Package' onClick={this.handleClone} src={cloneIcon} />
    }

    renderEdit() {
        return <img className='edit-packages-icon' title='Edit Package' alt='Edit Package' onClick={this.handleEdit} src={editIcon} />
    }

    renderOpen() {
        return <a onClick={this.goToPackage} name={this.props.packageAlias} href={`/packages/${this.props.packageAlias}`}>
            PREVIEW PACKAGE
        </a>
    }

    renderPackageCard() {
        return <div className="card-box">
            <div className="card-box-user">
                <a onClick={this.goToUser} name={this.props.username} href={`/creator/${this.props.username}`} className="card-box-user-img-link">
                    <AvatarImage size='small' identityId={this.props.packageOwner} />
                </a>
                <div className="card-box-user-name">
                    <a onClick={this.goToUser} name={this.props.username} href={`/creator/${this.props.username}`}>
                        {this.props.username}
                    </a>
                </div>
            </div>
            <div className='card-box-img-container'>
                <div className={this.props.shouldBlur ? "card-box-img blur" : "card-box-img"}>
                    <a onClick={this.goToPackage} name={this.props.packageAlias} href={`/packages/${this.props.packageAlias}`}>
                        <img alt={this.props.packageTag || 'Openswap image'} src={this.props.packageImage} className="full-width" />
                    </a>
                </div>
            </div>
            <div className="card-box-desc">
                <a onClick={this.goToPackage} name={this.props.packageAlias} href={`/packages/${this.props.packageAlias}`}>
                    <span _data={this.props.packageName}>{this.props.packageName}</span>
                    <span>{this.props.price} BTC</span>
                    <span>{this.props.quantity} {this.props.quantity > 1 ? 'items' : 'item'} in this Package</span>
                    {this.props.isEditing && <span className='date'>Date Published {this.renderTimestampToDate(this.props.date)}</span>}
                </a>
            </div>
            <div className="card-box-footer">
                {this.props.isEditing ? this.renderDelete() : this.renderShare()}

                {this.props.isEditing ? this.renderClone() : this.renderOpen()}

                {this.props.isEditing ? this.renderEdit() : ''}
            </div>
        </div>;
    }

    render() {
        return this.renderPackageCard()
    }
}

export default PackageCard
