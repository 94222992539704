import Cookie from 'js-cookie';
import EventEmitter from 'events';

const emitter = new EventEmitter();

let PreferenceService = {
    subscribe(event, callback) {
        return emitter.addListener(event, callback);
    },

    unsubscribe(event, callback) {
        return emitter.removeListener(event, callback);
    },

    isExplicitContentFilterSet() {
        return !!Cookie.get('explicitContentFilter');
    },

    getExplicitContentFilter() {
        return (Cookie.get('explicitContentFilter') ? Cookie.get('explicitContentFilter') : 'obscure');
    },

    setExplicitContentFilter(filterSetting) {
        Cookie.set('explicitContentFilter', filterSetting);

        emitter.emit('explicitFilterStateChange', filterSetting);
    },

    shouldFilterAdult() {
        return PreferenceService.getExplicitContentFilter() === 'hide';
    }
};

export default PreferenceService;
