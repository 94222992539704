import React from "react";

import AvatarImage from "../../components/avatar-image";

class UserCard extends React.Component {
    /*
    Props:
    - userId
    - username
    - bio
    - packageQuantity
     */
    constructor(props) {
        super(props);

        this.goTo = this.goTo.bind(this);
    }

    goTo(event) {
        event.preventDefault();

        this.props.navigation.userPage(event.target.name);
    }

    renderUsername() {
        return this.props.packageQuantity > 0 ? `${this.props.username} (${this.props.packageQuantity} Packages)` : `${this.props.username}`;
    }

    renderUserCard() {
        return <div className="col-md-3">
            <div className="card-box">
                {/*<div className="user-type">*/}
                {/*    Basic*/}
                {/*</div>*/}
                <div className="user-img">
                    <a onClick={this.goTo} href={`/creator/${this.props.username}`} >
                        <AvatarImage size='large' name={this.props.username} identityId={this.props.userId} />
                    </a>
                </div>
                <div className="user-name">
                    <a onClick={this.goTo} name={this.props.username} href={`/creator/${this.props.username}`} >
                        {this.renderUsername()}
                    </a>
                </div>

                <div className="desc">
                    {this.props.bio}
                </div>
                {/*<div className="footer">*/}
                {/*    <div className="separator"></div>*/}
                {/*    <div className="ico">*/}
                {/*        {this.props.packageQuantity}*/}
                {/*        <br />*/}
                {/*        Packages*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    }

    render() {
        return this.renderUserCard();
    }
}

export default UserCard;
