import React from "react";

import SessionService from "../../services/session";

import Navigation from '../../components/navigation';
import Footer from '../../components/footer';
import ResponsiveMenu from '../../components/responsive-navigation';
import HelmetComponent from '../../components/helmet';

class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            isLoading: false,
            errorMessage: '',
        };

        this.updateFormSate = this.updateFormSate.bind(this);
        this.signIn = this.signIn.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    updateFormSate(event) {
        this.clearErrors();
        this.setState({ [event.target.name]: event.target.value });
    }

    handleErrorResponse(errorPayload) {
        if (errorPayload.code === "UserNotFoundException" || errorPayload.code === "NotAuthorizedException" || errorPayload.code === "InvalidParameterException") {
            this.setState({errorMessage: "Your email / password combo is invalid. Please try again."});
        }
    }

    handleSuccessfulSignIn() {
        this.setState({errorMessage: '', isLoading: false});
        this.props.navigation.myPage();
    }

    clearErrors() {
        this.setState({errorMessage: '', isLoading: false});
    }

    async signIn(event) {
        event.preventDefault();

        this.setState({isLoading: true}, async () => {
            try {
                await SessionService.signIn(this.state.email, this.state.password);

                this.handleSuccessfulSignIn();
            }
            catch (err) {
                this.handleErrorResponse(err);
                this.setState({isLoading: false});
            }
        });
    }

    submitForm(event) {
        event.preventDefault();
    }

    renderNavigation() {
        return <Navigation isAuthenticated={false} navigation={this.props.navigation}/>
    }

    renderFooter() {
        return <Footer/>
    }

    renderRenderResponsiveMenu() {
        return <ResponsiveMenu navigation={this.props.navigation}/>
    }

    renderSignIn() {
        return <div className="signup-background cover">
            <header>
                <div className="nav-container nav-home">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            <section className="">
                <div className="container">
                    <form onSubmit={this.submitForm} className="form-signin" action="#" style={{"paddingTop": "30px"}}>
                        <p className="signupfree">Please sign in</p>
                        <input id="emailInput" onChange={this.updateFormSate} name="email" type="email" className="form-control" placeholder="Email" required />
                        <input id="passwordInput" onChange={this.updateFormSate} name="password" type="password" className="form-control" placeholder="Password" required />

                        <div id="SignupMessage" className={this.state.errorMessage ? "signup-message" : "hide"}>{this.state.errorMessage ? this.state.errorMessage : ''}</div>
                        <br />

                        <button id="SigninButton" disabled={this.state.isLoading ? "disabled" : ""} onClick={this.signIn} className={this.state.isLoading ? "btn--gray button-loading-border" : "btn--blueGr"}>Sign in</button>
                    </form>
                    <div style={{"textAlign": "center", "paddingBottom": "40px"}}>
                        {/*<a className="forgot-password btn" href="/signup">I forgot my password</a>*/}
                        <hr style={{"borderTop": "1px solid rgba(255,255,255, .91)", "width": "300px"}} />
                        <a href="/signup" style={{"textDecoration": "none"}}>
                            <span className="new-to">New to Openswap?</span>
                            <span className="new-to">Sign up, it's free</span>
                            <button id="SignupButton" className="btn--blueGr"
                                    style={{"maxWidth": "300px", "marginTop": "20px"}}>Sign up
                            </button>
                        </a>

                    </div>
                </div>
            </section>
            {this.renderFooter()}
            {this.renderRenderResponsiveMenu()}
            <HelmetComponent title='Sign In' description='Sign in to your OpenSwap.io account' />
        </div>;
    }

    render() {
        return this.renderSignIn()
    }
}

export default SignIn;
