import React from "react";
import PurchaseService from "../../services/purchase";
import PURCHASE_STATE from "../../constants/purchase-status";
import Navigation from "../../components/navigation";
import Footer from "../../components/footer";
import ResponsiveMenu from "../../components/responsive-navigation";
import LoadingIndicator from "../../components/loading-indicator";
import PackageService from "../../services/package";

class PackageRoute extends React.Component {

    constructor(props) {
        super(props);

        this.packageId = null;
    }

    componentDidMount() {
        if (!this.isLoggedIn()) {
            this.props.navigation.packagePage(this.retrievePackageAlias(), true);
        } else {
            this.fetchPackagePurchase();
        }
    }

    fetchPackagePurchase() {
        this.retrievePackageId().then(packageId => {
            this.packageId = packageId;
            PurchaseService.fetchPurchaseByPackage(packageId).then(response => this.handleSuccessfulFetch(response.data.purchases[0])).catch(error => this.handleFailedFetch(error));
        });
    }

    handleSuccessfulFetch(purchase) {
        if (purchase.status === PURCHASE_STATE.CONFIRMED || purchase.status === PURCHASE_STATE.JOSE) {
            this.props.navigation.purchasedPackage(this.packageId, true);
        } else if (purchase.status === PURCHASE_STATE.PENDING) {
            this.props.navigation.purchase(purchase._id);
        } else {
            this.props.navigation.packagePage(this.retrievePackageAlias(), true);
        }
    }

    handleFailedFetch(error) {
        this.props.navigation.packagePage(this.retrievePackageAlias(), true);
    }

    isLoggedIn() {
        return !!this.props.currentUser;
    }

    async retrievePackageId() {
        const packageAlias = this.retrievePackageAlias();

        return await PackageService.fetchIDFromAlias(packageAlias);
    }

    retrievePackageAlias() {
        let packagePayload = this.props.location.pathname.split("/");
        return (packagePayload && packagePayload[3]) ? packagePayload[3] : null;
    }

    renderNavigation() {
        return <Navigation isAuthenticated={!!this.props.currentUser} navigation={this.props.navigation} />
    }

    renderFooter() {
        return <Footer/>
    }

    renderRenderResponsiveMenu() {
        return <ResponsiveMenu isAuthenticated={!!this.props.currentUser} navigation={this.props.navigation} />
    }

    renderLoadingIndicator() {
        return <LoadingIndicator />
    }

    renderPage() {
        return <div>
            <header>
                <div className="nav-container">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>
            {this.renderLoadingIndicator()}
            {this.renderFooter()}
            {this.renderRenderResponsiveMenu()}
        </div>
    }

    render() {
        return this.renderPage();
    }
}

export default PackageRoute;
