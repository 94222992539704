import React from 'react';

import EnvironmentService from "../../services/environment";

class CDNImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imgSrc: '',
        };

        this.handleImageError = this.handleImageError.bind(this);
    }

    componentDidMount() {
        console.log(this.getImageSource());
        this.setState({imgSrc: this.getImageSource()});
    }

    getImageSource() {
        return `${EnvironmentService.staticURL()}/protected/${this.props.identityId}/${this.props.imageKey}`;
    }

    handleImageError(event) {
        this.setState({imgSrc: this.props.defaultImage});
    }

    renderImage() {
        return <img name={this.props.name} onError={this.handleImageError} className={this.props.className} alt="" src={this.state.imgSrc} />
    }

    render() {
        return this.renderImage();
    }
}

export default CDNImage;
