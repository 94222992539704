/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "BffUiMain",
            "endpoint": "https://ww06bie5z9.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://m5vasbu3r5acfdyuxw33tkb2z4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:12504ac6-9465-46c9-bd90-c99a9636ca5a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4IvJdPLmc",
    "aws_user_pools_web_client_id": "ir5bfodqa05vdmf4km4mp4mpt",
    "oauth": {
        "domain": "openswapweboauth-production.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://master.d1ufgh9kkbnlzw.amplifyapp.com/,https://www.openswap.io/,http://localhost:3000/",
        "redirectSignOut": "https://master.d1ufgh9kkbnlzw.amplifyapp.com/,https://www.openswap.io/,https://openswap.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "openswap-web-20200727131131-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d31uo2p63kjs9b.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "catalogue-production",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "openswap-web-common-storage-789aswui-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
