import React from 'react';
import Navigation from '../../components/navigation';
import Footer from '../../components/footer';
import ResponsiveMenu from '../../components/responsive-navigation';
import HelmetComponent from "../../components/helmet";

const photosImage = require('../../images/home-image-icon.png');
const videoImage = require('../../images/home-video-icon.png');
const musicImage = require('../../images/home-music-icon.png');
const bookImage = require('../../images/home-book-icon.png');

const lightPolygon = require('../../images/lightPolygon.svg');

class Landing extends React.Component {
    constructor(props) {
        super(props);

        this.goToSignUp = this.goToSignUp.bind(this);
    }

    goToSignUp() {
        this.props.navigation.signUp();
    }

    renderNavigation() {
        return <Navigation isAuthenticated={false} navigation={this.props.navigation}/>;
    }

    renderFooter() {
        return <Footer/>;
    }

    renderResponsiveMenu() {
        return <ResponsiveMenu isAuthenticated={false} navigation={this.props.navigation}/>
    }

    renderLanding() {
        return <div>
            <header>
                <div className="nav-container nav-home">
                    <div className="container">
                        {this.renderNavigation()}
                    </div>
                </div>
            </header>

            <section className="home-header">
                <div className="container">
                    <div className="home-intro">
                        <h1>
                            OpenSwap is a digital assets marketplace for content creators.
                            <strong>Sell music, photography, videography and more!</strong>
                        </h1>
                        <div className="row home-icon-container">
                            <div className="col-md-3 col-6">
                                <img alt="" width="100%" src={photosImage}/>
                            </div>
                            <div className="col-md-3 col-6">
                                <img alt="" width="100%" src={videoImage}/>
                            </div>
                            <div className="col-md-3 col-6">
                                <img alt="" width="100%" src={musicImage}/>
                            </div>
                            <div className="col-md-3 col-6">
                                <img alt="" width="100%" src={bookImage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="transactions">
                <div className="transactions__polygons">
                    <img className="polygon--left1" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--left2" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--left3" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--left4" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--left5" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--right1" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--right2" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--right3" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--right4" src={lightPolygon} alt="Polygon" />
                    <img className="polygon--right5" src={lightPolygon} alt="Polygon" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="home-description">
                                Weather you're a musician, photographer, model, writer or any other type of artist our
                                platform allows you to monetize your content.<br/><br/>
                                Here's what we offer:<br/>
                                * Immediate after-sale payment<br/>
                                * Minimal 10% fee on all sales<br/>
                                * Content management and geo-blocking tools<br/>
                                * Content marketing tools<br/>
                                * A strict copyright policy with proactive enforcement<br/><br/>
                                * <b>$100 credit for all new content creators!</b><br/><br/>
                                <button onClick={this.goToSignUp} id="SignupButton" className="btn--blueGr"  style={{"maxWidth": "300px", "marginTop": "20px"}}>Sign Up Now!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {this.renderFooter()}
            {this.renderResponsiveMenu()}
            <HelmetComponent title='Welcome' description='OpenSwap.io is a digital assets marketplace for content creators.' />
        </div>;
    }

    render() {
        return this.renderLanding();
    }
    }

    export default Landing;
