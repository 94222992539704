import React from "react";

class LoadingIndicator extends React.Component {
    renderPositionClassName() {
        if (this.props.customPosition) {
            return this.props.positionClassName ? this.props.positionClassName : "";
        }

        return this.props.positionClassName ? this.props.positionClassName + " center" : "center";
    }

    render() {
        return <div className={this.renderPositionClassName()}>
            <div className={this.props.contentClassName ? this.props.contentClassName + ' loading-indicator' : 'loading-indicator'}></div>
        </div>
    }
}

export default LoadingIndicator;
