import EventEmitter from 'events';

let emitter = new EventEmitter();

let navigationEmitter = {
    mobileNavMenuToggle: function(menuState) {
        return emitter.emit('mobileNavMenuToggle', menuState);
    },

    subscribe: function(type, callback) {
        return emitter.addListener(type, callback);
    },

    unsubscribe: function(type, callback) {
        return emitter.removeListener(type, callback);
    }
};

export default navigationEmitter;
